import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import ImageScroller from '../../components/imagescroller';
import HelpText from '../../components/gui/helpText';
import Input from '../../components/form/input';

import localUtil from '../../lib/util';

function participantExists(participants, key, value) {
    return participants.some(participant => participant[key] === value);
}

@observer
class ConventionSignup extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    updateField = async (e) => {
        const { id, md5, stage } = this.props;
        const { value, name, type } = e.target;
        const { field } = e.target.dataset;
        // console.log('updateClass', value);
        const { conventionStore } = this.props.stores;
        await conventionStore.editArrayObject({
            field,
            key: name,
            value,
            id,
            md5,
        });
    }

    render() {
        const {
            signedUp,
        } = this.state;
        const { id, md5, stage, idx } = this.props;
        const { appState, userStore, conventionStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { conventions = [], convention } = conventionStore;
        // console.log('conventions', conventions);
        // console.log('convention', convention);
        const currentConvention = conventions.find(c => c.id === parseInt(id, 10)) || convention;

        const currentStage = currentConvention.stages?.find(s => s.md5 === stage);
        const currentStageIndex = currentConvention.stages?.findIndex(s => s.md5 === stage);
        const participantFieldName = `participants${currentStageIndex + 1}`;
        const currentParticipant = currentConvention[participantFieldName] ? currentConvention[participantFieldName].find(p => p.md5 === md5) || {} : {};

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`font-weight-lighter px-3 mt-3 box-header w-100 position-relative`}>
                        {currentConvention.title}
                    </div>
                    {/* id: {id}<br /> */}
                    {/* md5: {md5}<br /> */}
                    {/* stage: {stage}<br /> */}
                    {/* participantFieldName: {participantFieldName}<br /> */}
                    {/* <xmp>{JSON.stringify(currentStage, null, 2)}</xmp> */}
                    {/* <xmp>{JSON.stringify(currentParticipant, null, 2)}</xmp> */}

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='d-flex flex-column'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.participant'>Participant</Text>
                                    </span>
                                    <span>
                                        {currentParticipant.firstname} {currentParticipant.lastname}
                                    </span>
                                </div>
                                <div class='d-flex flex-column mt-3'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.choose-class'>Choose shooting class</Text>
                                    </span>
                                    <select
                                        class='form-control rounded-pill'
                                        onInput={this.updateField}
                                        name={'class'}
                                        value={currentParticipant.class}
                                        data-field={participantFieldName}
                                    >
                                        <option value=''>-- Choose class --</option>
                                        <option value='fin'>Fin</option>
                                        <option value='grov'>Grov</option>
                                        <option value='mil'>Militær</option>
                                        <option value='milrp'>Militær RP</option>
                                        <option value='rev'>Revolver</option>
                                        <option value='revrp'>Revolver RP</option>
                                        <option value='sp.rev'>Sp.rev</option>
                                        <option value='sp.pistol'>Sp.pistol</option>
                                        <option value='mag1'>Magnum 1</option>
                                        <option value='mag2'>Magnum 2</option>
                                        <option value='luft'>Luft</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default ConventionSignup;
