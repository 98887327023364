import { h, Component } from 'preact';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import util from 'preact-util';
import { Text, Localizer } from 'preact-i18n';
import md5 from 'crypto-js/md5';

import DragNDropFileupload from './dragNdropFileupload';

@observer
class Files extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    handleAddImage = (file) => {
        const { addToFiles = true } = this.props;

        // <Images saved={saved} object={dog} field={field} updateField={updateField} stores={this.props.stores} />
        // updateFieldValue = ({ id, field, value, datatype, elementtype, isNew = false, skipTimer = false }) => {
        const {
            updateField,
            object,
            field,
        } = this.props;

        // if (field) {
        //     updateField({ id: object.id, field, value: {
        //         ...file,
        //         md5: md5(JSON.stringify(file)).toString(),
        //         date: new Date(),
        //     }, skipTimer: true });
        // }

        // let images = toJS(object.images);
        // if (!util.isArray(images)) {
        //     images = [];
        // }
        if (addToFiles) {
            const files = [];
            files.push({
                ...file,
                md5: md5(JSON.stringify(file)).toString(),
                date: new Date(),
            });
            updateField({ id: object.id, field: 'files', value: files, skipTimer: true });
        }
    };

    render() {
        const {
            uploadStatus = () => {},
            before = () => {},
            after = () => {},
            autoOpen = false,
            text,
            icon,
            field,
            displayLargeFiles,
        } = this.props;
        return (
            <div class='row'>
                <div class='col-12'>
                    <DragNDropFileupload
                        handleAddImage={this.handleAddImage}
                        apiUrl={'/api/fileupload'}
                        uploadStatus={uploadStatus}
                        before={before}
                        after={after}
                        autoOpen={autoOpen}
                        accept='*/*'
                        text={text}
                        icon={icon}
                        typeOfFile='file'
                        field={field}
                        displayLargeFiles={displayLargeFiles}
                    >
                        {/* <div style={{ height: 30, width: '100%' }}> */}
                            {/* {files.map((file, idx) => (
                                <div key={idx}>
                                    {file.name}
                                    Total: {util.asString(file, 'load', 'total')}
                                    <img style='width: 48px;'
                                        alt='Preview'
                                        key={file.preview}
                                        src={file.src}
                                    />
                                </div>
                            ))} */}
                        {/* </div> */}
                    </DragNDropFileupload>
                </div>
            </div>
        );
    }
}

export default Files;
