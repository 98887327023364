import { h, Component, createRef } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';

// import * as leaflet from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polyline, Polygon, useMap, GeoJSON, ScaleControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import markerSvg from '../../assets/mapicons/marker.svg';
import markerPerson from '../../assets/mapicons/person-duotone.svg';
import markerTentSvg from '../../assets/mapicons/camping-tent.svg';
import markerCircleSvg from '../../assets/mapicons/circle-marker.svg';
import markerDogSvg from '../../assets/mapicons/dog-duotone.svg';
import markerSnowMobileSvg from '../../assets/mapicons/person-snowmobiling-duotone.svg';
import markerPin from '../../assets/mapicons/pinmarker.png';

const iconMarker = new L.Icon({
    iconUrl: markerSvg,
    iconRetinaUrl: markerSvg,
    // popupAnchor:  [45, 45],
    iconAnchor: [15,30],
    iconSize: [30,30],
});
const iconMarkerPerson = new L.Icon({
    iconUrl: markerPerson,
    iconRetinaUrl: markerPerson,
    // popupAnchor:  [45, 45],
    iconAnchor: [15,30],
    iconSize: [30,30],
});
const iconMarkerPin = new L.Icon({
    iconUrl: markerPin,
    iconRetinaUrl: markerPin,
    // popupAnchor:  [45, 45],
    iconAnchor: [30,60],
    iconSize: [60,60],
});
const iconCircle = new L.Icon({
    iconUrl: markerCircleSvg,
    iconRetinaUrl: markerCircleSvg,
    // popupAnchor:  [45, 45],
    iconAnchor: [15,15],
    iconSize: [15,15],
});
const iconSnowmobile = new L.Icon({
    iconUrl: markerSnowMobileSvg,
    iconRetinaUrl: markerSnowMobileSvg,
    // popupAnchor:  [45, 45],
    iconAnchor: [25,25],
    iconSize: [25,25],
});

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

const ResizeMap = () => {
    const map = useMap();
    map._onResize();
    return null;
};

const ChangeView = ({ center, zoom, bounds = [] }) => {
    const map = useMap();
    map.setView(center, zoom);
    if (bounds.length > 0) {
        map.fitBounds(bounds);
    }
    return null;
}

const FitBounds = ({ bounds = [] }) => {
    const map = useMap();
    if (bounds.length > 0) {
        map.fitBounds(bounds);
    }
    return null;
}

function getColor(idx) {
    const colors = [
        'rgb(255, 0, 0)',     // Red
        'rgb(0, 255, 0)',     // Green
        'rgb(0, 0, 255)',     // Blue
        'rgb(255, 255, 0)',   // Yellow
        'rgb(255, 0, 255)',   // Purple
        'rgb(0, 255, 255)',   // Cyan
        'rgb(255, 128, 0)',   // Orange
        'rgb(128, 0, 255)',   // Violet
        'rgb(255, 0, 128)',   // Pink
        'rgb(128, 255, 0)',   // Lime
        'rgb(0, 128, 255)',   // Azure
        'rgb(255, 0, 128)',   // Rose
        'rgb(0, 255, 128)',   // Spring green
        'rgb(255, 128, 128)',   // Salmon
          // Add more colors as needed
    ];

    const color = colors[parseInt(idx, 10) % colors.length];
    return color;
}

function* colorCycler() {
    const colorArray = [
        'rgb(54, 133, 252)',  // Original bright blue
        'rgb(84, 72, 252)',   // Deeper purple accent (analogous)
        'rgb(54, 54, 252)',   // Complementary blue
        'rgb(77, 189, 252)',  // Lighter sky blue (tone on tone)

        'rgb(54, 252, 54)',   // Complementary green
        'rgb(54, 252, 133)',  // Vivid green

        'rgb(252, 54, 54)',   // Complementary red
        'rgb(252, 54, 133)',  // Vivid magenta
        'rgb(252, 163, 54)',  // Complementary orange

        'rgb(34, 34, 34)',    // Dark neutral (text)
        'rgb(230, 230, 230)', // Light neutral (background)
    ];

    let index = 0;
    while (true) {
        yield colorArray[index];
        index = (index + 1) % colorArray.length;
    }
}
@withText(props => ({
    titleText: <Text id='race-class-contestants.title'>Race Contestants</Text>,
}))
@observer
class RaceClassContestantList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            rcId: props.raceClass?.id,
        };
        this.mapRef = createRef;
    }

    viewRaceClassCheckpoint = e => {
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;

        const { md5 } = e.target.closest('.cp-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassCheckpoint', {
            md5,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    loadGpsPoints = async () => {
        const { skipRaceClass } = this.props;
        const { cutoffDate = '' } = this.state;
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        const { gpsPointStore } = this.props.stores;
        await gpsPointStore.getGpsPoints({
            race: race.id,
            raceClass: skipRaceClass ? '' : raceClass.id,
            cutoffDate,
        });
    }

    loadRaceClassContestants = async (props = this.props) => {
		const { raceStore, raceClassStore, raceClassContestantStore, userStore } = props.stores;
        const { skipRaceClass, rcId } = this.state;

        // raceClassStore.updateKeyValue('raceClass', {});

        await raceClassStore.load(rcId, false, {
            url: '/api/raceclasses/public/',
            addData: ['files'],
        });

        const { race } = raceStore;
        await raceClassContestantStore.load({
            query: {
                race: race.id,
                raceClass: skipRaceClass ? null : rcId,
                addResults: true,
            },
            url: '/api/raceclasscontestants/public/',
        });

        const { user } = userStore;
        raceStore.checkAllPositions(user);
    }

    loadAll = async (props = this.props, fitBounds = true) => {
        const {
            race,
            raceClass,
            raceClassContestants,
            inputMarkers,
        } = this.props;
        const { skipRaceClass, rcId } = this.state;

        const { files } = raceClass;
        const { gpsPointStore } = this.props.stores;

        this.stopReloadTimer();
        // this.stopReloadTimer();
        await gpsPointStore.getGpsPoints({ race: race.id, raceClass: skipRaceClass ? '' : rcId });

        // const map = this.mapRef.current.leafletElement;
        if (fitBounds && this.mapRef) {
            let { bounds } = gpsPointStore;
            // console.log(bounds);

            if (!bounds || bounds.length === 0) {
                if (files && files.length > 0) {
                    const geoJsonLayer = L.geoJSON(files[0].geoJSON);
                    this.mapRef.fitBounds(geoJsonLayer.getBounds(), { padding: [100, 130] });
                }
            }

            // const geoJsonLayer = L.geoJSON(this.props.geoJsonData);
            // map.leafletElement.fitBounds(geoJsonLayer.getBounds());

            if (bounds.length > 0) {
                let markerBounds = L.latLngBounds([]);
                bounds.forEach(b => {
                    markerBounds.extend(b);
                });
                if (this.mapRef) {
                    this.mapRef.fitBounds(markerBounds, { padding: [100, 140] });
                }
            }

            if (inputMarkers && inputMarkers.length > 0) {
                if (inputMarkers.length === 1) {
                    if (this.mapRef) {
                        this.mapRef.setView(inputMarkers[0], 9);
                    }
                } else {
                    let markerBounds = L.latLngBounds([]);
                    inputMarkers.forEach(marker => {
                        markerBounds.extend(marker);
                    });
                    if (this.mapRef) {
                        this.mapRef.fitBounds(markerBounds, { padding: [100, 140] });
                    }
                }
            }
        }
        // gpsPointStore.setLastPoint();
        // gpsPointStore.setPolyline();

        this.startReloadTimer();
    }

    startReloadTimer = () => {
        const { raceStore } = this.props.stores;
        raceStore.setTrackingReloadTimer(() => {
            this.loadAll(this.props, false);
        }, 1000 * 60);
    }

    stopReloadTimer = () => {
        const { raceStore } = this.props.stores;
        raceStore.deleteTrackingReloadTimer();
    }

    // createMap = () => {
    //     // Create map
    //     // this.map = L.map('map').setView([51.505, -0.09], 13);

    //     // Add OpenStreetMap tiles
    //     // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //     //     maxZoom: 19,
    //     //     attribution: '© OpenStreetMap contributors'
    //     // }).addTo(this.map);
    // }

    // setMap = map => {
    //     this.map = map;
    //     console.log('setMap', { map })
    // }

    setMapRef = map => {
        this.mapRef = map;
        if (map) {
            map.on('click', this.handleMapClick);
        }
        // console.log('setMapRef', { map })
    }

    toggleRealPosition = () => {
        this.setState({
            realPosition: !this.state.realPosition,
        }, () => {
            // this.fitFoundMarkersToBounds();
        });
    }

    searchInput = (e) => {
        const search = e.target.value;
        this.setState({ search });
        clearTimeout(this.searchTimer);
        if (search.length >= 1) {
            this.searchTimer = setTimeout(async () => {
                this.doSearch(search);
            }, 700);
        }
    }

    doSearch(search) {
        const { realPosition } = this.state;
        const { gpsPointStore } = this.props.stores;
        const { markers = [] } = gpsPointStore;
        const foundMarkers = markers.filter(marker => {
            const markerInfo = marker[2];
            const contestant = markerInfo.contestant || { firstname: 'trackerId', lastname: markerInfo.trackerId };
            const searchStr = `${contestant.bib} ${contestant.firstname} ${contestant.lastname}`.toLowerCase();
            // console.log({ searchStr })
            return searchStr.includes(search.toLowerCase());
        });
        this.setState({
            foundMarkers,
        }, () => {
            this.fitFoundMarkersToBounds();
        });
    }

    fitFoundMarkersToBounds = () => {
        const { gpsPointStore } = this.props.stores;
        const { markers = [] } = gpsPointStore;

        const { realPosition, foundMarkers = markers } = this.state;
        if (foundMarkers.length > 0) {
            let markerBounds = L.latLngBounds([]);
            foundMarkers.forEach(marker => {
                const markerInfo = marker[2];
                const contestant = markerInfo.contestant || { firstname: 'trackerId', lastname: markerInfo.trackerId };
                const pt = realPosition ? [markerInfo.point[0], markerInfo.point[1]] : [marker[0], marker[1]];
                markerBounds.extend(pt);
            });
            this.mapRef.fitBounds(markerBounds, { padding: [100, 140] });
        }
        // console.log(foundMarkers.map(m => m[2].contestant?.bib));
    }

    clearSearch = () => {
        // Rest search and search results
        this.setState({
            search: '',
        });
    }

    handleKeyDown = e => {
        if (e.keyCode === 27) {
            // Esc key pressed
            e.preventDefault();
            e.stopPropagation();
            // console.log('Esc key pressed');
            this.clearSearch();
        }
    }

    changeReportDate = async e => {
        const { value: cutoffDate } = e.target;
        const { stopTimer = () => {} } = this.props;
        stopTimer();
        this.stopReloadTimer();
        this.setState({ cutoffDate }, () => {
            this.loadGpsPoints();
        });
    }

    clearCutoffDate = e => {
        this.setState({ cutoffDate: null }, () => {
            this.loadGpsPoints();
            // this.startReloadTimer();
        });
    }

    handleMapClick = (e) => {
        // console.log('handleMapClick', e);
        this.setState({ currentPos: e.latlng });
    }

    changeMapType = e => {
        const { value: mapType } = e.target;
        const { appState } = this.props.stores;
        appState.setMapType(mapType);
    }

    viewRaceClassContestant = (e, id, markerInfo) => {
        const {
            view,
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        if (view) {
            return view(e);
        }
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        console.log({ view,
            race,
            raceClass,
            raceClassContestants });
        appState.openDrawer('viewRaceClassContestant', {
            id,
            // title,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            showResults: true,
            markerInfo,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    adminAddPosition = (e, pos) => {
        // console.log({ e, pos });
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('adminViewGpsPosition', {
            pos,
            // title,
            height: drawerHeightMedium,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    toggleCurrentPosition = () => {
        const { yourPosition } = this.state;
        this.setState({
            yourPosition: !yourPosition,
        }, () => {
            this.loadCurrentPosition();
        });
    }

    loadCurrentPosition = () => {
        const { yourPosition } = this.state;
        if (yourPosition) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        const { latitude, longitude } = position.coords;
                        this.setState({
                            yourPoint: [latitude, longitude],
                        });
                        // Set timer
                        this.currentPositionTimer = setTimeout(() => {
                            this.loadCurrentPosition();
                        }, 1000 * 30);

                        // console.log({ latitude, longitude });
                    },
                    (error) => {
                        console.error('Error getting location', error);
                        // Handle location error (e.g., user denied location access)
                    }
                );
            } else {
                console.error('Geolocation is not supported by this browser.');
            }
        } else {
            clearTimeout(this.currentPositionTimer);
            this.setState({
                yourPoint: null,
            });
        }
    }

    setRaceClass = async (e) => {
        const { callback = () => {} } = this.props;
        const { id, skip = 0 } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const skipInt = parseInt(skip, 10);
        this.setState({ skipRaceClass: skipInt, rcId: idInt }, () => {
            this.loadAll({ ...this.props, raceClassId: idInt, skipRaceClass: skip});
            this.loadRaceClassContestants();
        });
    }

    showRaceResults = e => {
        // Toggle results drawer
        const { appState } = this.props.stores;
        const {
            drawerLevel = 1,
            race,
            raceClass,
            raceClasses,
        } = this.props;
        const { drawerHeightMediumLarge } = appState;
        appState.openDrawer('viewRaceResults', {
            height: drawerHeightMediumLarge,
            raceClassId: raceClass.id,
            raceId: race.id,
            drawerLevel: drawerLevel + 1,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    componentDidMount() {
        this.loadAll();
        // this.createMap();
    }

    componentWillUnmount() {
        this.stopReloadTimer();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.race !== this.props.race || nextProps.raceClass !== this.props.raceClass) {
            this.loadAll(nextProps);
        }
    }

    render() {
        const { realPosition, yourPosition, yourPoint, currentPos, search = '', cutoffDate } = this.state;
        const { skipRaceClass, rcId } = this.state;
        const { appState, userStore, raceStore, raceClassStore, gpsPointStore } = this.props.stores;

        const { user } = userStore;
        const { isAdmin, isRaceAdmin, isMediaWorker, isTimeTracker, isRaceLeader, isTdLeader } = user;
        const showAllButton = isAdmin || isRaceAdmin || isMediaWorker || isTimeTracker || isRaceLeader || isTdLeader;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            race,
            raceClass,
            raceClasses,
            raceClassContestants,
            isFixed = true,
            inputMarkers = [],
        } = this.props;

        const { isDevelopment, path, apiServer, mapType = 'topograatone' } = appState;
        const mapTypeFixed = race.mapType;
        let mapTypeFinal = mapType;
        if (mapTypeFixed) {
            mapTypeFinal = mapTypeFixed;
        }

        const { gpsPoints, lastPoint, polyline, trackers = [], markers = [], lines = [], weather = {} } = gpsPointStore;
        const { files } = raceClass;
        const finalRaceClasses = raceClasses?.filter(rc => !rc.title.includes('⚠️'));

        const colorGenerator = colorCycler();

        return (<>
            <div class='w-100 h-100 d-flex flex-column skipRefresh position-relative'
                // style={`
                //     height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom));
                // `}
            >
                <div
                    class={`w-100 h-100 ${isFixed ? 'position-fixed' : 'position-relative'}`}
                    // style={`
                    //     height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
                    // `}
                    // onScroll={localUtil.captureAllEvents}
                    // onTouchMove={localUtil.captureAllEvents}
                    // onMouseDrag={localUtil.captureAllEvents}
                >
                    <MapContainer
                        center={[lastPoint.lat, lastPoint.lng]}
                        zoom={12}
                        scrollWheelZoom={false}
                        style={{ height: '100%', width: '100%' }}
                        ref={this.setMapRef}
                    >
                        <ResizeMap />
                        {/* <ChangeView center={[lastPoint.lat, lastPoint.lng]} zoom={11} bounds={bounds} /> */}
                        {mapTypeFinal === 'openstreetmap' ? <>
                            <TileLayer
                                key={`tilelayer-${mapTypeFinal}`}
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </> : <>
                            <TileLayer
                                key={`tilelayer-${mapTypeFinal}`}
                                attribution='&copy; <a href="https://statkart.no">Kartverket</a>'
                                url={`https://cache.kartverket.no/v1/wmts/1.0.0/${mapTypeFinal}/default/webmercator/{z}/{y}/{x}.png`}
                                subdomains={['', '2', '3']}
                            />
                        </>}
                        {/* // url={`https://opencache{s}.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=${mapTypeFinal}&zoom={z}&x={x}&y={y}`} */}

                        {/* https://opencache3.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4graatone&zoom=11&x=1184&y=456 */}

                        <ScaleControl position='topleft' imperial={false} />

                        {files && files.length > 0 && files.map(file => {
                            if (file.geoJSON) {
                                return (<>
                                    <GeoJSON
                                        key={`stroke-${file.md5}`}
                                        data={file.geoJSON}
                                        style={{
                                            weight: 10,                      // Stroke weight in pixels
                                            opacity: 0.5,                    // Stroke opacity
                                            color: "rgb(255, 255, 255)",           // Stroke color
                                        }}
                                        pointToLayer={(feature, latlng) => {
                                            return L.marker(latlng, { icon: iconCircle });
                                        }}
                                    />
                                    <GeoJSON
                                        key={`fill-${file.md5}`}
                                        data={file.geoJSON}
                                        style={(feature, idx) => {
                                            // console.log({ feature, idx });
                                            // console.log('name', feature?.properties?.name);

                                            switch (feature.geometry.type) {
                                                case 'Polygon':
                                                case 'MultiPolygon':
                                                    // return {
                                                    //     weight: 5,                       // Stroke weight in pixels
                                                    //     opacity: 0.6,                    // Stroke opacity
                                                    //     color: "rgb(54, 133, 252)",      // Stroke color
                                                    // };
                                                case 'LineString':
                                                case 'MultiLineString':
                                                    // return {
                                                    //     weight: 7,                       // Stroke weight in pixels
                                                    //     opacity: 0.6,                    // Stroke opacity
                                                    //     color: "rgb(54, 133, 252)",      // Stroke color
                                                    // };     // Styles for lines
                                                // Add more cases for other geometry types if needed
                                                default:
                                                    return {
                                                        weight: 6,                       // Stroke weight in pixels
                                                        opacity: 0.6,                    // Stroke opacity
                                                        color: colorGenerator.next().value, //"rgb(54, 133, 252)",      // Stroke color
                                                    }; // Default style
                                            }
                                        }}
                                        // onEachFeature={(feature, layer) => {
                                        //     // console.log({ feature, layer });
                                        //     console.log(feature.properties);
                                        // }}
                                        // filter={(feature) => {
                                        //     // console.log({ feature });
                                        //     return true;
                                        // }}
                                        pointToLayer={(feature, latlng) => {
                                            return L.marker(latlng, { icon: iconCircle });
                                        }}
                                    />
                                </>);
                            }
                        })}

                        {raceClass && raceClass.checkpoints && <>
                            {raceClass.checkpoints.map(cp => {
                                const { lat, lng, rotateDeg, md5 } = cp;
                                // console.log('md5', md5);
                                const weatherData = weather[md5];
                                let currentTemp;
                                if (weatherData) {
                                    const currentWeather = weatherData[0];
                                    currentTemp = currentWeather?.instant?.details?.air_temperature;
                                }
                                // console.log('weatherData', weatherData);
                                // console.log('currentTemp', currentTemp);

                                if (!cp || !cp.lat || !cp.lng) {
                                    return '';
                                }

                                const iconMrk = new L.divIcon({
                                    iconUrl: markerSvg,
                                    iconRetinaUrl: markerSvg,
                                    // popupAnchor:  [0, -15],
                                    iconAnchor: [3,15],
                                    iconSize: [150,30],
                                    html: `
                                    <div
                                        class='d-flex flex-row justify-content-start align-items-center'
                                        style='transform-origin: left bottom; ${rotateDeg ? `transform: rotate(${rotateDeg}deg);` : ''}'
                                    >
                                        <div class='rounded-circle mr-1' style='width: 15px; height: 15px; background-color: #00aa00; border: 2px solid #ffffff;'></div>
                                        <div class=' rounded-pill px-0' style='font-size: 15px; '>
                                            ${cp.name} ${currentTemp ? `<span class='font-weight-light' style='color: ${currentTemp < 0 ? '#44aadd' : '#dd8500'};'>(${util.format(currentTemp, 1)}°C)</span>` : ''}
                                        </div>
                                    </div>`,
                                });
                                return (<>
                                    <Marker position={[lat, lng]} icon={iconMrk}>
                                    </Marker>
                                </>);
                            })}
                        </>}

                        {raceClass && raceClass.waypoints && <>
                            {raceClass.waypoints.map(wp => {
                                const { lat, lng, rotateDeg, icon, md5 } = wp;
                                // console.log('md5', md5);

                                if (!wp || !wp.lat || !wp.lng) {
                                    return '';
                                }

                                const iconMrk = new L.divIcon({
                                    iconUrl: markerSvg,
                                    iconRetinaUrl: markerSvg,
                                    // popupAnchor:  [0, -15],
                                    iconAnchor: [3,15],
                                    iconSize: [150,30],
                                    html: `
                                    <div
                                        class='d-flex flex-row justify-content-start align-items-center'
                                        style='transform-origin: left bottom; ${rotateDeg ? `transform: rotate(${rotateDeg}deg);` : ''}'
                                    >
                                        <div class='d-flex align-items-center justify-content-center rounded-circle mr-1' style='width: 25px; height: 25px; background-color: #8080ff; border: 2px solid #ffffff;'>
                                            ${icon ? `<i class='${icon} text-white'></i>` : ''}
                                        </div>
                                        <div class=' rounded-pill px-0' style='font-size: 15px; '>
                                            ${wp.name}
                                        </div>
                                    </div>`,
                                });
                                return (<>
                                    <Marker position={[lat, lng]} icon={iconMrk}>
                                    </Marker>
                                </>);
                            })}
                        </>}

                        {race && race.waypoints && <>
                            {race.waypoints.map(wp => {
                                const { lat, lng, rotateDeg, icon, md5 } = wp;
                                // console.log('md5', md5);

                                if (!wp || !wp.lat || !wp.lng) {
                                    return '';
                                }

                                const iconMrk = new L.divIcon({
                                    iconUrl: markerSvg,
                                    iconRetinaUrl: markerSvg,
                                    // popupAnchor:  [0, -15],
                                    iconAnchor: [3,15],
                                    iconSize: [150,30],
                                    html: `
                                    <div
                                        class='d-flex flex-row justify-content-start align-items-center'
                                        style='transform-origin: left bottom; ${rotateDeg ? `transform: rotate(${rotateDeg}deg);` : ''}'
                                    >
                                        <div class='d-flex align-items-center justify-content-center rounded-circle mr-1' style='width: 25px; height: 25px; background-color: #8080ff; border: 2px solid #ffffff;'>
                                            ${icon ? `<i class='${icon} text-white'></i>` : ''}
                                        </div>
                                        <div class=' rounded-pill px-0' style='font-size: 15px; '>
                                            ${wp.name}
                                        </div>
                                    </div>`,
                                });
                                return (<>
                                    <Marker position={[lat, lng]} icon={iconMrk}>
                                    </Marker>
                                </>);
                            })}
                        </>}

                        {!realPosition && lines.map(line => {
                            return (<>
                                <Polyline
                                    positions={[[line[0], line[1]], [line[2], line[3]]]}
                                    pathOptions={{
                                        color: 'black',
                                        weight: 1,
                                        dashArray: '4',
                                    }}
                                />
                            </>);
                        })}

                        {isAdmin && currentPos && <>
                            <Marker
                                position={currentPos}
                                draggable={true}
                                icon={iconMarker}
                                eventHandlers={{
                                    click: (e) => {
                                        this.adminAddPosition(e, currentPos);
                                    },
                                }}
                            >
                            </Marker>
                        </>}

                        {markers.map((marker, idx) => {
                            const markerInfo = marker[2];
                            const contestant = markerInfo.contestant || { firstname: 'trackerId', lastname: markerInfo.trackerId };
                            const pt = realPosition ? [markerInfo.point[0], markerInfo.point[1]] : [marker[0], marker[1]];
                            // if (realPosition && markerInfo.isSpread) {
                            //     return null;
                            // }

                            return (<>
                                <Marker
                                    position={pt}
                                    icon={markerInfo.icon}
                                    eventHandlers={{
                                        click: (e) => {
                                            this.viewRaceClassContestant(e, contestant.id, markerInfo);
                                        },
                                    }}
                                >
                                    {/* <Popup>
                                        <span class='font-weight-bold'>{contestant.bib}: {contestant.firstname} {contestant.lastname} {contestant.birth ? `(${util.age(contestant.birth)})` : ''}</span><br />
                                        <span class='font-weight-normal'>Siste posisjon: {util.isoDate(markerInfo.timestamp)}</span><br />
                                        <span class='font-weight-normal'>{markerInfo.country}</span><br />
                                        <span class='font-weight-normal'>{markerInfo.sportsClub}</span><br />
                                        <span class='font-weight-normal'>{markerInfo.kennel}</span><br />
                                    </Popup> */}
                                </Marker>

                                {1===2 && isAdmin && <>
                                    <Marker position={[markerInfo.point[0], markerInfo.point[1]]} icon={markerInfo.icon}>
                                        <Popup>
                                            <span class='font-weight-bold'>{markerInfo.trackerId}</span><br />
                                            <span class='font-weight-normal'>Siste posisjon: {util.isoDate(markerInfo.timestamp)}</span><br />
                                            <span class='font-weight-normal'>Distanse på sporet: {util.format(markerInfo.totalDistanceOnTrack, 1)} km</span><br />
                                            {/* <span class='font-weight-normal'>{util.format(markerInfo.speed * 3.6, 2)} m/s</span><br /> */}
                                            <span class='font-weight-normal'>Batteri: {markerInfo.battery}%</span><br />
                                        </Popup>
                                    </Marker>
                                </>}
                                {/* {markerInfo.last10Points && markerInfo.last10Points.length > 0 && <>
                                    <Polyline color={'red'} positions={markerInfo.last10Points.map(p => [p[0], p[1]])} />
                                </>} */}
                                {/* {JSON.stringify(markerInfo.last10Points, null, 4)} */}
                            </>);
                        })}

                        {yourPoint && yourPosition && <>
                            <Marker position={yourPoint} icon={iconMarkerPerson}>
                                <Popup position={yourPoint}>
                                    Your location: <pre>{JSON.stringify(yourPoint, null, 2)}</pre>
                                </Popup>
                            </Marker>
                        </>}

                        {inputMarkers.map((marker, idx) => {
                            const { lat, lng, title } = marker;
                            return (<>
                                <Marker position={[lat, lng]} icon={iconMarkerPin}>
                                    <Popup>
                                        <span class='font-weight-bold'>{title}</span><br />
                                    </Popup>
                                </Marker>
                            </>);
                        })}

                        {isAdmin && trackers.map((tracker, idx) => {
                            const contestant = tracker.contestant;
                            // console.log('contestant.bib', contestant.bib);
                            if (!tracker.points || tracker.points.length === 0) {
                                return null;
                            }
                            return (<>
                                <Polyline
                                    color={tracker.lineColor || getColor(idx)}
                                    positions={tracker.points.map(p => [p[0], p[1]])}
                                    weight='2'
                                    dashArray='4, 5'
                                />
                            </>);
                        })}
                    </MapContainer>

                    {/* <xmp>{JSON.stringify(bounds, null, 4)}</xmp> */}
                    {/* {trackers.map(tracker => {
                        return (<>
                            <xmp>{JSON.stringify(tracker.points.map(p => [p[0], p[1]]))}</xmp>
                        </>);
                    })} */}
                </div>

                <div
                    class='px-3 py-1 position-absolute d-flex flex-row justify-content-center'
                    style='top: 10px; left: 50%; transform: translateX(-50%); z-index: 100000; width: 85vw;'
                >
                    {finalRaceClasses && finalRaceClasses.map((rc, idx) => {
                        return <>
                            <button
                                type='button'
                                class={`btn btn-sm overflow-hidden btn-${(rcId === rc.id && !skipRaceClass) ? 'primary' : 'secondary'} rounded-${idx === 0 ? 'pill-left' : idx === finalRaceClasses.length - 1 ? 'pill-right' : 'none'}`}
                                onClick={this.setRaceClass}
                                data-id={rc.id}
                            >
                                <nobr>{rc.title}</nobr>
                            </button>
                        </>;
                    })}
                    {showAllButton && <button
                        type='button'
                        class={`btn btn-sm btn-${skipRaceClass ? 'primary' : 'secondary'} rounded-pill ml-2`}
                        data-skip={skipRaceClass ? 0 : 1}
                        data-id={raceClass.id}
                        onClick={this.setRaceClass}
                    >
                        Show all
                    </button>}
                </div>
                <div
                    class='px-3 py-1 position-absolute d-flex flex-column'
                    style='top: 50px; right: 0px; z-index: 100000;'
                >
                    {/* {isAdmin && <button
                        type='button'
                        class={`btn btn-sm btn${realPosition ? '-success' : '-outline-secondary'} rounded-pill`}
                        onClick={this.toggleRealPosition}
                    >
                        <i class={`fa-solid fa-${realPosition ? 'location-crosshairs' : 'location-crosshairs-slash'}`} />
                    </button>} */}
                    {isAdmin && <div class='input-group'>
                        <div class='input-group-prepend'>
                            <span
                                class='input-group-text rounded-pill-left'
                                style={`background-color: rgba(255, 255, 255, 0.3);`}
                            >
                                <i class='fa-regular fa-timer text-danger'></i>
                            </span>
                        </div>
                        <input
                            class={`form-control form-control-sm rounded-${cutoffDate ? 'none' : 'pill-right'} text-danger`}
                            type='datetime-local'
                            onInput={this.changeReportDate}
                            value={cutoffDate || util.isoDate(raceClass.endDate, true)}
                        />
                        {cutoffDate && <div class='input-group-append'>
                            <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                <i class='fa-solid fa-circle-xmark'></i>
                            </button>
                        </div>}
                    </div>}
                    <div class='d-flex flex-row'>
                        <button
                            type='button'
                            class={`btn btn-sm btn${yourPosition ? '-success' : '-outline-secondary'} rounded-pill`}
                            onClick={this.toggleCurrentPosition}
                        >
                            <i class={`fa-solid fa-location-crosshairs`} />
                        </button>

                        <div class='d-flex flex-column ml-2' style='max-width: 50vw;'>
                            <div class='input-group mt-1'>
                                <div class='input-group-prepend'>
                                    <span
                                        class='input-group-text rounded-pill-left'
                                        style={`background-color: rgba(255, 255, 255, 0.3);`}
                                    >
                                        <i class='fa-regular fa-magnifying-glass'></i>
                                    </span>
                                </div>
                                <input
                                    class={`form-control form-control-sm ${!search ? 'rounded-pill-right' : ''}`}
                                    style={`background-color: rgba(255, 255, 255, 0.3); width: 50px;`}
                                    type='text'
                                    value={search}
                                    onInput={this.searchInput}
                                    onKeyDown={this.handleKeyDown}
                                />
                                {search && <div class='input-group-append'>
                                    <button class='btn btn-danger btn-sm rounded-pill-right' type='button' onClick={this.clearSearch}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>
                            {mapTypeFixed ? <></> : <>
                                <div class='input-group mt-1'>
                                    <div class='input-group-prepend'>
                                        <span
                                            class='input-group-text rounded-pill-left'
                                            style={`background-color: rgba(255, 255, 255, 0.3);`}
                                        >
                                            <i class='fa-regular fa-map'></i>
                                        </span>
                                    </div>
                                        <select
                                            class={`form-control form-control-sm ${!search ? 'rounded-pill-right' : ''}`}
                                            onInput={this.changeMapType}
                                            value={mapType}
                                        >
                                            <option value='topo'>Kartverket - Topo</option>
                                            <option value='topograatone'>Kartverket - Topo grå</option>
                                            {/* <option value='norges_grunnkart'>Kartverket - Grunnkart</option>
                                            <option value='norges_grunnkart_graatone'>Kartverket - Grunnkart grå</option>
                                            <option value='terreng_norgeskart'>Kartverket - Terreng</option> */}
                                            {/* <option value='kartdata3'>Kartverket - Kartdata</option> */}
                                            {/* <option value='egk'>Kartverket - Enkelt</option> */}
                                            {/* <option value='toporaster4'>Kartverket - europa_forenklet</option> */}

                                            <option value='openstreetmap'>Open Streetmap</option>
                                        </select>

                                    {/* // Layers from Kartverket that shows in LeafletJS without extra plugins
                                    const availableLayers = [
                                        { id: 'norgeskart_bakgrunn', title: 'Norgeskart bakgrunn' },
                                        { id: 'topo4', title: 'Topografisk norgeskart' },
                                        { id: 'topo4graatone', title: 'Topografisk norgeskart (gråtone)' },
                                        { id: 'europa', title: 'Europakart' },
                                        { id: 'kartdata3', title: 'Kartdata 3' },
                                        { id: 'norges_grunnkart', title: 'Norges Grunnkart' },
                                        { id: 'norges_grunnkart_graatone', title: 'Norges Grunnkart (gråtone)' },
                                        { id: 'egk', title: 'Europeiske grunnkart' },
                                        { id: 'havbunn_grunnkart', title: 'Havbunn grunnkart' },
                                        { id: 'terreng_norgeskart', title: 'Terreng norgeskart' },
                                        { id: 'sjokartraster', title: 'Sjøkart - raster' },
                                        { id: 'toporaster4', title: 'Topografisk raster' },
                                        { id: 'fjellskygge', title: 'Fjellskygge' },
                                        { id: 'bakgrunnskart_forenklet', title: 'Forenklet bakgrunnskart' },
                                    ]; */}
                                </div>
                            </>}
                            <button
                                type='button'
                                class={`btn btn-sm btn-success rounded-pill mt-1`}
                                onClick={this.showRaceResults}
                            >
                                <i class='fa-solid fa-list' /> <Text id='race.results'>Results</Text>
                            </button>

                        </div>
                    </div>

                    {((race && race.sponsors?.length > 0) || (raceClass && raceClass.sponsors?.length > 0)) && <>
                        <div class='w-100 d-flex flex-column justify-content-between mt-3'>
                            {race && race.sponsors?.length > 0 && race.sponsors.map(sponsor => {
                                return (<>
                                    <div class='mx-3 d-flex flex-row justify-content-end'>
                                        <img
                                            src={localUtil.displayImg(sponsor, this.props, '220x')}
                                            class='img-fluid mx-1'
                                            style='max-height: 40px;'
                                        />
                                    </div>
                                </>);
                            })}
                            {raceClass && raceClass.sponsors?.length > 0 && raceClass.sponsors.map(sponsor => {
                                return (<>
                                    <div class='mx-3 d-flex flex-row justify-content-end'>
                                        <img
                                            src={localUtil.displayImg(sponsor, this.props, '220x')}
                                            // class='img-fluid mx-1'
                                            style='max-height: 40px;'
                                        />
                                    </div>
                                </>);
                            })}
                        </div>
                    </>}
                </div>

                {/* {isAdmin && isFixed && <div
                    class={`w-90 rounded-lg px-3 py-1 position-absolute ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} overflow-auto`}
                    style='
                        top: 82vh;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 2;
                        height: 12vh !important;
                    '
                >
                    <div class='table-responsive' style='font-size: 0.8em;'>
                        <table class='table table-striped table-hover table-sm'>
                            <thead>
                                <tr>
                                    <th class='p-0'>first timestamp</th>
                                    <th class='p-0'>timestamp</th>
                                    <th class='p-0'>trackerId</th>
                                    <th class='p-0'>bib: name</th>
                                    <th class='p-0'>elevation</th>
                                    <th class='p-0'>speed</th>
                                    <th class='p-0 text-center'>battery</th>
                                    <th class='p-0 text-center'>points</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trackers.sort(fieldSorter(['-count'])).map(pt => {
                                    const rcc = raceClassContestants.find(rcc => rcc.id === pt.raceClassContestant);
                                    return (<>
                                        <tr
                                            class='box-line'
                                            onClick={e => this.viewRaceClassContestant(e, rcc.id)}
                                            // data-id={id}
                                        >
                                            <td class='p-0'>{util.isoDate(pt.firstTimestamp)}</td>
                                            <td class='p-0'>{util.isoDate(pt.timestamp)}</td>
                                            <td class='p-0'>{pt.trackerId}</td>
                                            <td class='p-0'>{rcc?.bib}: {rcc?.firstname} {rcc?.lastname}</td>
                                            <td class='p-0'>{pt.elevation} moh</td>
                                            <td class='p-0'>{util.format(pt.speed * 3.6, 1)} km/t</td>
                                            <td class='p-0 text-right'>{pt.battery}%</td>
                                            <td class='p-0 text-right'>{pt.count}</td>
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>} */}

            </div>
        </>);
    }
}

export default RaceClassContestantList;
