import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class PartStore extends LocalModel {
    constructor() {
        super('part', {
            namePlural: 'parts',
            sort: '-createdDate',
            limit: 500,
            api: {
                search: {
                    url: '/api/parts/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/parts/',
                    params: {},
                },
                save: {
                    url: '/api/parts/',
                    params: {},
                },
                delete: {
                    url: '/api/parts/',
                    params: {},
                },
            },
        });
    }

    @observable newPart = {};

    @observable newLoan = {};

    @observable part = {};

    @observable newMovement = {
        movePart: 0,
    };

    @observable parts = [];

    @observable tags = [];

    @observable tagUsages = [];

    @observable installations = [];

    @observable installationsEmpty = [];

    @observable loaners = [];

    @observable foundLoaners = [];

    @observable foundParts = [];

    @observable existingTags = [];

    @observable sortBy = ['name', '-qty', '-updatedDate'];

    @observable sortTitle = '-updatedDate';

    @action
    setSortTitle = field => {
        this.sortTitle = field;
    }

    async loanToFriend({ id, installationId, newLoan }) {
        // const idx = this.notifications?.findIndex(e => e.id === id);
        // const obj = this.notifications[idx];
        const response = await util.fetchApi(`/api/parts/${id}/loan`, {
            publish: true,
            method: 'POST',
        }, {
            installation: installationId,
            // requiredReturnDate
            ...newLoan,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 201:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                this.updateKeyValue('newLoan', {});
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async loanReturn({ loanId, comment }) {
        const response = await util.fetchApi(`/api/parts/loan/${loanId}/return`, {
            publish: true,
            method: 'PATCH',
        }, {
            endDate: new Date(),
            comment,
            version: this.currentVersion,
        });
        switch (response.status) {
            case 202:
                return response;
            case 401:
                // PubSub.publish(topics.LOG_OUT);
                // route('/');
                break;
        }
    }

    async sendOwnerReminder(partId) {
        const response = await util.fetchApi(`/api/parts/${partId}/sendOwnerReminder`, {
            publish: true,
            method: 'GET',
        }, {});
        switch (response.status) {
            case 200:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                break;
        }
    }

    async sendOwnerExcuse(partId) {
        const response = await util.fetchApi(`/api/parts/${partId}/sendOwnerExcuse`, {
            publish: true,
            method: 'GET',
        }, {});
        switch (response.status) {
            case 200:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                break;
        }
    }

    async updatePartUrl(id, idx) {
        const response = await util.fetchApi(`/api/parts/url/${id}?idx=${idx}`, {
            publish: true,
            method: 'GET',
        }, {});
        switch (response.status) {
            case 202:
                // obj.read = true;
                // this.updateObjectField('notifications', idx, obj);
                return response;
            case 401:
                break;
        }
    }
}

const store = new PartStore();
export default store;
