import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';

@withText(props => ({
    conventionNumber: <Text id='input.conventionNumber-help'>conventionNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
    description: <Text id='input.description-help'>description</Text>,
    url: <Text id='input.url-help'>url</Text>,
}))
@observer
class NewConvention extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('convention'),
        };
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { id, isNew } = props;
        const { conventionStore } = this.props.stores;
        if (emptyFirst) {
            // conventionStore.updateKeyValue('convention', {});
        }
        await conventionStore.load(id, false, {
            addData: ['tags', 'members', 'allMembers'],
            query: {
                hostname: conventionStore.currentHostname,
            },
        });
        const { members } = conventionStore;
        if (members && members.length > 0) {
            conventionStore.updateKeyValue('adminMembers', members);
        }
        if (isNew) {
            conventionStore.loadIntoNew({
                id: null,
                fields: ['public', 'title', 'startDate', 'endDate', 'stageInterval', 'stageSlots', 'members', 'tags', 'description'],
                query: {
                    hostname: conventionStore.currentHostname,
                },
            });
        }
    }

    createConvention = async () => {
        const { callback = () => {} } = this.props;
        const { conventionStore } = this.props.stores;
        const { newConvention } = conventionStore;

        const response = await conventionStore.insert(newConvention);

        conventionStore.updateKeyValue('newConvention', {});
        callback();
    }

    chooseItem = (e) => {
        const { id } = e.target.closest('.similar-item').dataset;
        const { conventionStore } = this.props.stores;
        const { foundList } = conventionStore;
        const item = foundList.find((item) => item.id === parseInt(id, 10));
        conventionStore.updateKeyValue('newConvention', {
            ...item,
        });
        conventionStore.resetSearch();
    }

    toggleSimilar = () => {
        const { showSimilar } = this.state;
        this.setState({
            showSimilar: !showSimilar,
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { week, isNew, drawerLevel, installation, lookupArray } = this.props;
        const { sections, showSimilar } = this.state;
        const { userStore, conventionStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const { newConvention, foundList, foundListKeys } = conventionStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center position-relative'>
                <div class='w-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>
                    {/* <div class='w-100 d-flex flex-column justify-content-start'>
                        <xmp>{JSON.stringify(user.settings, null, 2)}</xmp>
                    </div> */}
                    {/* <xmp>{JSON.stringify(newCustomer, null, 4)}</xmp> */}
                    {/* <xmp>{JSON.stringify(installation, null, 4)}</xmp> */}
                    <div class='font-weight-lighter px-3 box-header mt-0'>
                        {isNew ? <Text id='convention.new'>New convention</Text> : <Text id='convention.edit'>Edit convention</Text>}
                    </div>

                    <div class='w-100 d-flex flex-column justify-content-start'>
                        <div class='bg-light d-flex flex-column justify-content-start h-100 pt-3'>
                            <FormFields
                                stores={this.props.stores}
                                sections={sections}
                                holdingStore={conventionStore}
                                objectName={isNew ? 'newConvention' : 'convention'}
                                isNew={isNew}
                                drawerLevel={drawerLevel}
                                isAdmin={false}
                                hideAdmin={true}
                                lookupArray={lookupArray}
                                rightHeader={isNew && foundList?.length > 0 ? <span class='text-success font-weight-bold'><i class='fa-solid fa-circle-info' /> Similar conventions ({foundList.length})</span> : null}
                                rightHeaderClick={this.toggleSimilar}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {isNew && <div class='w-100 d-flex flex-column justify-content-start mt-3 px-4 py-3 bg-light fixed-bottom' style={`bottom: 0; left: 0;`}>
                <button type='button' class='btn btn-primary rounded-pill' onClick={this.createConvention}>
                    {isNew ? <Text id='convention.create'>Create convention</Text> : <Text id='convention.save'>Save convention</Text>}
                </button>
            </div>}
        </>);
    }
}

export default NewConvention;
