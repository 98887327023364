import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import { has } from 'mobx';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

// function timeUsed(rcc, sortedCheckpoints) {
//     const startTime = rcc.startTime;
//     let lastTimestamp = startTime;


//     sortedCheckpoints.forEach((cp, idx) => {
//         const rccCp = rcc.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
//         lastTimestamp = rccCp.timestampOut || rccCp.timestampIn;
//     });

//     const tu = util.dateDiff(startTime, lastTimestamp)
//     return tu.seconds;
// }

@withText(props => ({
    titleText: <Text id='race-class-contestants.title'>Race Contestants</Text>,
}))
@observer
class RaceClassContestantList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewRaceClassContestant = e => {
        const {
            view,
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassContestant', {
            id,
            // title,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            showResults: true,
            callback: () => {
                // appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    viewRaceClassCheckpoint = e => {
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;

        const { md5 } = e.target.closest('.cp-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassCheckpoint', {
            md5,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            callback: () => {
                // appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    calculateTimeUsed = () => {
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        const { raceClassContestantStore } = this.props.stores;

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        raceClassContestantStore.calculateTimeUsed(sortedCheckpoints, raceClass.id);
    }

    changeReportDate = async e => {
        const { value: cutoffDate } = e.target;
        const { stopTimer = () => {} } = this.props;
        stopTimer();
        this.setState({ cutoffDate }, () => {
            this.loadRaceResults();
        });
    }

    loadRaceResults = async () => {
        const { cutoffDate } = this.state;
        const {
            race,
            raceClassId,
        } = this.props;
        const { raceClassContestantStore } = this.props.stores;
        await raceClassContestantStore.loadPublic({
            query: {
                race: race.id,
                raceClass: raceClassId,
                addResults: true,
                cutoffDate,
            }
        });
    }

    clearCutoffDate = e => {
        this.setState({ cutoffDate: null }, () => {
            this.loadRaceResults();
        });
    }

    componentDidMount() {
        this.calculateTimeUsed();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.raceClassContestants !== this.props.raceClassContestants) {
            this.calculateTimeUsed();
        }
    }

    componentWillUnmount() {
        const { stopTimer = () => {} } = this.props;
        stopTimer();
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { cutoffDate } = this.state;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            race,
            raceClass,
            raceClassContestants,
            classNames,
        } = this.props;

        const tdWidth = '45px';
        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        let prevContestant = null;
        let currentPosistion = 0;
        let currentPosistionRaceTime = 0;

        return (<>
            <div class={`w-100 d-flex flex-column ${classNames}`}>
                <div class='w-100 d-flex flex-row justify-content-between align-items-center bg-warning px-3 rounded-lg'>
                    <div>
                        {race.title}: {raceClass.title}
                    </div>
                    {isAdmin && <div>
                        <div class='input-group'>
                            <div class='input-group-prepend'>
                                <span
                                    class='input-group-text rounded-pill-left'
                                    style={`background-color: rgba(255, 255, 255, 0.3);`}
                                >
                                    <i class='fa-regular fa-timer text-danger'></i>
                                </span>
                            </div>
                            <input
                                class={`form-control form-control-sm rounded-${cutoffDate ? 'none' : 'pill-right'} text-danger`}
                                type='datetime-local'
                                onInput={this.changeReportDate}
                                value={cutoffDate || util.isoDate(raceClass.endDate, true)}
                            />
                            {cutoffDate && <div class='input-group-append'>
                                <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                    <i class='fa-solid fa-circle-xmark'></i>
                                </button>
                            </div>}
                        </div>
                    </div>}
                </div>
                {raceClass.warning && <div class='w-100 d-flex flex-column my-1 px-2'>
                    <div class='alert alert-danger' role='alert'>
                        {raceClass.warning}
                    </div>
                </div>}
                {raceClass.sortOrderResults && !raceClass.doNotCountRestTime && <div class='w-100 d-flex flex-column my-1 px-2'>
                    <div class='alert alert-info' role='info'>
                        <Text id='race.result-info-timeused'>RaceClass do not use time equalization. Only race time is counting.</Text>
                    </div>
                </div>}
                <div class={`w-100 d-flex px-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    <div class='table-responsive' style='font-size: 0.8em;'>
                        <table class='table table-striped table-hover table-sm'>
                            <thead>
                                <tr>
                                    {raceClass.checkpoints && raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])).map((cp, idx) => {
                                        const nextCheckpoint = sortedCheckpoints[idx + 1];
                                        if (cp.sortOrder === 1) {
                                            return (<>
                                                <th
                                                    class='p-0 text-center cp-line'
                                                    style={`
                                                        width: ${tdWidth} !important;
                                                        min-width: ${tdWidth} !important;
                                                    `}
                                                    onClick={this.viewRaceClassCheckpoint}
                                                    data-md5={cp.md5}
                                                >
                                                    <Text id='race.result-start'>Start</Text>
                                                </th>
                                                <th class='p-0 font-weight-lighter' style='
                                                    width: 10px !important;
                                                    min-width: 10px !important;
                                                '>{nextCheckpoint?.distanceFromPrev || <>&nbsp;</>}</th>
                                            </>);
                                        }
                                        if (cp.sortOrder === raceClass.checkpoints.length) {
                                            return (<>
                                                <th
                                                    class='p-0 text-center cp-line'
                                                    style={`
                                                        width: ${tdWidth} !important;
                                                        min-width: ${tdWidth} !important;
                                                    `}
                                                    onClick={this.viewRaceClassCheckpoint}
                                                    data-md5={cp.md5}
                                                >
                                                    {raceClass.sortOrderResults && raceClass.doNotCountRestTime ? <>
                                                        <nobr>{cp.name}</nobr>
                                                    </> : <>
                                                        <Text id='race.result-finish'>Finish</Text>
                                                    </>}
                                                </th>
                                                <th class='p-0 font-weight-lighter' style='
                                                    width: 10px !important;
                                                    min-width: 10px !important;
                                                '>{nextCheckpoint?.distanceFromPrev || <>&nbsp;</>}</th>
                                            </>);
                                        }
                                        return (<>
                                            <th
                                                class='p-0 text-center cp-line'
                                                style={`
                                                    width: ${tdWidth} !important;
                                                    min-width: ${tdWidth} !important;
                                                `}
                                                onClick={this.viewRaceClassCheckpoint}
                                                data-md5={cp.md5}
                                            >
                                                {raceClass.sortOrderResults && raceClass.doNotCountRestTime ? <>
                                                    <nobr>{cp.name}</nobr>
                                                </> : <>
                                                    <i class='fa-duotone fa-campground' />
                                                </>}


                                            </th>
                                            <th class='p-0 font-weight-lighter' style='
                                                width: 10px !important;
                                                min-width: 10px !important;
                                            '>{nextCheckpoint?.distanceFromPrev || <>&nbsp;</>}</th>
                                        </>);
                                    })}
                                    <th class='p-0'>Deltaker</th>
                                </tr>
                            </thead>
                            <tbody>
                                {raceClassContestants.filter(e => e.bib > 0).sort(fieldSorter(['hasScratched', '-lastCheckpoint', '-lastCheckpointLeft', (raceClass.sortOrderResults || 'lastTimestamp'), 'bib', '-name'])).map((rcc, posIdx) => {
                                    const { id, bib, firstname, lastname, startTime, country, timeused, raceTimeused, totalRaceTime } = rcc;
                                    const cpLength = rcc.lastCheckpoint > 0 ? rcc.lastCheckpoint : 1;
                                    let rccHasScratched = false;
                                    // console.log(rcc)
                                    let firstTimeOut = null;
                                    let firstTimeOutRaw = null;
                                    let timeEqualization = null;
                                    let totalActualRestMinutes = 0
                                    let totalRequiredRestMinutes = 0;
                                    let hasTakenOptionalRest = false;

                                    const prevRcc = { ...prevContestant };
                                    prevContestant = rcc;
                                    return (<>
                                        <tr
                                            class='box-line'
                                            onClick={this.viewRaceClassContestant}
                                            data-id={id}
                                        >
                                            {sortedCheckpoints.slice(0, cpLength).map((cp, idx) => {
                                                const _prevCp = sortedCheckpoints[idx - 1];
                                                const prevRccCp = rcc.checkpoints?.find(e => e.checkpoint === _prevCp?.md5) || {};
                                                const prevHasScratced = prevRccCp.timstampScratch;
                                                const rccCp = rcc.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
                                                if (rccCp.optionalRest) {
                                                    hasTakenOptionalRest = rccCp.optionalRest;
                                                }
                                                const hasLeftCp = rccCp.timestampOut;
                                                const hasScratced = rccCp.timstampScratch;
                                                const isInCp = rccCp.timestampIn && !rccCp.timestampOut;
                                                const isFinished = raceClass.checkpoints.length === idx + 1;
                                                const isTimeEq = cp.timeEqualization == 1 ? true : false;
                                                const isRestRequired = cp.requiredRestMinutes ? true : false;
                                                const isPrologue = cp.isPrologue == 1;
                                                const isStartline = cp.isStartLine == 1;
                                                const isLastCp = cp.sortOrder === raceClass.checkpoints.length - 1;
                                                const timeInCp = !isFinished && rccCp.timestampIn && rccCp.timestampOut ? util.dateDiff(rccCp.timestampIn, rccCp.timestampOut) : 0;

                                                let prevTimeInCp = 0;
                                                let timeIsEqualToPrev = false;
                                                if (isFinished) {
                                                    // rcc.position = posIdx + 1;
                                                    const prevRccCp = prevRcc.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
                                                    prevTimeInCp = prevRccCp.timestampIn;
                                                    timeIsEqualToPrev = prevRccCp.timestampIn === rccCp.timestampIn;
                                                    if (!timeIsEqualToPrev) {
                                                        currentPosistion = posIdx + 1;
                                                        // rcc.position = posIdx;
                                                    }
                                                    if (prevRcc.totalRaceTime !== rcc.totalRaceTime) {
                                                        currentPosistionRaceTime = posIdx + 1;
                                                    }
                                                }
                                                // {prevRcc && <>
                                                //     {prevRcc.lastname} {prevRcc.firstname} {prevRcc.bib}
                                                // </>}

                                                let totRequiredRestForContestant = 0;
                                                let remainingRestMinutes;
                                                if (!isPrologue && !isStartline) {
                                                    totalRequiredRestMinutes += cp.requiredRestMinutes ? parseInt(cp.requiredRestMinutes, 10) : 0;
                                                    totRequiredRestForContestant = totalRequiredRestMinutes + (rcc.timeEqualization?.minutes || 0);
                                                    totalActualRestMinutes += timeInCp?.minutes || 0;
                                                    if (isLastCp && !hasScratced && isInCp) {
                                                        if (cp.useRemainingRest) {
                                                            remainingRestMinutes = totRequiredRestForContestant - totalActualRestMinutes;
                                                        } else {
                                                            remainingRestMinutes = cp.requiredRestMinutes;
                                                        }
                                                        if (remainingRestMinutes < 0) {
                                                            remainingRestMinutes = 0;
                                                        }
                                                        firstTimeOutRaw = util.isoDate(new Date(rccCp.timestampIn).getTime() + (remainingRestMinutes  * 60 * 1000));
                                                        firstTimeOut = util.isoDateCompact(new Date(rccCp.timestampIn).getTime() + (remainingRestMinutes  * 60 * 1000));
                                                    } else if (!hasScratced && isInCp) {
                                                        if (cp.requiredRestMinutes > 0) {
                                                            firstTimeOut = util.isoDateCompact(new Date(rccCp.timestampIn).getTime() + (cp.requiredRestMinutes * 60 * 1000));
                                                        }
                                                        if (isTimeEq) {
                                                            firstTimeOut = util.isoDateCompact(new Date(rccCp.timestampIn).getTime() + (cp.requiredRestMinutes * 60 * 1000) + (rcc.timeEqualization?.minutes * 60 * 1000));
                                                            timeEqualization = util.secToHms(rcc.timeEqualization?.seconds, true);
                                                        }
                                                        if (rccCp.optionalRest) {
                                                            if (cp.requiredRestMinutesOptional > 0) {
                                                                firstTimeOut = util.isoDateCompact(new Date(rccCp.timestampIn).getTime() + (cp.requiredRestMinutesOptional * 60 * 1000));
                                                            }
                                                            if (cp.timeEqualizationOptional) {
                                                                firstTimeOut = util.isoDateCompact(new Date(rccCp.timestampIn).getTime() + (cp.requiredRestMinutesOptional * 60 * 1000) + (rcc.timeEqualization?.minutes * 60 * 1000));
                                                                timeEqualization = util.secToHms(rcc.timeEqualization?.seconds, true);
                                                            }
                                                        }
                                                    }
                                                }

                                                if (prevHasScratced) {
                                                    return (<>
                                                        {/* <td class={`p-0 text-center`}>
                                                            &nbsp;
                                                        </td> */}
                                                    </>);
                                                }

                                                return (<>
                                                    {cp.sortOrder === 1 ? <>
                                                        <td
                                                            class={`p-0 text-center ${rccCp.positionInToCheckpoint ? 'bg-light text-dark' : ''}`}
                                                        >
                                                            {util.isoTime(startTime)}
                                                        </td>
                                                    </> : <>
                                                        {!hasLeftCp && hasScratced ? <>
                                                            <td
                                                                class={`p-0 text-center bg-danger rounded-pill-right text-white`}
                                                            >
                                                                {(isInCp || hasLeftCp) && <>
                                                                    <div class='float-left bg-primary px-1' style='width: 30px;'>
                                                                        {raceClass.sortOrderResults ? <>
                                                                            <span class=''>
                                                                                {rccCp.positionTimeusedOnStage}
                                                                            </span>
                                                                            {/* {raceClass.doNotCountRestTime && <>
                                                                                <span class='font-weight-lighter text-monospace ml-2 mr-2'>{rccCp.timeused}</span>
                                                                            </>} */}
                                                                        </> : <>
                                                                            {rccCp.positionInToCheckpoint}
                                                                        </>}
                                                                    </div>
                                                                </>}
                                                                <i class='fa-regular fa-xmark text-white' />
                                                            </td>
                                                        </> : <>
                                                            {isFinished ? <>
                                                                <td
                                                                    class={`p-0 text-center ${rccCp.positionInToCheckpoint ? 'bg-success rounded-pill-right text-white' : ''}`}
                                                                >
                                                                    {raceClass.sortOrderResults && raceClass.doNotCountRestTime ? <>
                                                                        <span class='ml-2'>
                                                                            {rccCp.positionTimeusedOnStage}
                                                                        </span>
                                                                        <span class='font-weight-lighter text-monospace ml-2 mr-2'>{rccCp.timeused}</span>
                                                                    </> : <>
                                                                        {rccCp.positionInToCheckpoint && <>
                                                                            {/* {raceClass.sortOrderResults ? posIdx + 1 : rccCp.positionInToCheckpoint} */}
                                                                            {timeIsEqualToPrev ? <>
                                                                                {currentPosistion}
                                                                            </> : <>
                                                                                {posIdx + 1}
                                                                            </>}

                                                                            {/* :: {util.secToHms(totalRaceTime)} :: {rccCp.positionTimeusedOnStage} :: {currentPosistionRaceTime} */}

                                                                            {/* prevTimeInCp: {prevTimeInCp}<br />
                                                                            timeIsEqualToPrev: {timeIsEqualToPrev ? 'true' : 'false'}<br /> */}
                                                                        </>}
                                                                    </>}

                                                                </td>
                                                            </> : <>
                                                                <td
                                                                    class={`p-0 text-center ${rccCp.positionInToCheckpoint ? `${isInCp ? 'bg-success rounded-pill-right' : 'bg-primary'} text-white` : ''}`}
                                                                >
                                                                    {raceClass.sortOrderResults && raceClass.doNotCountRestTime ? <>
                                                                        <span class='ml-2'>
                                                                            {rccCp.positionTimeusedOnStage}
                                                                        </span>
                                                                        <span class='font-weight-lighter text-monospace ml-2'>{rccCp.timeused}</span>
                                                                    </> : <>
                                                                        {rccCp.positionInToCheckpoint}
                                                                    </>}
                                                                </td>
                                                            </>}
                                                        </>}
                                                    </>}

                                                    {/* {hasLeftCp && !isInCp && cp.sortOrder === rcc.lastCheckpointLeft && <> */}
                                                        <td
                                                            class={`p-0 text-center ${hasLeftCp ? `${rcc.hasScratched && hasLeftCp && cp.sortOrder === rcc.lastCheckpointLeft ? 'bg-danger rounded-pill-right' : 'bg-primary'} text-white
                                                                ${hasLeftCp && !isInCp && cp.sortOrder === rcc.lastCheckpointLeft ? 'rounded-pill-right' : ''}
                                                            ` : ''}`}
                                                        >

                                                            {/* positionInToCheckpoint: {rcc.positionInToCheckpoint}<br />
                                                            lastCheckpoint: {rcc.lastCheckpoint}<br />
                                                            lastCheckpointLeft: {rcc.lastCheckpointLeft}<br />
                                                            lastTimestamp: {rcc.lastTimestamp}<br /> */}
                                                            {/* hasLeftCp: {hasLeftCp ? 'true' : 'false'}<br />
                                                            rcc.hasScratched: {rcc.hasScratched ? 'true' : 'false'}<br />
                                                            cp.sortOrder: {cp.sortOrder}<br />
                                                            rcc.lastCheckpointLeft: {rcc.lastCheckpointLeft}<br /> */}
                                                            {hasLeftCp && !isInCp && cp.sortOrder === rcc.lastCheckpointLeft ? <>
                                                                <i class='fa-regular fa-angles-right' />
                                                            </> : <></>}

                                                            {/* {isInCp && !hasScratced && isRestRequired && <>
                                                                firstTimeOut: {firstTimeOut}
                                                                timeEqualization: {rcc.timeEqualization?.seconds}
                                                            </>} */}
                                                            {/* hasScratced: {hasScratced ? 'true' : 'false'}<br /> */}
                                                            {/* sortOrder:"{rccCp.sortOrder}" === "{rcc.lastCheckpointLeft}" */}
                                                            {/* {raceClass.checkpoints.length} === {idx + 1} */}
                                                            {/* isFinished: {isFinished ? 'true' : 'false'} */}

                                                            {isAdmin && isLastCp && !hasScratced && isInCp && <>
                                                                <details onClick={e => e.stopPropagation()} class='text-right'>
                                                                    <summary>
                                                                        <nobr>
                                                                        <i class='fa-solid fa-user-police' />
                                                                        </nobr>
                                                                    </summary>
                                                                    <nobr><span class='font-weight-lighter'>totalRequiredRestMinutes</span>: {totalRequiredRestMinutes}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>totRequiredRestForContestant</span>: {totRequiredRestForContestant}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>totalActualRestMinutes</span>: {totalActualRestMinutes}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>remainingRestMinutes</span>: {remainingRestMinutes}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>cp.requiredRestMinutes</span>: {cp.requiredRestMinutes}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>remainingRestTime</span>: {util.secToHms(remainingRestMinutes * 60)}</nobr><br />

                                                                    <nobr><span class='font-weight-lighter'>rccCp.timestampIn</span>: {util.isoDate(rccCp.timestampIn)}</nobr><br />
                                                                    <nobr><span class='font-weight-lighter'>firstTimeOutRaw</span>: {firstTimeOutRaw}</nobr><br />
                                                                </details>
                                                            </>}
                                                        </td>
                                                    {/* </>} */}
                                                </>);
                                            })}
                                            <td class={`p-0 `}
                                                colspan='23'
                                            >
                                                <nobr>
                                                    {bib ? <>
                                                        <span class='badge badge-dark rounded-pill mr-1 ml-0' style={`width: 35px; ${raceClass.bibColor ? `background-color: ${raceClass.bibColor} !important;` : ''}`}>{bib}</span>
                                                    </> : ''} {localUtil.getFlagSvg(country, 15, false, 'rounded')} {util.ucfirst(firstname)} {util.ucfirst(lastname)}
                                                    {/* {isAdmin && <>
                                                        <small class='text-muted ml-2'>
                                                            (start: {util.isoDate(rcc.startTime)}
                                                            -end: {util.isoDate(rcc.endTime)})
                                                        </small>
                                                    </>} */}

                                                    {rcc.rnb ? <>
                                                        <small><small class='font-weight-lighter ml-2'>RNB1</small></small>
                                                    </> : <></>}
                                                    {rcc.isDNS || rcc.hasDns ? <>
                                                        <span class='text-danger ml-2'><i class='fa-solid fa-times text-danger' /> DNS</span>
                                                    </> : <></>}
                                                    {rcc.hasWarning ? <>
                                                        <i class='fa-solid fa-cards-blank text-warning ml-2' />
                                                    </> : <></>}
                                                    {rcc.hasTimepenalty ? <>
                                                        <i class='fa-solid fa-timer text-warning ml-2' />
                                                    </> : <></>}
                                                    {rcc.isDisqualified ? <>
                                                        <i class='fa-solid fa-user-xmark text-danger ml-2' />
                                                    </> : <></>}
                                                    {/* lastCheckpoint: {rcc.lastCheckpoint} lastCheckpointLeft: {rcc.lastCheckpointLeft} */}
                                                    {/* rccHasScratched: {rccHasScratched ? 'true' : 'false'}<br /> */}

                                                    {raceClass.doNotCountRestTime ? <></> : <>
                                                        {firstTimeOut ? <>
                                                            {/* isTimeEq: {isTimeEq ? 'true' : 'false'} */}
                                                            <span class='font-weight-lighter ml-2'><i class='fa-duotone fa-clock' /> <Text id='race.firsttimeout'>Time out</Text>:</span> {firstTimeOut}
                                                            {/* <span class='font-weight-lighter ml-2'><Text id='race.firsttimeout'>Time eq:</Text></span> {timeEqualization} */}
                                                        </> : <>
                                                            {hasTakenOptionalRest ? <>
                                                                {/* <i class='fa-duotone fa-circle-dot text-success ml-2' /> */}
                                                            </> : <></>}
                                                        </>}
                                                    </>}
                                                </nobr>
                                                <nobr class='float-right'>
                                                    {raceClass.sortOrderResults && <>
                                                        <span class='text-muted ml-2'>
                                                            {raceClass.doNotCountRestTime ? <>
                                                                <span class='font-weight-lighter'><Text id='race.racetime'>Race time</Text>:</span> {util.secToHms(raceTimeused)}
                                                            </> : <>
                                                                <span class='font-weight-lighter'><Text id='race.racetime'>Race time</Text>:</span> {util.secToHms(timeused)}
                                                            </>}
                                                        </span>
                                                    </>}
                                                </nobr>
                                            </td>
                                            {/* <td class='p-0'>
                                                {rcc.lastCheckpoint} /
                                                {rcc.lastCheckpointLeft}
                                            </td> */}
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>


            {/* <GuiList
                stores={this.props.stores}
                holdingStore={raceStore}
                classNames={classNames}
                view={this.viewRaceClassContestant}
                title={title}
                list={raceClassContestants}
                elTitle={e => `${e.firstname} ${e.lastname}`}
                elInfo1={e => e.birth ? util.age(e.birth) : ''}
                elInfo2={e => e.sportsClub}
                elInfo3={e => ''}
                hideEmpty={hideEmpty}
                editElement={editElement}
                showEmptyImage={false}
                showCevron={showCevron}
                rightBoxField={rightBoxField}
                rightBoxFieldValue={rightBoxFieldValue}
                rightBoxFieldPostfix={rightBoxFieldPostfix}
                imgHeight='60px'
                help={help}
            /> */}
        </>);
    }
}

export default RaceClassContestantList;
