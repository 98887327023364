import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

@observer
class SaleListMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
    }

    onClickViewVisit = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore } = this.props.stores;
        const { drawerHeightMedium } = appState;

        const newSale = await saleStore.load(parseInt(id, 10), false, { skipUpdate: true });
        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }
        saleStore.createNewSale({ skipAnimalJournals: true, skipVisitorSales: true, skipVisitorAnimals: true });
        saleStore.updateKeyValue('newSale', newSale[0]);

        const { visitors } = newSale[0];
        const isProductionAnimals = visitors.some(v => v?.producerNumber);
        saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);

        if (id) {
            await this.loadSalePayments(parseInt(id, 10));
        }
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);

        await animalStore.load({
            query: {
                owner: visitorIds,
            },
        });
    }

    // await this.loadSales(visitorIds, collectiveInvoiceCutoffDate, collectiveInvoiceCutoffDateEnd);

    loadSales = async (visitorIds, cutoffDate, cutoffDateEnd) => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const visitorSales = await saleStore.load({
            query: {
                visitor: visitorIds,
                cutoffDate,
                cutoffDateEnd,
            },
            skipUpdate: 1,
        });
        saleStore.updateKeyValue('visitorSales', visitorSales);

        // this.loadAllVisitorSalePayments();
    }

    onClickCreateCollectiveInvoice = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { visitors = [] } = this.props;
        const visitorIds = visitors.map(v => v.id);
        // console.log('onClickCreateCollectiveInvoice', visitorIds);
        if (!confirm('Are you sure you want to create a collective invoice for these customers?')) {
            return false;
        }

        const { visitorStore, saleStore, animalStore } = this.props.stores;
        const { collectiveInvoiceCutoffDate, collectiveInvoiceCutoffDateEnd } = saleStore;
        // const { currentVisitors } = visitorStore;
        // const finalVisitors = (visitors && visitors.length > 0) ? (currentVisitors || visitors) : [];
        saleStore.createNewSale({ visitors });
// We should load the animals and sales for the visitor here.
        if (visitorIds.length > 0) {
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds, collectiveInvoiceCutoffDate, collectiveInvoiceCutoffDateEnd);
        }
        animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorIds[0]);

        // Add Products from collective invoice sales to this sale.
        const { visitorSales = [] } = saleStore;
        const collectiveInvoiceSales = visitorSales.filter(e => e.status === 1003);
        const collectiveInvoiceProducts = [];
        collectiveInvoiceSales.forEach(sale => {
            sale.products.forEach(product => {
                collectiveInvoiceProducts.push({
                    ...product,
                    saleDate: sale.saleDate,
                    saleId: sale.id,
                    saleNumber: sale.saleNumber,
                    saleUser: sale.user,
                });
            });
        });
        saleStore.updateObjectKeyValue('newSale', 'products', collectiveInvoiceProducts);
        saleStore.updateObjectKeyValue('newSale', 'subInvoices', collectiveInvoiceSales.map(e => e.id));

        await saleStore.saveSale();

        // TODO: Add references to the collective invoice sales.
        // TODO: Mark the collective invoice sales as a subinvoice. New status.
        // TODO: Add the collective invoice sales to the new sale.

    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);
    }

    loadJournals = async (animalIds, sale, journalIds) => {
        const { journalStore, saleStore } = this.props.stores;
        const response = await journalStore.load({
            query: {
                id: journalIds,
                // $or: [
                //     {
                //         animal: animalIds,
                //         sale,
                //     },
                //     {
                //         id: { $in: journalIds },
                //     },
                // ],
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    toggleHeight = e => {
        e.stopPropagation();
        e.preventDefault();
        const { height = 150 } = this.state;
        this.setState({ height: height === 150 ? 500 : 150 });
    }

    changeCutoffDate = async e => {
        const { value: cutoffDate } = e.target;
        const { saleStore } = this.props.stores;
        saleStore.updateKeyValue('collectiveInvoiceCutoffDate', cutoffDate);
    }

    changeCutoffDateEnd = async e => {
        const { value: cutoffDateEnd } = e.target;
        const { saleStore } = this.props.stores;
        saleStore.updateKeyValue('collectiveInvoiceCutoffDateEnd', cutoffDateEnd);
    }

    onClickGoToToday = e => {
        const { saleStore } = this.props.stores;
        saleStore.updateKeyValue('collectiveInvoiceCutoffDate', util.isoDate(new Date(), false, false, true));
    }

    clearCutoffDate = e => {
        const { saleStore } = this.props.stores;
        saleStore.updateKeyValue('collectiveInvoiceCutoffDate', null);
    }

    clearCutoffDateEnd = e => {
        const { saleStore } = this.props.stores;
        saleStore.updateKeyValue('collectiveInvoiceCutoffDateEnd', null);
    }

    toggleShowCollectiveInvoice = e => {
        e.stopPropagation();
        e.preventDefault();
        const { showCollectiveInvoice } = this.state;
        this.setState({
            showCollectiveInvoice: !showCollectiveInvoice,
            height: 500,
        });
    }

    // componentDidMount() {
    //     this.loadAllSalePayments();
    // }

    render() {
        const { height = 150, showCollectiveInvoice } = this.state;
        const {
            visitors = [],
            saleIsLocked = false,
            classNames = '',
        } = this.props;
        const { userStore, saleStore } = this.props.stores;
        const {
            visitorSales = [],
            visitorAllPayments = [],
            newSale = {},
            collectiveInvoiceCutoffDate,
            collectiveInvoiceCutoffDateEnd,
        } = saleStore;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { language = 'no' } = user;
        // console.log('SaleListMini.render', { sales })
        if (!visitorSales || visitorSales.length === 0) {
            return ''
        }

        const allPayableVisitorSales = visitorSales.filter(e => e.status < 1000 && e.status !== 0);
        const totalPurchase = saleStore.sumTotalAll(allPayableVisitorSales, false);

        const salesWithoutPayment = allPayableVisitorSales.filter(s => {
            if (s.status === 1) return false;
            if (s.status === 2) return true;
            const salePayments = visitorAllPayments.filter(p => p.sale === s.id);
            return salePayments && salePayments.length > 0;
        });
    // console.log('salesWithoutPayment', salesWithoutPayment);
    // console.log('totalPurchaseWithPayment', totalPurchaseWithPayment);
        let totalPurchaseWithPayment = 0;
        let missingPayment = 0;
        let missingPaymentAll = 0;
        try {
            totalPurchaseWithPayment = saleStore.sumTotalAll(salesWithoutPayment, false);
        // const paidSales = visitorSales.filter(e => e.status === 2);

            missingPayment = saleStore.sumRestPayments(visitorAllPayments, totalPurchaseWithPayment, false);
    // console.log('missingPayment', missingPayment);
            missingPaymentAll = saleStore.sumRestPayments(visitorAllPayments, totalPurchase, false);
        } catch (e) {
            console.error('SaleListMini.render', e);
        }
        // const missingPayment = saleStore.sumRestPayments(visitorAllPayments, totalPurchase, false);

        // console.log('visitorSales', visitorSales);
        // console.log('newSale?.subInvoices?.length', newSale?.subInvoices?.length);
        const hasSubInvoices = newSale?.subInvoices?.length > 0;
        const hasCollectiveInvoice = !hasSubInvoices && visitorSales.filter(e => e.status === 1003).length > 0;
        const qtyCollectiveInvoice = visitorSales.filter(e => e.status === 1003 && (e.saleDate >= collectiveInvoiceCutoffDate && e.saleDate <= collectiveInvoiceCutoffDateEnd)).length;

        // console.log('totalPurchase', totalPurchase)
        // console.log('totalPurchaseWithPayment', totalPurchaseWithPayment)
        // console.log('missingPayment', missingPayment)
        // console.log('visitorAllPayments', visitorAllPayments.map(e => e.amount))

        return (<>
            <div class={`d-flex flex-column mt-1 p-2 ${classNames}`} style={`max-height: ${height}px;`}>
                {hasCollectiveInvoice && <>
                    <div class='d-flex flex-row justify-content-between align-items-center bg-danger-light rounded-lg py-1 px-2'>
                        <Text id='dyrejournal.visitor-has-collective-invoices'>Customer has sales for collective invoice</Text>
                        <button class='btn btn-sm btn-link' onClick={this.toggleShowCollectiveInvoice}>
                            <i class='fa-duotone fa-file-invoice mr-2' /> {showCollectiveInvoice ?  <Text id='dyrejournal.hide-collective-invoice'>Hide collective invoice</Text>  : <Text id='dyrejournal.show-collective-invoice'>Show collective invoice</Text>}
                        </button>
                    </div>
                    {showCollectiveInvoice && <>
                        <div class='d-flex flex-column justify-content-between align-items-center bg-danger-light rounded-lg py-2 px-2 mt-2'>
                            <div class='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text rounded-pill-left'>
                                        <i class='fa-regular fa-timer mr-2'></i> Fra
                                    </span>
                                </div>
                                <input
                                    class={`form-control rounded-${collectiveInvoiceCutoffDate ? 'none' : 'pill-right'}`}
                                    type='date'
                                    onInput={this.changeCutoffDate}
                                    value={collectiveInvoiceCutoffDate}
                                />
                                {collectiveInvoiceCutoffDate && <div class='input-group-append'>
                                    <button class='btn btn btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>
                            <div class='input-group ml-2'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text rounded-pill-left'>
                                        <i class='fa-regular fa-timer mr-2'></i> Til
                                    </span>
                                </div>
                                <input
                                    class={`form-control rounded-${collectiveInvoiceCutoffDateEnd ? 'none' : 'pill-right'}`}
                                    type='date'
                                    onInput={this.changeCutoffDateEnd}
                                    value={collectiveInvoiceCutoffDateEnd}
                                />
                                {collectiveInvoiceCutoffDateEnd && <div class='input-group-append'>
                                    <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDateEnd}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>

                            <button class='btn btn-sm btn-danger rounded-pill' onClick={this.onClickCreateCollectiveInvoice}>
                                <i class='fa-duotone fa-file-invoice mr-2' />
                                <Text id='dyrejournal.create-collective-invoice'>Create collective invoice</Text> ({qtyCollectiveInvoice})
                            </button>

                        </div>

                    </>}
                </>}

                <div class='d-flex flex-row justify-content-between'>
                    <div class='text-right text-danger font-weight-bold'>
                        {missingPayment > 0 && <>
                            <Text id='input.restPaymentAmount'>Utestående</Text>: {util.format(missingPayment, 2, ',', ' ')}
                        </>}
                    </div>
                    {/* {missingPaymentAll > 0 && <div class='text-right text-danger font-weight-bold'>
                        <Text id='input.restPaymentAmount'>Utestående </Text>: {util.format(missingPaymentAll, 2, ',', ' ')}
                    </div>} */}
                    {totalPurchase > 0 && <div class='text-right text-success'>
                        <Text id='dyrejournal.total-purchases'>Totalomsetning</Text>: {util.format(totalPurchase, 2, ',', ' ')}
                    </div>}
                </div>

                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mt-1`}>
                    <small>
                        <table class='table table-striped table-sm mb-0'>
                            <thead>
                                <tr>
                                    {/* <th class='d-none d-sm-table-cell'><Text id='dyrejournal.id'>Id</Text></th> */}
                                    <th class='text-center p-0'><Text id='dyrejournal.createdDate'>CreatedDate</Text></th>
                                    <th class='text-center p-0'><Text id='dyrejournal.saleDate'>SaleDate</Text></th>
                            {/* <th><Text id='dyrejournal.title'>Title</Text></th> */}
                                    {/* <th class='text-center'><Text id='dyrejournal.animals'>Animals</Text></th>
                                    <th class='text-center'><Text id='dyrejournal.journals'>Journals</Text></th> */}
                                    <th class='text-center p-0'><Text id='dyrejournal.status'>Status</Text></th>
                                    {/* <th class='text-center'><Text id='dyrejournal.payment-method'>Payment method</Text></th> */}
                                    <th class='text-center p-0'><Text id='dyrejournal.total'>Total</Text></th>
                                    <th class='text-center p-0'><Text id='dyrejournal.rest'>Rest</Text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {(visitorSales && visitorSales.length > 0) && visitorSales.map(e => {
                                    const saleStatus = saleStore.getSaleStatus(e.paymentMethod);
                                    const saleStatusText = saleStore.getSaleStatusById(e.status);
                                    const saleStatusIcon = saleStore.getSaleStatusIcon(saleStatusText);
                                    const saleStatusClass = saleStore.getSaleStatusClass(saleStatusText);
                                    const salePayments = visitorAllPayments.filter(p => p.sale === e.id);
                                    const hasPayments = salePayments && salePayments.length > 0;
                                    const isMarkedForCollectiveInvoice = e.status === 1003 && e.saleDate >= collectiveInvoiceCutoffDate && e.saleDate <= collectiveInvoiceCutoffDateEnd;

                                    let saleIsDone = (!!e.paidDate && !!e.paymentMethod)
                                        || e.paymentMethod === 'creditNote'
                                        || e.paymentMethod === 'invoiceCredited'
                                        || e.paymentMethod === 'saleCredited';
                                    let paymentRestAmount = 0;
                                    if (hasPayments) {
                                        paymentRestAmount = saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), false, false);
                                    } else if (e.status !== 0 && e.status < 6 && !saleIsDone) {
                                        paymentRestAmount = saleStore.sumTotal(e.products, false);
                                    }
                                    let saleIsPaid = saleIsDone
                                        || (hasPayments && paymentRestAmount === 0);

                                    return (<>
                                        <tr class={`${newSale.id === e.id ? 'font-weight-bold' : ''}`} onClick={this.onClickViewVisit} data-id={e.id}>
                                            {/* <td class='d-none d-sm-table-cell'>{e.id}</td> */}
                                            <td class='p-0'>
                                                <nobr>
                                                    {newSale.id === e.id && <i class='fa-duotone fa-arrow-right mr-2' />}
                                                    {util.formatDate(e.createdDate || e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                                                </nobr>
                                            </td>
                                            <td>
                                                <nobr class='text-muted'>{util.formatDate(e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>
                                            </td>
                                            {/* <td>{e.title || 'no title'}</td> */}
                                            {/* <td class='text-center'>{e.animals?.length > 0 ? e.animals.length : ''}</td>
                                            <td class='text-center'>{e.journals?.length > 0 ? e.journals.length : ''}</td> */}
                                            <td class='text-center p-0'>
                                                <nobr>
                                                    {/* <span class={`text-${saleStatusClass}`}><i class={saleStatusIcon} /> {saleStatusText}</span> */}
                                                    {saleIsPaid ? <>
                                                        {e.status === 8 ? <>
                                                            <span class={`text-success`}><i class={saleStore.getSaleStatusIcon(e.paymentMethod)} /> {saleStore.getSaleStatusById(saleStore.getSaleStatus(e.paymentMethod), language)}</span>
                                                        </> : <>
                                                            <span class={`text-${saleStore.getSaleStatusClass('paid')}`}><i class={saleStore.getSaleStatusIcon('paid')} /> {saleStore.getSaleStatusById(1, language)}</span>
                                                        </>}
                                                        {/* paymentRestAmount: {paymentRestAmount}:: */}
                                                    </> : <>
                                                        <span class={`text-${saleStatusClass}`}><i class={saleStatusIcon} /> {saleStore.getSaleStatusById(e.status, language)}</span>
                                                    </>}
                                                    {isMarkedForCollectiveInvoice && <i class='fa-duotone fa-check ml-2' />}

                                                    {/* {e.parentSaleNumber && <>
                                                        {e.parentSaleNumber} <i class='fas fa-link' />
                                                    </>} */}
                                                </nobr>
                                            </td>
                                            {/* <td class='text-center'><i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {e.paymentMethod}</td> */}
                                            <td class='text-right p-0'>
                                                <nobr>{saleStore.sumTotal(e.products)}</nobr>
                                            </td>
                                            <td class='text-right p-0'>
                                                {/* {salePayments && salePayments.length > 0 && <>
                                                    <nobr>{saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), true, false)}</nobr>
                                                </>} */}
                                                <nobr>{util.format(paymentRestAmount, 2, ',', ' ')}</nobr>
                                                {/* Rest: {paymentRestAmount} */}
                                                {/* status: {e.status} */}
                                                {/* saleStatus: {saleStatus} */}
                                                {/* saleStatusText: {saleStatusText} */}
                                            </td>
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </small>
                </div>

                <button class='btn btn-sm btn-link rounded-pill mt-1' onClick={this.toggleHeight}>
                    {height === 150 ? <>
                        <i class='fa-duotone fa-chevron-down mr-2' />
                        <Text id='input.show-more'>Show more</Text>
                    </> : <>
                        <i class='fa-duotone fa-chevron-up mr-2' />
                        <Text id='input.show-less'>Show less</Text>
                    </>}
                </button>
            </div>


        </>);
    }
}

export default SaleListMini;





