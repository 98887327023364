import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import CalendarHourGrid from './hourGrid';
import CalendarHourGridMonth from './hourGridMonth';

import localUtil from '../../lib/util';

function formatDate(date) {
    return new Intl.DateTimeFormat('nb-NO', { year: 'numeric', month: 'long' }).format(date);
}

const dayNames = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
];
@observer
class CalendarInline extends Component {
  	constructor(props) {
        super(props);
        this.state = {};
        this.scrollAmount = 300;
        this.scrollContainer = null;
    }

    loadAll = async (props = this.props, skipDateReset) => {
        const { calendar = 'booking', startDate = new Date(), endDate } = props;
        const { userStore, calendarStore, calendarEventStore } = this.props.stores;
        const { calendarDate, viewName } = calendarStore;
        const { customer = {} } = userStore;

        if (!skipDateReset) {
            calendarStore.calendarSetDate(startDate);
        }
        // calendarStore.calendarSetDate(startDate);
        if (customer.calendarStartHour) {
            calendarStore.setStartHour(customer.calendarStartHour);
        }
        if (customer.calendarEndHour) {
            calendarStore.setEndHour(customer.calendarEndHour);
        }

        await calendarStore.load({
            query: {
                type: calendar,
            },
        });

        // const calendarId = calendarStore.calendars.filter(calendarStore.filterCalendars).map(c => c.calendarId);
        const calendarId = calendarStore.calendars.map(c => c.calendarId);
        await calendarEventStore.load({
            query: {
                calendarId,
                calendarDate: util.isoDate(calendarDate, false, false, true),
                viewName,
            },
            // addData: ['recurringList', 'currentTime', 'currentTimeOffset', 'creators', 'animals', 'visitors'],
            addData: ['recurringList', 'currentTime', 'currentTimeOffset', 'creators', 'animals', 'visitors'],
        });
        calendarEventStore.isOverlapping();
        this.loadCalendarAvailability(props);
    }

    loadCalendarEvents = async (props = this.props) => {
        const { calendarStore, calendarEventStore } = this.props.stores;
        const { calendarDate, viewName } = calendarStore;
        const calendarId = calendarStore.calendars.map(c => c.calendarId);
        await calendarEventStore.load({
            query: {
                calendarId,
                calendarDate: util.isoDate(calendarDate, false, false, true),
                viewName,
            },
            addData: ['recurringList', 'currentTime', 'currentTimeOffset', 'creators', 'animals', 'visitors'],
        });
        console.log('util.isoDate(calendarDate)', util.isoDate(calendarDate, false, false, true));
        calendarEventStore.isOverlapping();
        this.loadCalendarAvailability(props);
    }

    // loadCalendarAvailability = async (props = this.props) => {
    //     const { calendarAvailabilityStore } = this.props.stores;
    //     await calendarAvailabilityStore.load({ query: {}});
    // }
    loadCalendarAvailability = async (props = this.props) => {
        const { calendarStore, calendarAvailabilityStore } = this.props.stores;
        const daysInMonth = calendarStore.daysInMonth();
        // console.log({ daysInMonth })
        await calendarAvailabilityStore.load({
            query: {
                start: util.isoDate(daysInMonth[0].date, false, false, true),
                end: util.isoDate(daysInMonth[daysInMonth.length - 1].date, false, false, true),
            },
        });
    }

    addEventCallback = async (event, calendarHtmlId) => {
        this.loadAll(this.props, true);
    }

    clickHeader = e => {
        e.stopPropagation();
        e.preventDefault();
        const { calendarStore, appState, saleStore } = this.props.stores;
        const { calendarSelectDateTime, calendarId } = this.props;

        const { drawerLevel = 1, callback = () => {}, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        appState.openDrawer('newCalendarAvailability', {
			height: drawerHeightLarge,
            callback: async () => {
                this.loadAll();
            },
		}, drawerLevel + 1);
    }

    handleScroll = e => {
        let isSticky = false;
        const div = e.target;
        if (div.scrollTop > 100) { // Adjust the threshold as needed
            isSticky = true;
        }
        this.setState({ isSticky });
    }

    onClickEditCalendar = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('div').dataset;
        const { drawerLevel = 1 } = this.props;
		const { appState, customerStore } = this.props.stores;
        customerStore.load(id);
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editCalendar', {
			height: drawerHeightMedium,
            id,
            callback: () => {
                this.loadAll();
            },
		}, drawerLevel + 1);
    }



    updateScrollButtons = () => {
        const container = this.scrollContainer;
        if (container) {
            const { scrollLeft, scrollWidth, clientWidth } = container;
            this.setState({
                canScrollLeft: scrollLeft > 0,
                canScrollRight: scrollLeft + clientWidth < scrollWidth,
            });
        }
    }

    scrollLeft = (e) => {
        e.stopPropagation();
        const container = this.scrollContainer;
        if (container) {
            container.scrollBy({ left: -this.scrollAmount, behavior: 'smooth' });
        }
    }

    scrollRight = (e) => {
        e.stopPropagation();
        const container = this.scrollContainer;
        if (container) {
            container.scrollBy({ left: this.scrollAmount, behavior: 'smooth' });
        }
    }

    handleCalendarDisplayChange = e => {
        const { calendarStore } = this.props.stores;
        const options = e.target.options;
        const selectedIds = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedIds.push(parseInt(options[i].value, 10));
            }
        }
        // Update the observable array with selected IDs
        calendarStore.toggleCalendarDisplay(null, selectedIds);
    }

    toggleCalendar = id => {
        const { calendarStore } = this.props.stores;
        calendarStore.toggleCalendarDisplay(parseInt(id, 10));
    }

    showAllCalendars = () => {
        const { calendarStore } = this.props.stores;
        calendarStore.showAllCalendars();
    }

    componentDidMount() {
        this.loadAll();

        const container = this.scrollContainer;
        if (container) {
            container.addEventListener('scroll', this.updateScrollButtons);
            window.addEventListener('resize', this.updateScrollButtons);
            this.updateScrollButtons();
        }
    }

    componentWillUnmount() {
        const container = this.scrollContainer;
        if (container) {
            container.removeEventListener('scroll', this.updateScrollButtons);
            window.removeEventListener('resize', this.updateScrollButtons);
        }
    }

    render() {
        const { isSticky, canScrollLeft = true, canScrollRight = true } = this.state;
        const { calendarSelectDateTime, skipAnimal, inputKey, showPullDownHandle, pulldownHandleId, showViewEvent, enableSelection } = this.props;
        const { appState, userStore, calendarStore, calendarEventStore, calendarAvailabilityStore } = this.props.stores;
        const {
            colors,
            viewName = 'day',
            showMonth,
            calendarDate,
            calendarDateStart,
            calendarDateEnd,
            isToday,
            isTodayWeek,
            allDaysInWeek,
            allDaysInMonth,
            allWeeksInMonth,
        } = calendarStore;
        const { currentTime, currentTimeOffset } = calendarEventStore;
        const { calendars, displayCalendarIds } = calendarStore;
        const { user = {}, customer, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const localTime = util.isoDate(new Date());
        const localTimeOffset = new Date().getTimezoneOffset();
        const timeIsDifferent = localTime !== currentTime;

        const calendarYYYY = calendarDate ? calendarDate.getFullYear() : '';
        const calendarMM = calendarDate ? calendarDate.getMonth() + 1 : '';
        const calendarDD = calendarDate ? calendarDate.getDate() : '';
        const calendarWeek = calendarDate ? util.getWeek(calendarDate) : '';

        return (<>
            <div class='w-100 h-100 d-flex justify-content-center overflow-auto' onScroll={this.handleScroll}>
                <div class='w-100 h-100'>
                    <div class='d-flex flex-row w-100 justify-content-between mt-2 px-3'>
                        <div class='d-flex flex-column align-items-start'>
                            <div class='d-flex flex-nowrap'>
                                <button class={`btn btn-sm btn-outline-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrev(this.loadCalendarEvents)}>
                                    <i class='fa-duotone fa-caret-left' /> <Text id='calendar.prev'>Prev</Text>
                                </button>
                                <button class={`btn btn-sm btn-${isToday ? 'primary' : 'outline-primary'} rounded-none`} onClick={() => calendarStore.calendarToday(this.loadCalendarEvents)}><Text id='calendar.today'>Today</Text></button>
                                <button class={`btn btn-sm btn-outline-primary rounded-pill-right`} onClick={() => calendarStore.calendarNext(this.loadCalendarEvents)}>
                                    <Text id='calendar.next'>Next</Text> <i class='fa-duotone fa-caret-right' />
                                </button>
                            </div>
                        </div>

                        <div class='d-flex flex-column align-items-center justify-content-end'>
                            <div class='d-none d-md-block'>
                                <div class='d-flex flex-column align-items-end font-weight-lighter' style='line-height: 0.9em;'>
                                    <div><small class={`${timeIsDifferent ? 'text-danger' : 'text-muted'}`}>PC: {localTime} ({localTimeOffset})</small></div>
                                    <div><small class='text-muted'>Server: {currentTime} ({currentTimeOffset})</small></div>
                                </div>
                            </div>
                        </div>

                        <div class='d-flex flex-column align-items-end'>
                            <div class='d-flex'>
                                <button
                                    type='button'
                                    class={`btn btn-sm btn-${showMonth ? 'primary' : 'outline-primary'} rounded-pill-both`}
                                    onClick={() => calendarStore.toggleShowMonth()}>
                                        {showMonth ? <>
                                            <Text id='calendar.hideMonth'>Hide month</Text>
                                        </> : <>
                                            <Text id='calendar.showMonth'>Show month</Text>
                                        </>}
                                </button>
                                <button
                                    type='button'
                                    class={'btn btn-lg btn-link py-0'}
                                    onClick={this.clickHeader}
                                >
                                    <i class='fa-regular fa-gear' />
                                </button>
                            </div>
                        </div>
                    </div>

                    {showMonth ? <>
                        <div class='d-flex flex-row w-100 justify-content-center'>
                        <div class='d-flex flex-column w-100 mt-3 px-3' style='max-width: 500px;'>

                            <div class='d-flex flex-row flex-nowrap'>
                                <button class={`btn btn-sm btn-primary rounded-pill-left`} onClick={() => calendarStore.calendarPrevMonth(this.loadCalendarEvents)}>
                                    <i class='fa-duotone fa-caret-left' />
                                </button>
                                <button class={`flex-fill btn btn-sm btn-outline-primary rounded-none`} disabled>
                                    {formatDate(calendarDate)}
                                </button>
                                <button class={`btn btn-sm btn-primary rounded-pill-right`} onClick={() => calendarStore.calendarNextMonth(this.loadCalendarEvents)}>
                                    <i class='fa-duotone fa-caret-right' />
                                </button>
                            </div>

                            <div class='table-responsive mt-1'>
                                <table class='table table-sm table-bordered table-hover'>
                                    <thead>
                                        <tr>
                                            <th class='text-center p-0'><Text id='calendar.week'>Week</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.monday-short'>M</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.tuesday-short'>T</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.wednesday-short'>W</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.thursday-short'>T</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.friday-short'>F</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.saturday-short'>S</Text></th>
                                            <th class='text-center p-0'><Text id='calendar.sunday-short'>S</Text></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {calendarStore.weeksInMonth().map((obj, idx) => {
                                            if (!obj.week) {
                                                return '';
                                            }
                                            return (<>
                                                <tr>
                                                    <td class='text-center font-weight-lighter p-0'>
                                                        {obj.week?.week}
                                                    </td>
                                                    {obj.week?.daysInWeek.map((day, idx) => {
                                                        const dayOfWeek = day.getDay();
                                                        const isToday = day.toDateString() === new Date().toDateString();
                                                        const currentIsToday = calendarDate.toDateString() === new Date().toDateString();
                                                        const isCurrentDay = day.toDateString() === calendarDate.toDateString() && !currentIsToday;
                                                        return (<>
                                                            <td
                                                                class={`text-center p-0
                                                                    ${(dayOfWeek === 6 || dayOfWeek === 0) ? 'text-danger' : ''}
                                                                    ${isToday ? 'font-weight-bolder' : ''}
                                                                    ${isCurrentDay ? 'bg-primary text-white' : ''}
                                                                `}
                                                                style={`
                                                                    ${isToday ? 'font-weight: 1000 !important;' : ''}
                                                                `}
                                                                onClick={() => calendarStore.calendarSetDate(day, this.loadCalendarEvents)}
                                                            >
                                                                {day.getDate()}
                                                            </td>
                                                        </>);
                                                    })}
                                                </tr>
                                            </>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </> : <></>}

                    <div class={`d-flex flex-row w-100 justify-content-start mt-2 px-2`}>
                        {calendarDate && <>
                            <div class='d-flex flex-row w-100 align-items-center justify-content-between'>
                                <div class='box-header font-weight-lighter text-muted' style='font-size: 1.7em;'>
                                    {calendarDate && <>
                                        {viewName === 'month' && <>
                                            <span class='mr-2'>{calendarYYYY}-{calendarMM}</span>
                                        </>}
                                        {viewName === 'week' && <>
                                            <span class='mr-2'>{calendarYYYY} w{calendarWeek}</span>
                                        </>}
                                        {viewName === 'day' && <>
                                            <div class=''>
                                                {util.formatDate(calendarDate, {
                                                    weekday: 'short',
                                                    year: 'numeric',
                                                    month: 'short',
                                                    day: 'numeric',
                                                    locale: 'nb-NO',
                                                    // hour: '2-digit',
                                                    // minute: '2-digit',
                                                    // hour12: false,
                                                })},
                                                <span class='ml-2'>w{util.getWeek(calendarDate)}</span>
                                            </div>
                                        </>}

                                    </>}
                                </div>

                                <div class='d-none d-lg-block'>
                                    <div class='d-flex flex-row w-100 align-items-center justify-content-center mt-0'>
                                        <div class='font-weight-normal'>
                                            <details>
                                                <summary>
                                                    <span class='btn btn-sm btn-outline-info rounded-pill py-1'>
                                                    <Text id='calendar.select-calendars-display'>Select Calendars to Display</Text> {displayCalendarIds.length > 0 && <>
                                                        <span class='alert alert-danger rounded-pill py-1 px-2'>({displayCalendarIds.length} / {calendars.length})</span>
                                                    </>}
                                                    </span>
                                                </summary>
                                                {/* <select
                                                    multiple
                                                    value={displayCalendarIds}
                                                    onChange={this.handleCalendarDisplayChange}
                                                    size={calendars.length}
                                                >
                                                    {calendars.map(calendar => (
                                                        <option key={calendar.id} value={calendar.id}>
                                                            {calendar.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <hr /> */}
                                                {calendars.map(calendar => (
                                                    <div key={calendar.id}>
                                                        <label>
                                                            <input
                                                                class='mr-2'
                                                                type='checkbox'
                                                                checked={displayCalendarIds.includes(calendar.id)}
                                                                onChange={() => this.toggleCalendar(calendar.id)}
                                                            />
                                                            {calendar.name}
                                                        </label>
                                                    </div>
                                                ))}

                                                {displayCalendarIds.length > 0 && <>
                                                    <button class='btn btn-sm btn-primary rounded-pill mt-2' onClick={this.showAllCalendars}>
                                                        <Text id='calendar.show-all-calendars'>Show all calendars</Text>
                                                    </button>
                                                </>}
                                            </details>

                                        </div>
                                    </div>
                                </div>

                                <div class='d-flex flex-nowrap'>
                                    <button type='button' class={`btn btn-sm btn-${viewName === 'month' ? 'primary' : 'outline-primary'} rounded-pill-left`} onClick={() => { calendarStore.calendarChangeView('month'); this.loadCalendarEvents(); }}><Text id='calendar.month'>month</Text></button>
                                    <button type='button' class={`btn btn-sm btn-${viewName === 'week' ? 'primary' : 'outline-primary'} rounded-none`} onClick={() => { calendarStore.calendarChangeView('week'); this.loadCalendarEvents(); }}><Text id='calendar.week'>week</Text></button>
                                    <button type='button' class={`btn btn-sm btn-${viewName === 'day' ? 'primary' : 'outline-primary'} rounded-pill-right`} onClick={() => { calendarStore.calendarChangeView('day'); this.loadCalendarEvents(); }}><Text id='calendar.day'>day</Text></button>
                                </div>

                            </div>
                        </>}
                    </div>

                    <div class='d-none d-sm-block d-md-block d-lg-none'>
                        <div class='d-flex flex-row w-100 align-items-center justify-content-center mt-2'>
                            <div class='font-weight-normal'>
                                <details>
                                    <summary>
                                        <span class='btn btn-sm btn-outline-info rounded-pill py-1'>
                                        <Text id='calendar.select-calendars-display'>Select Calendars to Display</Text> {displayCalendarIds.length > 0 && <>
                                            <span class='alert alert-danger rounded-pill py-1 px-2'>({displayCalendarIds.length} / {calendars.length})</span>
                                        </>}
                                        </span>
                                    </summary>
                                    {/* <select
                                        multiple
                                        value={displayCalendarIds}
                                        onChange={this.handleCalendarDisplayChange}
                                        size={calendars.length}
                                    >
                                        {calendars.map(calendar => (
                                            <option key={calendar.id} value={calendar.id}>
                                                {calendar.name}
                                            </option>
                                        ))}
                                    </select>
                                    <hr /> */}
                                    {calendars.map(calendar => (
                                        <div key={calendar.id}>
                                            <label>
                                                <input
                                                    class='mr-2'
                                                    type='checkbox'
                                                    checked={displayCalendarIds.includes(calendar.id)}
                                                    onChange={() => this.toggleCalendar(calendar.id)}
                                                />
                                                {calendar.name}
                                            </label>
                                        </div>
                                    ))}

                                    {displayCalendarIds.length > 0 && <>
                                        <button class='btn btn-sm btn-primary rounded-pill mt-2' onClick={this.showAllCalendars}>
                                            <Text id='calendar.show-all-calendars'>Show all calendars</Text>
                                        </button>
                                    </>}
                                </details>

                            </div>
                        </div>
                    </div>

                    {viewName === 'day' && <>
                        <div
                            class='d-flex flex-row w-100 justify-content-between mt-0 mb-5 overflow-auto'
                            onTouchmove={localUtil.captureEvents}
                        >
                            {calendars.filter(calendarStore.filterCalendars).map((c, idx) => {
                                const color = c.color ? c.color : colors[idx].color;
                                const backgroundColor = c.backgroundColor ? c.backgroundColor : colors[idx].backgroundColor;
                                const { startHour, startMinute, endHour, endMinute } = calendarAvailabilityStore.getAvailableHours(calendarDate, c.id);

                                return (<>
                                    <div class='d-flex flex-column w-100 h-100 mt-2 mx-1 px-1'>
                                        <div
                                            class='rounded-pill my-1 px-2 py-1 flex-fill text-center calendar-header'
                                            style={`
                                                background-color: ${backgroundColor};
                                                color: ${color};
                                            `}
                                            data-id={c.id}
                                        >
                                            <nobr>
                                                <small>
                                                    {c.name}
                                                    {startHour && endHour && <>
                                                        <span class='ml-2 font-weight-lighter'>({util.padDate(startHour)}:{util.padDate(startMinute)}-{util.padDate(endHour)}:{util.padDate(endMinute)})</span>
                                                    </>}
                                                    <i class='fa-duotone fa-edit ml-2' onClick={this.onClickEditCalendar} />
                                                </small>
                                            </nobr>
                                        </div>
                                        <div class={`w-100 px-2 py-1 mt-2  ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                            <CalendarHourGrid
                                                key={`calendar-${c.id}-${inputKey}`}
                                                stores={this.props.stores}
                                                idx={idx}
                                                calendarDate={calendarDate}
                                                calendarId={c.calendarId}
                                                calendarSelectDateTime={calendarSelectDateTime}
                                                callback={this.addEventCallback}
                                                skipAnimal={skipAnimal}
                                                displayCustomerInfo={c.displayCustomerInfo}

                                                useCalendarAvailability={c.useCalendarAvailability}
                                                availabilityHoursStart={startHour}
                                                availabilityMinutesStart={startMinute}
                                                availabilityHoursEnd={endHour}
                                                availabilityMinutesEnd={endMinute}
                                                showPullDownHandle={showPullDownHandle}
                                                pulldownHandleId={pulldownHandleId}
                                                showViewEvent={showViewEvent}
                                                enableSelection={enableSelection}
                                                color={color}
                                                backgroundColor={backgroundColor}
                                            />
                                        </div>
                                    </div>
                                </>);
                            })}
                        </div>
                    </>}

                    {viewName === 'week' && <>
                        <div
                            class='scroll-wrapper'
                            onTouchmove={localUtil.captureEvents}
                        >
                            {canScrollLeft && (
                                <button
                                    className="scroll-button left rounded-pill"
                                    onClick={this.scrollLeft}
                                    aria-label="Scroll Left"
                                >
                                    ◀
                                </button>
                            )}
                            {canScrollRight && (
                                <button
                                    className="scroll-button right rounded-pill"
                                    onClick={this.scrollRight}
                                    aria-label="Scroll Right"
                                >
                                    ▶
                                </button>
                            )}
                            <div
                                class='d-flex flex-row w-100 justify-content-between mt-0 mb-5 overflow-auto'
                                // class='d-flex mt-0 mb-5 overflow-auto w-100'
                                ref={c => this.scrollContainer = c}
                            >

                                {allDaysInWeek.map((day, idx) => {
                                    const dayOfWeek = day.getUTCDay();
                                    const dayName = dayNames[dayOfWeek];
                                    if (customer.calendarDays) {
                                        if (!customer.calendarDays[dayName]) {
                                            return '';
                                        }
                                    }
                                    return (<>
                                        <div class={`d-flex flex-column w-100 h-100 mt-2 mx-1 px-0 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                            <div class={`d-flex flex-row w-100 justify-content-center ${dayOfWeek === 6 || dayOfWeek === 0 ? 'bg-danger' : 'bg-secondary'} text-white rounded-top-lg py-1`} style={``}>
                                                {util.formatDate(day, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric', locale: 'nb-NO' })}
                                            </div>
                                            {/* dayOfWeek: {dayOfWeek} */}
                                            {/* <xmp>day: {JSON.stringify(day, null, 2)}</xmp> */}
                                            <div class='d-flex flex-row w-100 justify-content-between'>

                                                {calendars.filter(calendarStore.filterCalendars).map((c, idx) => {
                                                    const color = c.color ? c.color : colors[idx].color;
                                                    const backgroundColor = c.backgroundColor ? c.backgroundColor : colors[idx].backgroundColor;
                                                    const { startHour, startMinute, endHour, endMinute } = calendarAvailabilityStore.getAvailableHours(day, c.id);

                                                    return (<>
                                                        <div class='d-flex flex-column w-100 h-100 mt-2 mx-1 px-1'>
                                                            <div
                                                                class='rounded-pill my-1 px-2 py-1 flex-fill text-center calendar-header'
                                                                style={`
                                                                    background-color: ${backgroundColor};
                                                                    color: ${color};
                                                                `}
                                                                data-id={c.id}
                                                            >
                                                                <nobr>
                                                                    <small>
                                                                        {c.name}
                                                                        {/* {startHour && endHour && <>
                                                                            <span class='ml-2 font-weight-lighter'>({util.padDate(startHour)}:{util.padDate(startMinute)}-{util.padDate(endHour)}:{util.padDate(endMinute)})</span>
                                                                        </>} */}
                                                                        <i class='fa-duotone fa-edit ml-2' onClick={this.onClickEditCalendar} />
                                                                    </small>
                                                                </nobr>
                                                            </div>
                                                            <div class={`w-100 px-2 py-1 mt-2  ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`}>
                                                                <CalendarHourGrid
                                                                    key={`calendar-${c.id}-${inputKey}`}
                                                                    stores={this.props.stores}
                                                                    idx={idx}
                                                                    calendarDate={day}
                                                                    calendarId={c.calendarId}
                                                                    calendarSelectDateTime={calendarSelectDateTime}
                                                                    callback={this.addEventCallback}
                                                                    skipAnimal={skipAnimal}
                                                                    displayCustomerInfo={c.displayCustomerInfo}

                                                                    useCalendarAvailability={c.useCalendarAvailability}
                                                                    availabilityHoursStart={startHour}
                                                                    availabilityMinutesStart={startMinute}
                                                                    availabilityHoursEnd={endHour}
                                                                    availabilityMinutesEnd={endMinute}
                                                                    showPullDownHandle={showPullDownHandle}
                                                                    pulldownHandleId={pulldownHandleId}
                                                                    showViewEvent={showViewEvent}
                                                                    enableSelection={enableSelection}
                                                                    color={color}
                                                                    backgroundColor={backgroundColor}
                                                                    // minWidth={220}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>);
                                                })}
                                            </div>
                                        </div>
                                    </>)
                                })}
                            </div>
                        </div>
                    </>}

                    {viewName === 'month' && <>
                        <div
                            class='d-flex flex-column w-100 justify-content-between mt-0 mb-5 overflow-auto'
                            onTouchmove={localUtil.captureEvents}
                        >
                            <div class={`d-flex flex-row justify-content-between mt-1 px-0 rounded-lg`}>
                                {[1,2,3,4,5,6,0].map((idx) => {
                                    return (<>
                                        <div class={`d-flex flex-column align-items-end px-2 py-1 mx-1 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`} style='min-width: 13.9%;'>
                                            <span class='text-muted'>{calendarStore.getDayNameNo(idx)}</span>
                                        </div>
                                    </>);
                                })}
                            </div>
                            {allWeeksInMonth.map((obj, idx) => {
                                const { week = {} } = obj;
                                const { daysInWeek = [] } = week;
                                return (<>
                                    <div class={`d-flex flex-row justify-content-between mt-1 px-0 rounded-lg`}>
                                        {/* <xmp>week: {JSON.stringify(week, null, 2)}</xmp> */}

                                        {daysInWeek && daysInWeek.map((day, idx) => {
                                            const date = day.getDate();
                                            const dow = day.getDay();
                                            const month = day.getMonth() + 1;
                                            if (month !== calendarMM) {
                                                return (<>
                                                    <div class={`d-flex flex-column px-1 mx-1`} style='min-width: 13.9%; min-height: 12vh;'>
                                                        {/* month: {month}<br />
                                                        calendarMM: {calendarMM}<br /> */}
                                                    </div>
                                                </>);
                                            }
                                            return (<>
                                                <div class={`d-flex flex-column px-2 py-1 mx-1 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} rounded-lg`} style='min-width: 13.9%; min-height: 12vh;'>
                                                    <div class='d-flex flex-row justify-content-between'>
                                                        <div class='text-muted font-weight-lighter' style='font-size: 1.2em;'>{dow === 1 ? week.week : ''}</div>
                                                        <div class='text-muted font-weight-lighter' style='font-size: 1.2em;'>{date}</div>
                                                    </div>
                                                    <div class='d-flex flex-column rounded'>
                                                        <CalendarHourGridMonth
                                                            key={`calendar-${inputKey}`}
                                                            stores={this.props.stores}
                                                            idx={1}
                                                            calendarDate={day}
                                                            calendarId={calendars.filter(calendarStore.filterCalendars).map(c => c.calendarId)}
                                                            calendarSelectDateTime={calendarSelectDateTime}
                                                            callback={this.addEventCallback}
                                                            skipAnimal={skipAnimal}

                                                            // useCalendarAvailability={c.useCalendarAvailability}
                                                            // availabilityHoursStart={startHour}
                                                            // availabilityMinutesStart={startMinute}
                                                            // availabilityHoursEnd={endHour}
                                                            // availabilityMinutesEnd={endMinute}
                                                            showPullDownHandle={showPullDownHandle}
                                                            pulldownHandleId={pulldownHandleId}
                                                            showViewEvent={showViewEvent}
                                                            enableSelection={enableSelection}
                                                            // color={color}
                                                            // backgroundColor={backgroundColor}
                                                        />
                                                    </div>
                                                </div>
                                            </>);
                                        })}
                                    </div>
                                </>);
                            })}
                        </div>



                    </>}

                </div>
            </div>
        </>);
    }
}

export default CalendarInline;
