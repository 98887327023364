import { observable, configure, action, computed, autorun, toJS } from 'mobx';
import util from 'preact-util';
import { route } from 'preact-router';

configure({ enforceActions: 'always' });

function merge(a, b, prop){
    const reduced =  a.filter(aitem => !b.find(bitem => aitem[prop] === bitem[prop]));
    return reduced.concat(b);
}

function scrollTo(element, top = 0, left = 0) {
    // element.scrollTop = to;
    element.scrollTo({
        top,
        left,
        behavior: 'smooth'
    });
}

function addMetaTag(tag = 'og:title', content = 'title') {
    var newTag = document.createElement('meta');
    newTag.setAttribute('property', tag);
    newTag.content = content
    document.getElementsByTagName('head')[0].appendChild(newTag);
}

function updateOrCreateMetaTag(propertyName, content, propertyType = 'name') {
    let selector = `${propertyType}="${propertyName}"`;
    let metaTag = document.querySelector(`meta[${selector}]`);

    if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.setAttribute(propertyType, propertyName);
        document.getElementsByTagName('head')[0].appendChild(metaTag);
    }

    metaTag.setAttribute('content', content);
}

function updateMetaTags(article) {
    document.title = article.title; // Update the document title

    // Update or create each meta tag
    updateOrCreateMetaTag('description', article.description || article.ingress || '');
    updateOrCreateMetaTag('keywords', article.tags ? article.tags.map(tag => tag.name).join(', ') : '');

    updateOrCreateMetaTag('og:url', article.uuidv4 ? `/article/${encodeURIComponent(article.title).toLocaleLowerCase()}/${article.uuidv4}` : '', 'property');
    updateOrCreateMetaTag('og:type', article.type || 'article', 'property');
    updateOrCreateMetaTag('og:locale', 'no_NO', 'property');
    updateOrCreateMetaTag('og:title', article.title || '', 'property');
    updateOrCreateMetaTag('og:description', article.ingress || '', 'property');
    if (article.images && article.images[0]) {
        updateOrCreateMetaTag('thumbnail', article.images[0].s3LargeLink);
        updateOrCreateMetaTag('og:image', article.images[0].s3LargeLink, 'property');
        updateOrCreateMetaTag('og:image:width', '800', 'property');
        updateOrCreateMetaTag('og:image:type', 'image/jpeg', 'property');
    }
}

function getBrowserInfo() {
    const userAgent = navigator.userAgent;
    const language = navigator.language;

    // Basic parsing of userAgent to identify browser and OS - this can be expanded
    let browserName = userAgent.match(/firefox|fxios/i) ? 'Firefox' :
                      userAgent.match(/chrome|chromium|crios/i) ? 'Chrome' :
                      userAgent.match(/safari/i) ? 'Safari' :
                      userAgent.match(/opr\//i) ? 'Opera' :
                      userAgent.match(/edg/i) ? 'Edge' :
                      'Other';

    let osName = userAgent.match(/android/i) ? 'Android' :
                 userAgent.match(/iphone|ipad|ipod/i) ? 'iOS' :
                 userAgent.match(/win/i) ? 'Windows' :
                 userAgent.match(/mac/i) ? 'MacOS' :
                 userAgent.match(/linux/i) ? 'Linux' :
                 'Other';

    return {
        userAgent: userAgent,
        browser: browserName,
        os: osName,
        language: language
    };
}

class AppState {
    constructor() {
        this.setView(util.getObject('view'));
    }

    @observable opts = {
        skipRedirect: true,

        showCustomerLogo: false,
        showArticle: true,
        showMedia: true,
        showNews: true,
        showInstallation: true,
        showRace: true,
        showPart: true,

        showInspections: false,
        showAddInstallations: true,
        showSortParts: true,
        showAddParts: true,
        showTimeTracker: true,
        showDyrejournal: false,
        showFrontpageShop: false,

        swipeLeft: true,
        swipeRight: true,
        showLoan: true,
        showAddUsage: true,
        showMove: true,
        showDelete: true,
        showEdit: true,
        showRefresh: true,

        showAddToInstallation: true,
        showAddLog: true,
        showDeleteFromInstallation: true,
        allowEditQty: true,

        loginCountries: [
            'NO', // Norge
            'AR', // Argentina
            'AT', // Østerrike
            'BE', // Belgia
            'CH', // Sveits
            'CZ', // Tsjekkia
            'DE', // Tyskland
            'DK', // Danmark
            'EE', // Estland
            'ES', // Spania
            'FR', // Frankrike
            'GB', // Storbritannia
            'IE', // Irland
            'IT', // Italia
            'NL', // Nederland
            'PL', // Polen
            'SE', // Sverige
            'FI', // Finland
            'CA', // Canada
            'LT', // Litauen
            'SK', // Slovakia
            'US'  // USA
        ]
    };

    @observable isDevelopment = process.env.NODE_ENV === 'development';

    @observable state = 'active';

    @observable isVisibleNavbar = true;

    @observable isMobile = true;

    @observable currentEmail = null;

    @observable isAdmin = false;

    @observable isExpert = false;

    @observable mousePos = [];

    @observable view = {};

    @observable inputProps = {};

    @observable newBugReport = {};

    @observable newEmail = {};

    @observable newSms = {};

    @observable saved = {};

    @observable latlng = null;

    @observable mapColorMode = 'incline';

    @observable mapRange = [];

    @observable mapRangeMin = -13;

    @observable mapRangeMax = 13;

    @observable mapColors = [];

    @observable drawerHeightLarge = '100vh';

    @observable drawerHeightMediumLarge = '90vh';

    @observable drawerHeightMedium = '75vh';

    @observable drawerHeightMediumMedium = '60vh';

    @observable drawerHeightMediumSmall = '50vh';

    @observable drawerHeightSmall = '40vh';

    @observable drawerHeightXSmall = '20vh';

    @observable drawerHeight = '500px';

    @observable darkmode = util.get('darkmode');

    @observable viewmode = util.get('viewmode') || 'normal';

    @observable counter = util.get('counter');

    @observable language = util.get('language') || 'no';

    @observable languageList = [['en', 'English', '🇬🇧'], ['no', 'Norsk', '🇳🇴']];

    @observable mapChecked = util.get('mapChecked') || 'OpenStreetMap.Mapnik';

    @observable wantedPage = util.get('wantedPage') || '/';

    @observable showAdminFields = util.get('showAdminFields');

    @observable userEmail = util.getUserEmail();

    @observable userCellphone = util.getUserCellphone();

    @observable jwtToken = util.getJwtToken();

    @observable apiServer = util.getApiServer();

    @observable mapType = util.get('mapType') || 'topograatone';

    @observable imageDirection = util.get('imageDirection') || 'horizontal';

    @observable apiServerChanged = false;

    @observable webocketUrl = util.getWebsocketServer();

    @observable webocketUrlChanged = false;

    @observable fingerprint = '';

    @observable path = '/';

    @observable currentVersion = 0;

    @observable latestVersion = 0;

    @observable previousPath = '';

    @observable workoutType = 'all';

    @observable prevScroll = 0;

    @observable publicTrackFilter = '';

    @observable hasShareApi = false;

    @observable isCordova = false;

    @observable cordovaIsReady = false;

    @observable connectionStatus = 'unknown';

    @observable displayDetails = util.get('displayDetails') || {};

    @observable showInput = util.get('showInput') || {};

    @observable editUserDetails = {};

    @observable veterinaryUserDetails = {};

    @observable selectedClassIds = util.get('selectedClassIds') || [];

    @observable searchResultStories = [];

    @observable searchResultDogs = [];

    @observable searchResultWorkouts = [];

    @observable searchResultTeams = [];

    @observable searchResultTracks = [];

    @observable searchResultUsers = [];

    @observable publicTeams = [];

    @observable publicUsers = [];

    @observable currentCustomer = {};

    @observable swipeLeft = () => {};

    @observable swipeRight = () => {
        // Swipe to go back interferes with the swipe right inside the lists.
        // Be careful!
        //
        // console.log('path', this.path)
        // if (
        //     /^\/race\//.test(this.path)
        //     || /^\/raceclass\//.test(this.path)
        //     // || /^\/installations\//.test(this.path)
        // ) {
        //     window.history.back();
        // }
    };

    @observable swipeUp = () => {};

    @observable swipeDown = () => {};


    @observable swipeRights = [];

    @observable swipeLefts = [];

    @observable swipeUps = [];

    @observable swipeDowns = [];

    @observable swipeRightIndicate = () => {};

    @observable swipeLeftIndicate = () => {};

    @observable swipeUpIndicate = () => {};

    @observable swipeDownIndicate = () => {};

    @observable swipeRightIndicates = [];

    @observable swipeLeftIndicates = [];

    @observable swipeUpIndicates = [];

    @observable swipeDownIndicates = [];

    @observable showDashoard = false;

    @observable appContainer = null;

    @observable showDrawer1 = false;

    @observable showDrawer2 = false;

    @observable showDrawer3 = false;

    @observable showDrawer4 = false;

    @observable showDrawer5 = false;

    @observable showDrawer6 = false;

    @observable showDrawer7 = false;

    @observable showDrawer8 = false;

    @observable showDrawer9 = false;

    @observable showDrawerRight1 = false;

    @observable showDrawerRight2 = false;

    @observable showDrawerRight3 = false;

    @observable showDrawerRight4 = false;

    @observable showDrawerRight5 = false;

    @observable drawerComponent1 = null;

    @observable drawerComponent2 = null;

    @observable drawerComponent3 = null;

    @observable drawerComponent4 = null;

    @observable drawerComponent5 = null;

    @observable drawerComponent6 = null;

    @observable drawerComponent7 = null;

    @observable drawerComponent8 = null;

    @observable drawerComponent9 = null;

    @observable drawerProps1 = {};

    @observable drawerProps2 = {};

    @observable drawerProps3 = {};

    @observable drawerProps4 = {};

    @observable drawerProps5 = {};

    @observable drawerProps6 = {};

    @observable drawerProps7 = {};

    @observable drawerProps8 = {};

    @observable drawerProps9 = {};

    @observable logs = [];

    @observable errors = [];

    @observable statistics = [];

    @observable apiLogs = [];

    @observable apiLogsUsers = [];

    @observable apiLogsCustomers = [];

    @observable queryLogs = [];

    @observable queryLogsUsers = [];

    @observable queryLogsCustomers = [];

    @observable mainView = 'webcam';

    @observable subView = null;

    @observable weather = [];

    @observable loadMore = () => {};

    @observable mainViewCallback = () => {};

    @observable subViewCallback = () => {};

    @observable usageLog = [];

    @observable user = {};

    @observable clickOnLogo = () => {};

    @action
    setClickOnLogo(func) {
        if (util.isFunction(func)) {
            this.clickOnLogo = func;
        }
    }

    @action
    addUsageLog(data) {
        const inputData = JSON.parse(JSON.stringify(data));
        if (inputData?.props) {
            const keys = Object.keys(inputData.props);
            for (let i = 0; i < keys.length; i++) {
                if (util.isObject(inputData.props[keys[i]])) {
                    delete inputData.props[keys[i]];
                }
            }
        }
        this.usageLog.push({
            domain: window.location.hostname,
            ...inputData,
        });
    }

    @action
    emptyUsageLog() {
        this.usageLog = [];
    }

    @action
    setUser(user) {
        this.user = user;
    }

    @action
    updateKeyValue(key, value) {
        this[key] = value;
    }

    @action
    updateObjectKeyValue(obj, key, value) {
        this[obj][key] = value;
    }

    @action
    setPageHeaders(headers) {
        // replace html headers for og and twitter
        {/* <meta property="og:url" content="https://litt.no/Livesenter/Live/322" />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="no_NO" />
        <meta property="og:title" content="Live" />
        <meta property="og:description" content="" />
        <meta property="og:site_name" content="litt.no" />
        <meta property="og:image" content="https://litt.no/pho/photo/simpleblog-77e5d472-9848-4bf1-a43d-f82d9783206a.jpg?w=600" />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:type" content="image/jpeg" />

        <!-- Twitter Card -->
        <meta name="twitter:site" content="https://twitter.com/sorenso" />
        <meta name="twitter:text:title" content="Live" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:creator" content="https://twitter.com/sorenso" />
        <meta name="twitter:image" content="https://litt.no/pho/photo/simpleblog-77e5d472-9848-4bf1-a43d-f82d9783206a.jpg?w=600" />

        <meta name="thumbnail" content="https://litt.no/pho/photo/simpleblog-77e5d472-9848-4bf1-a43d-f82d9783206a.jpg?w=600" /> */}

        if (headers) {
            document.title = headers.title;
            if (document.querySelector('meta[name="title"]')) {
                document.querySelector('meta[name="title"]').setAttribute("content", headers.title);
            } else {
                addMetaTag('title', headers.title);
            }
            if (document.querySelector('meta[name="description"]')) {
                document.querySelector('meta[name="description"]').setAttribute("content", headers.description);
            } else {
                addMetaTag('description', headers.description);
            }
            if (document.querySelector('meta[name="thumbnail"]')) {
                document.querySelector('meta[name="thumbnail"]').setAttribute("content", headers.image);
            } else {
                addMetaTag('thumbnail', headers.image);
            }

            if (document.querySelector('meta[property="og:url"]')) {
                document.querySelector('meta[property="og:url"]').setAttribute("content", headers.url);
            } else {
                addMetaTag('og:ur', headers.url);
            }
            if (document.querySelector('meta[property="og:title"]')) {
                document.querySelector('meta[property="og:title"]').setAttribute("content", headers.title);
            } else {
                addMetaTag('og:title', headers.title);
            }
            if (document.querySelector('meta[property="og:description"]')) {
                document.querySelector('meta[property="og:description"]').setAttribute("content", headers.description);
            } else {
                addMetaTag('og:description', headers.description);
            }
            if (document.querySelector('meta[property="og:image"]')) {
                document.querySelector('meta[property="og:image"]').setAttribute("content", headers.image);
            } else {
                addMetaTag('og:image', headers.image);
            }

            if (document.querySelector('meta[property="twitter:text:title"]')) {
                document.querySelector('meta[property="twitter:text:title"]').setAttribute("content", headers.title);
            } else {
                addMetaTag('twitter:text:title', headers.title);
            }
            if (document.querySelector('meta[property="twitter:description"]')) {
                document.querySelector('meta[property="twitter:description"]').setAttribute("content", headers.description);
            } else {
                addMetaTag('twitter:description', headers.description);
            }
            if (document.querySelector('meta[property="twitter:image"]')) {
                document.querySelector('meta[property="twitter:image"]').setAttribute("content", headers.image);
            } else {
                addMetaTag('twitter:image', headers.image);
            }
        }
    }

    updateMetaTags(object) {
        updateMetaTags(object);
    }

    storeDefaultMetaTags({ title = document.title, keywords = '', description = '', image = '', url = '', type = '' }) {
        // Store the default meta tags
        this.defaultMetaTags = {
            title,
            keywords,
            description,
            image,
            url,
            image,
            type,
        };
    }

    restoreDefaultMetaTags() {
        // Restore the default meta tags
        updateMetaTags(this.defaultMetaTags);
    }

    @action
    setWebsocketServer(webocketUrl) {
        util.setWebsocketServer(webocketUrl);
        this.webocketUrl = webocketUrl;
        this.webocketUrlChanged = true;
    }

    @action
    setLoadMore(func) {
        if (util.isFunction(func)) {
            this.loadMore = func;
        }
    }

    @action
    setState(state) {
        this.state = state;
    }
    @action
    setInputProps(inputProps) {
        this.inputProps = inputProps;
        this.opts = {
            ...this.opts,
            ...inputProps,
        };
    }

    @action
    toggleDrawer(state, drawerLevel = 1) {
        // console.log('toggleDrawer', { state, drawerLevel });
        if (drawerLevel) {
            this[`showDrawer${drawerLevel}`] = false;
            if (drawerLevel === 1) {
                for (let i = 2; i <= 9; i++) {
                    this[`showDrawer${i}`] = false;
                }
                util.toggleBodyClasses('noscroll', false);
            }
            return;
        }
    }

    getTopMostDrawer() {
        for (let i = 9; i >= 1; i--) {
            if (this[`showDrawer${i}`]) {
                return i;
            }
        }
        return 0;
    }

    @action
    closeDrawer(state, drawerLevel = 1) {
        this.toggleDrawer(state, drawerLevel);
    }

    /**
     * Opens a drawer with the specified component and props.
     * @param {React.ComponentType} component - The component to render in the drawer.
     * @param {Object} props - The props to pass to the component.
     * @param {number} [drawerLevel=1] - The level of the drawer to open (1 or 2).
     * @returns {void}
     */
    @action
    openDrawer(component, props, drawerLevel = 1) {
        if (drawerLevel) {
            this.addUsageLog({
                type: 'openDrawer',
                url: component,
                timestamp: Math.floor(new Date().getTime() / 1000),
                browser: getBrowserInfo(),
                user: this.user ? this.user.id : null,
                props,
            });

            this[`drawerComponent${drawerLevel}`] = component;
            this[`drawerProps${drawerLevel}`] = props;
            this[`showDrawer${drawerLevel}`] = true;
            if (drawerLevel === 1) {
                util.toggleBodyClasses('noscroll', true);
            }
            return;
        }
    }

    @action
    toggleDrawerRight(state, drawerLevel = 1) {
        if (drawerLevel) {
            this[`showDrawerRight${drawerLevel}`] = false;
            if (drawerLevel === 1) {
                for (let i = 2; i <= 5; i++) {
                    this[`showDrawerRight${i}`] = false;
                }
                util.toggleBodyClasses('noscroll', false);
            }
            return;
        }
    }

    /**
     * Opens a right-hand drawer with the specified component and props.
     * @param {React.ComponentType} component - The component to render in the drawer.
     * @param {Object} props - The props to pass to the component.
     * @param {number} [drawerLevel=1] - The level of the drawer to open (1 or 2).
     * @returns {void}
     */
    @action
    openDrawerRight(component, props, drawerLevel = 1) {
        // console.log('openDrawer', component, props, drawerLevel)
        this.addUsageLog({
            type: 'openDrawerRight',
            url: component,
            timestamp: Math.floor(new Date().getTime() / 1000),
            browser: getBrowserInfo(),
            user: this.user ? this.user.id : null,
            props,
        });
        if (drawerLevel) {
            this[`drawerComponent${drawerLevel}`] = component;
            this[`drawerProps${drawerLevel}`] = props;
            this[`showDrawerRight${drawerLevel}`] = true;
            if (drawerLevel === 1) {
                util.toggleBodyClasses('noscroll', true);
            }
            return;
        }
    }

    @action
    setAppContainer(ref) {
        this.appContainer = ref;
    }

    appContainerScrollTop = (top = 0, left = 0) => {
        // console.log(this.appContainer.clientHeight, this.appContainer.scrollLeft, this.appContainer.scrollTop);
        // console.log('appContainerScrollTop', this.appContainer, top, left);
        scrollTo(this.appContainer, top, left);
        // console.log(this.appContainer.clientHeight, this.appContainer.scrollLeft, this.appContainer.scrollTop);
    }

    @action
    toggleDashboard() {
        this.showDashoard = !this.showDashoard;
    }

    @action
    setSwipeLeft(func) {
        if (typeof func === 'function') {
            this.swipeLefts.push(this.swipeLeft);
            this.swipeLeft = func;
        } else {
            this.swipeLeft = this.swipeLefts.pop();
        }
    }

    @action
    setSwipeRight(func) {
        if (typeof func === 'function') {
            this.swipeRights.push(this.swipeRight);
            this.swipeRight = func;
        } else {
            this.swipeRight = this.swipeRights.pop();
        }
    }

    @action
    setSwipeUp(func) {
        if (typeof func === 'function') {
            this.swipeUps.push(this.swipeUp);
            this.swipeUp = func;
        } else {
            this.swipeUp = this.swipeUps.pop();
        }
    }

    @action
    setSwipeDown(func) {
        if (typeof func === 'function') {
            this.swipeDowns.push(this.swipeDown);
            this.swipeDown = func;
        } else {
            this.swipeDown = this.swipeDowns.pop();
        }
    }

    @action
    setSwipeRightIndicate(func) {
        if (typeof func === 'function') {
            this.swipeRightIndicates.push(this.swipeRightIndicate);
            this.swipeRightIndicate = func;
        } else {
            this.swipeRightIndicate = this.swipeRightIndicates.pop();
        }
    }

    @action
    setSwipeLeftIndicate(func) {
        if (typeof func === 'function') {
            this.swipeLeftIndicates.push(this.swipeLeftIndicate);
            this.swipeLeftIndicate = func;
        } else {
            this.swipeLeftIndicate = this.swipeLeftIndicates.pop();
        }
    }

    @action
    setSwipeUpIndicate(func) {
        if (typeof func === 'function') {
            this.swipeUpIndicates.push(this.swipeUpIndicate);
            this.swipeUpIndicate = func;
        } else {
            this.swipeUpIndicate = this.swipeUpIndicates.pop();
        }
    }

    @action
    setSwipeDownIndicate(func) {
        if (typeof func === 'function') {
            this.swipeDownIndicates.push(this.swipeDownIndicate);
            this.swipeDownIndicate = func;
        } else {
            this.swipeDownIndicate = this.swipeDownIndicates.pop();
        }
    }

    @action
    setMapType(type) {
        this.mapType = type;
        util.set('mapType', type);
    }

    @action
    setImageDirection(imageDirection) {
        this.imageDirection = imageDirection;
        util.set('imageDirection', imageDirection);
    }

    @action
    setApiServer(apiServer) {
        util.setApiServer(apiServer);
        this.apiServer = apiServer;
        this.apiServerChanged = true;
    }

    @action
    localUpdateField(key, value) {
        this[key] = value;
    }

    @action
    setSelectedClassId(classId) {
        // console.log('addSelectedClassId', classId);
        if (classId) {
            this.selectedClassIds = [classId];
            util.set('selectedClassIds', this.selectedClassIds);
        }
    }

    @action
    addSelectedClassId(classId) {
        // console.log('addSelectedClassId', classId);
        if (classId) {
            const idx = this.selectedClassIds?.indexOf(classId);
            if (idx === -1) {
                this.selectedClassIds.push(classId);
            }
            util.set('selectedClassIds', this.selectedClassIds);
        }
    }

    @action
    removeSelectedClassId(classId) {
        if (classId) {
            const idx = this.selectedClassIds?.indexOf(classId);
            if (idx > -1) {
                this.selectedClassIds.splice(idx, 1);
            }
            this.selectedClassIds = this.selectedClassIds.filter(e => e !== null);
            util.set('selectedClassIds', this.selectedClassIds);
        }
    }

    @action
    hasSelectedClassId(classId) {
        const idx = this.selectedClassIds?.indexOf(classId);
        if (idx > -1) {
            return true;
        }
        return false;
    }

    @action
    toggleSelectedClassId(classId, single) {
        // console.log('toggleSelectedClassId', classId, this.selectedClassIds);
        if (single) {
            this.setSelectedClassId(classId);
        } else if (this.hasSelectedClassId(classId)) {
            this.removeSelectedClassId(classId);
        } else {
            this.addSelectedClassId(classId);
        }
    }

    @action
    toggleDisplayDetails(email) {
        this.displayDetails[email] = !this.displayDetails[email];
        util.set('displayDetails', this.displayDetails);
    }

    @action
    toggleInput(type) {
        this.showInput[type] = !this.showInput[type];
        util.set('showInput', this.showInput);
    }

    @action
    toggleEditUserDetails(email) {
        this.editUserDetails[email] = !this.editUserDetails[email];
    }

    @action
    toggleVeterinaryUserDetails(email, onlyOne) {
        if (onlyOne) {
            const prevValue = this.veterinaryUserDetails[email];
            this.veterinaryUserDetails = {};
            this.veterinaryUserDetails[email] = !prevValue;
        } else {
            this.veterinaryUserDetails[email] = !this.veterinaryUserDetails[email];
        }
    }

    @action
    setLanguage(language) {
        this.language = language;
        util.set('language', language);
        window.location.reload(true);
    }

    @action
    setWantedPage(wantedPage) {
        this.wantedPage = wantedPage;
        util.set('wantedPage', wantedPage);
    }

    @action
    setShowAdminFields(showAdminFields) {
        this.showAdminFields = showAdminFields;
        util.set('showAdminFields', showAdminFields);
    }

    @action
    setMapChecked(mapChecked) {
        this.mapChecked = mapChecked;
        util.set('mapChecked', mapChecked);
    }

    @action
    setPath(path) {
        this.path = path;
    }

    @action
    setVersion(version = {}) {
        this.currentVersion = version.build;
    }

    @action
    setLatestVersion(version = {}) {
        this.latestVersion = version.build;
    }

    @action
    setPrevPath(path) {
        this.previousPath = path;
    }
    @action
    setPrevScroll(scroll) {
        this.prevScroll = scroll;
    }

    @action
    setMousePosition(pos = []) {
        this.mousePos = pos;
    }

    @action
    setView(view) {
        this.view = view;
        util.set('view', view);
    }

    @action
    setWorkoutType(type) {
        this.workoutType = type;
    }

    @action
    setViewKey(key, value) {
        if (!util.isObject(this.view)) {
            this.view = {};
        }
        this.view[key] = value;
        util.setObject('view', key, value);
    }

    @action
    setKey(key, value) {
        this[key] = value;
    }

    @action
    checkShareApi() {
        if (navigator && navigator.share) {
            // Web Share is supported
            this.hasShareApi = true;
        }
    }

    async shareUrl({ url, title, text }) {
        try {
            await navigator.share({ url, title, text });
            return true;
        } catch (e) {
            console.error('Could not share!', e);
            return false;
        }
    }

    @action
    checkCordova() {
        // if(!window.cordova && !window.BackgroundGeolocation) {
        if(!window.cordova) {
            this.isCordova = false;
            // document.body.style['margin-top'] = '200px';
        } else {
            this.isCordova = true;
            // document.body.style['margin-top'] = '200px';
        }
    }

    @action
    cordovaSetToReady() {
        this.cordovaIsReady = true;
    }

    @action
    setConnectionStatus(status) {
        this.connectionStatus = status;
    }

    @action
    incCounter() {
        this.counter += 1;
        util.set('counter', this.counter);
    }

    @action
    decCounter() {
        this.counter -= 1;
        util.set('counter', this.counter);
    }

    @computed
    get counterTimes2() {
        return this.counter * 2;
    }

    @action
    toggleDarkmode(value) {
        // console.log('toggleDarkmode', this.darkmode);
        if (util.isDefined(value)) {
            this.darkmode = value;
        } else {
            this.darkmode = !this.darkmode;
        }
        util.set('darkmode', this.darkmode);
        util.toggleDarkModeClasses(this.darkmode);
    }

    @action
    toggleViewmode(mode) {
        // console.log('toggleViewmode', this.viewmode);
        this.viewmode = mode || !this.viewmode;
        util.set('viewmode', this.viewmode);
    }

    @action
    toggleField(key, val) {
        this[key] = val ? val : !this.val;
    }

    @action
    setLatlng(latlng = []) {
        this.latlng = latlng;
    }

    @action
    setMapColorMode(colorMode) {
        this.mapColorMode = colorMode;
    }

    @action
    setMapRange(range, min, max) {
        this.mapRange = range;
        this.mapRangeMin = min;
        this.mapRangeMax = max;
    }

    @action
    setMapColors(colors) {
        this.mapColors = colors;
    }

    @action
    updateField(key, val) {
        this[key] = val;
    }

    @action
    findPublicTeams(teams = []) {
        const results = this.publicTeams.filter(e => teams?.indexOf(e.id) > -1 && e.public).map(e => toJS(e));
        return results;
    }

    @action
    findPublicTeam(team) {
        const idx = this.publicTeams?.findIndex(e => e.id === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByUuidv4(team) {
        const idx = this.publicTeams?.findIndex(e => e.uuidv4 === team);
        if (idx > -1) {
            return this.publicTeams[idx];
        }
    }

    @action
    findPublicTeamByMembers(user) {
        if (!user) {
            return [];
        }
        const teams = this.publicTeams.filter(e => e.members?.indexOf(user) > -1);
        return teams;
    }

    @action
    findPublicUser(user) {
        if (!this.publicUsers) {
            return {};
        }
        const idx = this.publicUsers?.findIndex(e => e.id === user);
        if (idx > -1) {
            return this.publicUsers[idx];
        }
    }

    @action
    setMainView(view) {
        this.mainView = view;
        this.mainViewCallback(view);
    }

    @action
    setSubView(view) {
        this.subView = view;
        this.subViewCallback(view);
    }

    @action
    setMainViewCallback(func) {
        this.mainViewCallback = func;
    }

    @action
    setSubViewCallback(func) {
        this.subViewCallback = func;
    }

    async getCustomerByHostname() {
        const res = await util.fetchApi('/api/customers/public', { publish: false }, { hostname: window.location.hostname });
        if (res.status === 200) {
            this.updateField('currentCustomer', res.data?.customer);
        }
    }

    async getFingerprint() {
        const res = await util.fetchApi('/api/public/fingerprint/', { publish: false });
        if (res.status === 200) {
            this.updateField('fingerprint', res.fingerprint);
            this.updateField('jwtToken', res.jwtToken);
        }
    }

    async getInfo() {
        const res = await util.fetchApi('/api/info', { publish: false, credentials: 'include' });
        if (res.status === 200) {
            this.updateField('isAdmin', res.data.isAdmin);
            this.updateField('isExpert', res.data.isExpert);
            this.updateField('currentEmail', res.data.currentEmail);
            this.updateField('jwtToken', res.data.jwtToken);
            util.setJwtToken(res.data.jwtToken);
            return true;
        } else {
            return false;
        }
    }

    async sendEmail({ from, to = [], subject = '', body = '', raceId, raceClassId }) {
        const response = await util.fetchApi(`/api/email/`, { publish: true, method: 'POST' }, { from, to, subject, body, raceId, raceClassId });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                route('/');
                break;
            default:
                return response;
        }
    }

    async sendSms({ from, to = [], body = '' }) {
        const response = await util.fetchApi(`/api/sms/`, { publish: true, method: 'POST' }, { from, to, body });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                route('/');
                break;
            default:
                return response;
        }
    }

    async sendEmailPlain({ to = [], subject = '', body = '', from }) {
        const response = await util.fetchApi(`/api/email/plain`, { publish: true, method: 'POST' }, { from, to, subject, body });
        switch (response.status) {
            case 200:
                return response;
            case 401:
                route('/');
                break;
            default:
                return response;
        }
    }

    @action
    setWeather = (weather) => {
        this.weather = weather;
    }

    async postLog({ type = 'appPerformance', deviceInfo, currentLocation }) {
        const lines = this.getLogs();
        const response = await util.fetchApi(`/api/logs/`, { publish: false, method: 'POST' }, {
            lines,
            deviceInfo,
            currentLocation,
            type,
        });
        switch (response.status) {
            case 201:
                return response;
            case 401:
                route('/');
                break;
        }
    }

    async getWeather({ lat, lon, altitude, updateAppstate = true }) {
        const response = await util.fetchApi(`/api/yr/`, { publish: false, method: 'GET' }, {
            lat, lon, altitude,
        });
        switch (response.status) {
            case 200:
                if (updateAppstate) {
                    this.setWeather(response.data);
                }
                return response.data;
            case 401:
                route('/');
                break;
        }
    }

    onPhotoURISuccess = (imageURI) => {
        console.log(JSON.stringify(imageURI));
    }

    onFail = (message) => {
        console.log('Failed because: ' + message);
    }

    uploadPhoto = (imageURI) => {
        const options = new FileUploadOptions();
        options.fileKey = 'file';
        options.fileName = imageURI.substr(imageURI.lastIndexOf('/') + 1) + '.jpg';
        options.mimeType = 'text/plain';

        var params = new Object();
        options.params = params;

        var ft = new FileTransfer();
        ft.upload(imageURI, encodeURI("http://some.server.com/upload.php"), win, fail, options);
    }

    win(r) {
        console.log("Code = " + r.responseCode);
        console.log("Response = " + r.response);
        console.log("Sent = " + r.bytesSent);
    }

    fail(error) {
        alert("An error has occurred: Code = " + error.code);
        console.log("upload error source " + error.source);
        console.log("upload error target " + error.target);
    }

    getPhoto(source = Camera?.PictureSourceType?.PHOTOLIBRARY) {
        if (navigator.camera) {
            navigator.camera.getPicture(this.onPhotoURISuccess, this.onFail, {
                quality: 50,
                mediaType: Camera?.MediaType?.PICTURE,
                destinationType: Camera?.DestinationType?.FILE_URI,
                sourceType: source,
                correctOrientation: true,
                saveToPhotoAlbum: true,
                cameraDirection: Camera?.Direction?.BACK,
            });
        }
    }

    async spiderPage({ url }) {
        const response = await util.fetchApi(`/api/spider/${encodeURIComponent(url)}`, {}, this);
        switch (response.status) {
            case 200:
                return {
                    ...response.data,
                }
                break;
            default:
                return response;
        }
    }

    async loadErrors({ limit = 100, offset = 0 }) {
        const response = await util.fetchApi(`/api/admin/errors/`, { publish: false, method: 'GET' }, {
            limit, offset,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('errors', response.data);
        }
    }

    async loadStatistics({}) {
        const response = await util.fetchApi(`/api/usageLogs/statistics`, { publish: false, method: 'GET' }, {
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('statistics', response.data);
        }
    }

    async loadApiLogs({ search, limit = 500, offset = 0 }) {
        const response = await util.fetchApi(`/api/apilogger/admin/`, { publish: false, method: 'GET' }, {
            limit, offset,
            search,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('apiLogs', response.data);
                this.updateKeyValue('apiLogsUsers', response.included.users);
                this.updateKeyValue('apiLogsCustomers', response.included.customers);
        }
    }

    async loadQueryLogs({ search, limit = 500, offset = 0 }) {
        const response = await util.fetchApi(`/api/querylogger/admin/`, { publish: false, method: 'GET' }, {
            limit, offset,
            search,
        });
        switch (response.status) {
            case 200:
                this.updateKeyValue('queryLogs', response.data);
                this.updateKeyValue('queryLogsUsers', response.included.users);
                this.updateKeyValue('queryLogsCustomers', response.included.customers);
        }
    }

    async checkVersion() {
        const response = await util.fetchApi(`/api/version`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.setLatestVersion(response.data);
        }
    }

    async loadVideoStreamingUrl(streamName) {
        const response = await util.fetchApi(`/api/video/${streamName}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                console.log('loadVideoStreamingUrl', response.data);
                return response.data?.url?.url;
        }
    }

    async postnummerLookup(postnummer) {
        const response = await util.fetchApi(`/api/postnummer/${postnummer}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                console.log('postnummerLookup', response.data);
                return response.data?.url?.url;
        }
    }

    async animaliaIndividualList(individual = '') {
        const response = await util.fetchApi(`/api/integrations/animalia/individuals/${individual}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                console.log('animaliaIndividualList', response);
                return response;
        }
    }
    async animaliaDiagnosesList({ code = '', doImport = false }) {
        const response = await util.fetchApi(`/api/integrations/animalia/diagnoses/${code}`, { method: 'GET' }, {
            doImport,
        });
        switch (response.status) {
            case 200:
                console.log('animaliaDiagnosesList', response);
                return response;
        }
    }
    async animaliaMedicinesList({ doImport = false }) {
        const response = await util.fetchApi(`/api/integrations/animalia/medicines`, { method: 'GET' }, {
            doImport,
        });
        switch (response.status) {
            case 200:
                console.log('animaliaMedicinesList', response);
                return response;
        }
    }

    async animaliaUploadVisit(visit, journal) {
        const response = await util.fetchApi(`/api/integrations/animalia/visits`, { method: 'POST' }, {
            visit,
            journal,
        });
        switch (response.status) {
            case 200:
                console.log('animaliaUploadVisit', response);
                return response;
            default:
                // console.log('animaliaUploadVisit', response);
                return response;
        }
    }
}

const store = new AppState();

autorun(() => {
    // console.log(store.counter);
})

export default store;
