import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
import DyrejournalSaleListMini from '../../components/dyrejournal/listSaleMini';
import DyrejournalVisitorListCommunicationMini from '../../components/dyrejournal/listVisitorCommunication';

@observer
class VisitorListMini extends Component {
	constructor(props) {
        super(props);
        this.state = {
            dyreIdOwner: {},
            dyreIdAnimals: {},
        };
    }

    onClickEditVisitor = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('.visitor-card').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState, saleStore, visitorStore } = this.props.stores;
		const { drawerHeightLarge } = appState;

		appState.openDrawer('editVisitor', {
			height: drawerHeightLarge,
            isNew: false,
            id: parseInt(id, 10),
            callback: () => {
                // console.log('onClickEditVisitor callback');
                const { visitor } = visitorStore;
                const { newSale, visitorResults } = saleStore;
                const { visitors } = newSale;
                const idx = visitors.findIndex(a => a.id === visitor.id);
                if (idx > -1) {
                    saleStore.updateObjectKeyArrValue('newSale', 'visitors', idx, visitor);
                }
                const idx2 = visitorResults.findIndex(a => a.id === visitor.id);
                if (idx2 > -1) {
                    saleStore.updateKeyArrValue('visitorResults', idx2, visitor);
                }
            },
		}, drawerLevel + 1);
    }

    onClickRemoveVisitor = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('div').dataset;
        const { saleStore } = this.props.stores;
        const { newSale } = saleStore;
        const { visitors } = newSale;
        const newVisitors = visitors.filter(v => v.id !== parseInt(id, 10));
        saleStore.updateObjectKeyValue('newSale', 'visitors', newVisitors);
        saleStore.updateObjectKeyValue('newSale', 'animals', []);
        saleStore.updateKeyValue('visitorAnimals', []);
    }

    onClickSendSms = e => {
        e.stopPropagation();
        e.preventDefault();
        const { firstname, lastname, cellphone } = e.target.closest('button').dataset;
        const { drawerLevel = 1 } = this.props;
        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('sendSms', {
			height: drawerHeightMedium,
            isNew: false,
            users: [{
                firstname,
                lastname,
                cellphone,
            }],
            cellphone: cellphone,
            callback: () => {
                // console.log('onClickSendSms callback');
            },
		}, drawerLevel + 1);
    }

    onClickCreateNewSaleForVisitor = e => {
        e.stopPropagation();
        e.preventDefault();
        const {
            visitors = [],
        } = this.props;
        const { visitorStore, saleStore, animalStore } = this.props.stores;
        const { currentVisitors } = visitorStore;
        const finalVisitors = (visitors && visitors.length > 0) ? (currentVisitors || visitors) : [];
        saleStore.createNewSale({ visitors: finalVisitors });
// We should load the animals and sales for the visitor here.
        const visitorIds = finalVisitors.map(v => v?.id);
        if (visitorIds.length > 0) {
            this.loadAnimals(visitorIds);
            this.loadSales(visitorIds);
        }
        animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorIds[0]);
    }

    onClickCreateNewSaleForThisVisitor = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
        const { saleStore, animalStore } = this.props.stores;
        const { lastVisitors } = saleStore;
        const visitor = lastVisitors.find(v => v.id === parseInt(id, 10));
        saleStore.createNewSale({ visitors: [visitor] });

        const { newSale } = saleStore;
        const { visitors } = newSale;
        const visitorIds = visitors.map(v => v?.id);
        if (visitorIds.length > 0) {
            this.loadAnimals(visitorIds);
            this.loadSales(visitorIds);
        }
        animalStore.updateObjectKeyValue('newAnimal', 'owner', visitor.id);

        // const isProductionAnimals = visitors.some(v => v?.producerNumber);
        // saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);

        await animalStore.load({
            query: {
                owner: visitorIds,
            },
        });
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
        // this.loadAllVisitorSalePayments();
    }

    loadAll = async (props = this.props) => {
        const { visitors = [] } = props;
        const { saleStore, journalStore, visitorStore } = this.props.stores;
        const visitorIds = visitors.map(v => v.id);
        saleStore.updateKeyValue('visitorSales', []);
        if (visitorIds && visitorIds.length > 0) {
            const visitorSales = await saleStore.load({ query: { visitor: visitorIds }, skipUpdate: 1 });
            saleStore.updateKeyValue('visitorSales', visitorSales);

            const currentVisitors = await visitorStore.load({ query: { id: visitorIds }, skipUpdate: 1 });
            visitorStore.updateKeyValue('currentVisitors', currentVisitors);
        }

        this.loadAllVisitorSalePayments();
    }

    loadAllVisitorSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        saleStore.updateKeyValue('visitorAllPayments', []);

        const { visitorSales = [] } = saleStore;
        const saleIds = visitorSales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAllPayments', response);
    }

    dyreIdSearchOwnerPet = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { cellphone } = e.target.closest('button').dataset;
        const { animalStore } = this.props.stores;
        const result = await animalStore.dyreIdSearchOwnerPet(cellphone);
        this.setState({
            dyreIdOwner: {
                [cellphone]: result?.data || { error: 'No data' },
            },
        });
    }

    dyreIdGetOwnerPetsInfoByPhoneNumberEmail = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { cellphone } = e.target.closest('button').dataset;
        const { animalStore } = this.props.stores;
        const result = await animalStore.dyreIdGetOwnerPetsInfoByPhoneNumberEmail(cellphone);
        this.setState({
            dyreIdAnimals: {
                [cellphone]: result?.data || { error: 'No data' },
            },
        });
    }

    componentDidMount() {
        this.loadAll();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.visitors !== this.props.visitors) {
            this.loadAll(nextProps)
        }
    }

    render() {
        const { dyreIdOwner = {}, dyreIdAnimals = {} } = this.state;
        const {
            visitors = [],
            saleIsLocked = false,
            classNames = '',
        } = this.props;
        const { userStore, visitorStore, saleStore } = this.props.stores;
        const { newSale, lastVisitors } = saleStore;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { currentVisitors } = visitorStore;
        const finalVisitors = (visitors && visitors.length > 0) ? (currentVisitors || visitors) : [];

        return (<>
            {(finalVisitors && finalVisitors.length > 0) && finalVisitors.map(e => <>
                <div class={`d-flex flex-column border box-container mt-2 bg-light p-2 visitor-card ${classNames}`}>
                    <div class={`d-flex flex-row justify-content-between px-1 py-0`}>

                        <div class='d-flex flex-column p-2'>
                            <div class={`d-flex flex-row justify-content-between px-0 py-0`}>
                                <div class='mr-2 visitor-card' onClick={this.onClickEditVisitor} data-id={e.id}>
                                    <i class='fa-solid fa-edit' />
                                </div>
                                <div class='flex-fill'><nobr>
                                    <span class='font-weight-lighter mr-1'>{e.visitorNumber || e.id}:</span>
                                    {localUtil.showName(e)}
                                </nobr></div>
                            </div>
                            {e.cellphone && <div class={`d-flex flex-row justify-content-between px-0 py-0`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-mobile text-muted' /> {e.cellphone}</nobr>
                                </div>
                            </div>}
                            {e.cellphone2 && <div class={`d-flex flex-row justify-content-between px-0 py-0`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-mobile text-muted' /> {e.cellphone2}</nobr>
                                </div>
                            </div>}
                            {e.cellphone3 && <div class={`d-flex flex-row justify-content-between px-0 py-0`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-mobile text-muted' /> {e.cellphone3}</nobr>
                                </div>
                            </div>}
                            {e.email && <div class={`d-flex flex-row justify-content-between px-0 py-0 text-muted`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-envelope' /> {e.email}</nobr>
                                </div>
                            </div>}
                            <div class={`d-flex flex-row justify-content-between px-0 py-0`} style='font-size: 0.8em; line-height: 1.3em;'>
                                <div>{e.address}</div>
                            </div>
                            <div class={`d-flex flex-row justify-content-between px-0 py-0`} style='font-size: 0.8em; line-height: 1.3em;'>
                                {e.postalcode && <div>{fields.getNumberFixedWidth(e.postalcode)}</div>}
                                <div class='flex-fill ml-2'>{e.place}</div>
                            </div>
                            {e.birth && <div class={`d-flex flex-row justify-content-between px-0 py-0 text-muted`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-cake-candles' /> {util.formatDate(e.birth)}</nobr>
                                </div>
                            </div>}
                            {e.producerNumber > 0 && <div class={`d-flex flex-row justify-content-between px-0 py-0 text-muted`}>
                                <div style='font-size: 0.8em; line-height: 1.3em;'>
                                    <nobr><i class='fa-solid fa-sheep' /> {e.producerNumber}</nobr>
                                </div>
                            </div>}
                        </div>

                        <div class='d-flex flex-column justify-content-between flex-fill p-2 border-left position-relative'>
                            {saleIsLocked ? <>
                            </> : <>
                                <div class={`position-absolute px-0 py-0`} style='top: 5px; right: 5px;'>
                                    <div class='ml-2' onClick={this.onClickRemoveVisitor} data-id={e.id}><i class='fa-solid fa-xmark' /></div>
                                </div>
                            </>}
                            {e.comment ? <>
                                <div class={`d-flex flex-row justify-content-between px-0 py-0 text-muted`}>
                                    <div>
                                        <i class='fa-duotone fa-circle-info' /> {e.comment}
                                    </div>
                                </div>
                            </> : <>
                                <div>&nbsp;</div>
                            </>}

                            <DyrejournalVisitorListCommunicationMini
                                key={`visitor-communication-${e.id}`}
                                stores={this.props.stores}
                                classNames='w-100'
                                visitorCellphone={e.cellphone}
                                visitorEmail={e.email}
                            />

                            <div class={`d-flex flex-row justify-content-center mt-2`}>
                                {newSale.id && <>
                                    <button class='btn btn-sm btn-outline-primary rounded-pill mx-1' onClick={this.onClickCreateNewSaleForVisitor}>
                                        <i class='fa-duotone fa-plus' /> <Text id='dyrejournal.visitor.create-new-sale'>Create new sale for visitor</Text>
                                    </button>
                                </>}
                                {e.cellphone && <>
                                    <button
                                        class='btn btn-sm btn-outline-success rounded-pill mx-1 py-0 px-2'
                                        onClick={this.onClickSendSms}
                                        data-firstname={e.firstname}
                                        data-lastname={e.lastname}
                                        data-cellphone={e.cellphone}
                                    >
                                        <i class='fa-duotone fa-message' /> <Text id='dyrejournal.visitor.create-new-sms-message'>Send new SMS message</Text>
                                    </button>
                                </>}
                            </div>

                        </div>
                    </div>
                    <div class={`d-flex flex-row justify-content-between px-0 py-0`}>
                        <div class='d-flex flex-column align-items-center w-100 p-0 border-top'>
                            <DyrejournalSaleListMini stores={this.props.stores} classNames='w-100' visitors={visitors} />
                        </div>
                    </div>


                    {/*
                        TODO: List out all medical records for this visitor.
                        TODO: List out all outstanding payments for this visitor.
                        TODO: List last 5 visits for this visitor.
                        TODO: List recommendations for this visitor.
                        TODO: Add fields for sms and email campaigns.
                        TODO: Add visit method (in person, phone, email, sms, etc.)
                    */}

                </div>
            </>)}
            {!(finalVisitors && finalVisitors.length > 0) && <>
                <div class={`d-flex flex-column justify-content-center w-100 my-1 text-muted ${classNames}`}>
                    {lastVisitors && lastVisitors.length > 0 && <>
                        <small><Text id='dyrejournal.sale-last-visitors'>Last visitors:</Text></small>
                        <div class='d-flex flex-wrap'>
                            {lastVisitors.map(e => <>
                                <div class={`d-flex flex-row justify-content-between px-0 py-0`} style='font-size: 0.9em; line-height: 1.2em;'>
                                    <button
                                        class='btn btn-sm btn-outline-primary rounded-pill mx-1 mt-1'
                                        style='font-size: 0.8em; line-height: 1.2em;'
                                        onClick={this.onClickCreateNewSaleForThisVisitor}
                                        data-id={e.id}
                                    >
                                        <i class='fa-duotone fa-user' /> {localUtil.showName(e, true)} - {e.cellphone}
                                    </button>
                                </div>
                            </>)}
                        </div>
                    </>}

                    <div class='w-100 text-center' style='font-size: 1.5em;'>
                        <i class='fa-solid fa-user' />
                    </div>
                    <div class='w-100 text-center' style='font-size: 1.0em;'>
                        <Text id='dyrejournal.visitor.not-selected'>No visitor selected</Text>
                    </div>
                </div>
            </>}
        </>);
    }
}

export default VisitorListMini;
