import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

import md5 from 'crypto-js/md5';

configure({ enforceActions: 'always' });

class ConventionStore extends LocalModel {
    constructor() {
        super('convention', {
            namePlural: 'conventions',
            namePluralReal: 'conventions',
            sort: '-startDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/conventions/',
                    params: {
                        limit: 15,
                        sort: '-startDate',
                    },
                },
                load: {
                    url: '/api/conventions/',
                    params: {},
                },
                save: {
                    url: '/api/conventions/',
                    params: {},
                },
                delete: {
                    url: '/api/conventions/',
                    params: {},
                },
            },
        });
    }

    @observable reloadTimer = null;

    @observable newConvention = {};

    @observable convention = {};

    @observable conventions = [];

    @observable tags = [];

    @observable jwtToken = null;

    @observable otp = '';

    @action
    setReloadTimer = (timer) => {
        if (this.reloadTimer) {
            clearTimeout(this.reloadTimer);
        }
        this.reloadTimer = timer;
    }

    @action
    deleteReloadTimer = () => {
        clearTimeout(this.reloadTimer);
    }

    async getJwt(convention) {
        const response = await util.fetchApi(`/api/conventions/jwt/${convention}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                this.updateKeyValue('jwtToken', response.data.jwtToken);
                this.updateKeyValue('otp', response.data.otp);
                return response;
            default:
                return 'Error';
        }
    }

    async checkOtp(convention, otp) {
        const response = await util.fetchApi(`/api/conventions/otp/${convention}/${otp}`, { method: 'GET' }, {});
        switch (response.status) {
            case 200:
                return response;
            default:
                return 'Error';
        }
    }

    async signUp(user, shootingClass) {
        const response = await util.fetchApi(`/api/conventions/signup/${this.convention.id}`, { method: 'PATCH' }, {
            id: this.convention.id,
            participants: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                class: shootingClass,
                md5: md5(JSON.stringify(user)).toString(),
            },
        });
        switch (response.status) {
            case 202:
                return response;
            default:
                return 'Error';
        }
    }

    async signUpAsAdmin(user) {
        // console.log('signUp', user);
        await this.save({
            id: this.convention.id,
            participants: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                md5: md5(JSON.stringify(user)).toString(),
            },
        });
    }

    async newRound(type) {
        if (!type) {
            return;
        }
        // const participants = [...this.convention.participants];
        const prevStageNumber = this.convention.stages.length;
        const prevStage = this.convention.stages[prevStageNumber - 1];
        const nextDate = new Date(prevStage?.date || this.convention.startDate || new Date());
        if (prevStage?.date) {
            nextDate.setMinutes(nextDate.getMinutes() + this.convention.stageInterval || 10);
        }

        const stageNumber = this.convention.stages.length + 1;

        const newStage = {
            type,
            stages: type === 'norgesfelt' ? 8 : null,
            name: `Lag ${stageNumber}`,
            date: nextDate,
            idx: stageNumber,
            stages: this.convention.stageSlots,
        };
        newStage.md5 = md5(JSON.stringify(this.convention.stages)).toString();

        const response = await this.save({
            id: this.convention.id,
            // [`participants${stageNumber}`]: participants,
            stages: newStage,
        });

        return response;
    }

    async newParticipant(user = {}, stageMd5 = null) {
        if (!user.id) {
            return;
        }

        let stageName = 'participants';
        let currentStage;
        if (stageMd5) {
            const stageIdx = this.convention.stages.findIndex((s) => s.md5 === stageMd5);
            if (stageIdx >= 0) {
                stageName = `participants${stageIdx + 1}`;
                currentStage = this.convention.stages[stageIdx];
            }
        }

        // console.log('this.convention.stages', this.convention.stages);
        // console.log('currentStage', currentStage);

        if (currentStage && currentStage.stages > 0) {
            const totalParticipants = this.convention[stageName].length;
            if (totalParticipants >= currentStage.stages) {
                alert('Max antall deltakere nådd');
                return;
            }
        }

        // Check if participant already exists
        const existingParticipant = this.convention[stageName].find((p) => p.id === user.id);
        if (existingParticipant) {
            alert('Deltakeren er allerede påmeldt');
            return;
        }

        const response = await this.save({
            id: this.convention.id,
            [stageName]: {
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                cellphone: user.cellphone,
                email: user.email,
                md5: md5(JSON.stringify(user)).toString(),
                date: util.isoDate(),
            },
        });
        return response;
    }
}

const store = new ConventionStore();
export default store;
