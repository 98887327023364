import { h, Component, Fragment } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import linkstate from 'linkstate';
import { route } from 'preact-router';
import { Text, Localizer, withText } from 'preact-i18n';
import md5 from 'crypto-js/md5';

@observer
class DecimalInput extends Component {
    state = {
        inputValue: '',
        parsedNumber: null
    };

    handleNumericInput = (event) => {
        const { onChange = () => {} } = this.props;
        let inputValue = event.target.value;

        // Replace all commas with dots
        inputValue = inputValue.replace(/,/g, '.');

        // Remove invalid characters (allow numbers, dots, and optional minus)
        let sanitizedValue = inputValue.replace(/[^0-9.-]/g, '');

        // Handle multiple dots
        const parts = sanitizedValue.split('.');
        if (parts.length > 2) {
            sanitizedValue = parts[0] + '.' + parts.slice(1).join('');
        }

        // Handle minus sign
        if (sanitizedValue.startsWith('-')) {
            sanitizedValue = '-' + sanitizedValue.replace(/-/g, '');
        } else {
            sanitizedValue = sanitizedValue.replace(/-/g, '');
        }

        // Update input value in state
        this.setState({ inputValue: sanitizedValue }, () => {
            // Parse the sanitized value after state update
            const parsedNumber = parseFloat(sanitizedValue);
            if (!isNaN(parsedNumber)) {
                this.setState({ parsedNumber });
            } else {
                this.setState({ parsedNumber: null });
            }
            onChange(event);
        });
    };

    componentDidMount() {
        const { value } = this.props;
        if (value) {
            this.setState({ inputValue: value });
        }
    }

    render() {
        const { inputValue, parsedNumber } = this.state;
        const {
            className = '',
            object = {},
            field,
            type,
            elementtype,
            extraDataset = {},
            placeholder,
        } = this.props;
        return (
            <div>
                {/* <div>Parsed Number: {parsedNumber}</div> */}
                <input
                    class={className}
                    name={field}
                    type='text'
                    pattern='^[0-9.,\-]*$'
                    inputmode='decimal'
                    onInput={this.handleNumericInput}
                    value={inputValue}
                    data-parsedvalue={parsedNumber}
                    data-id={object.id}
                    data-field={field}
                    data-datatype={type}
                    data-elementtype={elementtype}
                    {...extraDataset}
                    placeholder={placeholder}
                />
            </div>
        );
    }
}

export default DecimalInput;
