import { h, render, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { IntlProvider, Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import Pagination from '../../components/gui/pagination';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';
// import DyrejournalAllSalesPrint from '../../components/dyrejournal/allMedicinePrint';
// import DyrejournalAllSalesTable from '../../components/dyrejournal/allMedicineTable';

const LIMIT = 25;

import definitionNo from '../../languages/no.json';
import definitionEn from '../../languages/en.json';

const countryMap = {
    no: definitionNo,
    en: definitionEn,
    default: definitionEn,
};

@withText(props => ({
    saleTitle: <Text id='dyrejournal.sale-title'>Search for sale</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search for sale</Text>,
    deleteAreYouSure: <Text id='dyrejournal.sale-delete-are-you-sure'>Are you sure you want to delete this sale?</Text>,
}))
@observer
class AllMedicine extends Component {
	constructor(props) {
        super(props);
        this.state = {
            sections: fields.get('journal'),
            limit: LIMIT,
            // currentPage: 1,
            // formatting: true,
        };
        this.searchTimer = null;
        this.searchInputField = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { limit } = this.state;
        const { journalStore } = this.props.stores;
        await journalStore.loadMedicineUsage();
    }

    // onClickMedicineUsage = async e => {
    //     const { saleStore, userStore } = this.props.stores;
    //     const { activeStatus, formatting } = saleStore;
    //     const { id } = e.target.closest('button').dataset;

    //     // console.log('onClickPrintSale', e);
    //     const { user } = userStore;
    //     const { language = 'no' } = user;
    //     const languageDef = countryMap[language || 'default'];

    //     // Render Receipt component with data
    //     const receipt = <IntlProvider definition={languageDef}>
    //         <DyrejournalAllSalesPrint
    //             stores={this.props.stores}
    //             activeStatus={activeStatus}
    //             formatting={formatting}
    //         />
    //     </IntlProvider>;

    //     // Append to document
    //     const printDiv = document.createElement('div');
    //     printDiv.className = 'receipt';
    //     document.body.appendChild(printDiv);
    //     render(receipt, printDiv);

    //     // Trigger print dialog
    //     window.print();

    //     // Remove the appended div
    //     document.body.removeChild(printDiv);
    //     // Re-render the receipt component to reset it
    //     render(null, printDiv);
    // }

    changeCutoffDate = async e => {
        const { value: cutoffDate } = e.target;

        const { journalStore } = this.props.stores;
        journalStore.updateKeyValue('cutoffDate', cutoffDate);
        this.loadAll();
    }

    changeCutoffDateEnd = async e => {
        const { value: cutoffDateEnd } = e.target;
        const { journalStore } = this.props.stores;
        journalStore.updateKeyValue('cutoffDateEnd', cutoffDateEnd);
        this.loadAll();
    }

    clearCutoffDate = e => {
        const { journalStore } = this.props.stores;
        journalStore.updateKeyValue('cutoffDate', null);
        this.loadAll();
    }

    clearCutoffDateEnd = e => {
        const { journalStore } = this.props.stores;
        journalStore.updateKeyValue('cutoffDateEnd', null);
        this.loadAll();
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { focused, limit } = this.state;
        const {
            saleTitle,
            searchTitle,
        } = this.props;
        const { userStore, journalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { language = 'no' } = user;

        const { medicineUsage, cutoffDate, cutoffDateEnd } = journalStore;

        const apiServer = util.getApiServer();
        const jwtToken = util.getJwtToken();

        const searchHistory = util.get(`searchHistory-allMedicine`) || [];

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 0px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            {/* <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.visits-today'>Visits today</Text>
                            </div> */}
                            <div class={`d-flex flex-column mx-3 px-2 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                                <div class='d-flex flex-row justify-content-end mt-1'>
                                    <div class='input-group'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Fra
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDate ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDate}
                                            value={cutoffDate}
                                        />
                                        {cutoffDate && <div class='input-group-append'>
                                            <button class='btn btn btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDate}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>
                                    <div class='input-group ml-2'>
                                        <div class='input-group-prepend'>
                                            <span class='input-group-text rounded-pill-left'>
                                                <i class='fa-regular fa-timer mr-2'></i> Til
                                            </span>
                                        </div>
                                        <input
                                            class={`form-control rounded-${cutoffDateEnd ? 'none' : 'pill-right'}`}
                                            type='date'
                                            onInput={this.changeCutoffDateEnd}
                                            value={cutoffDateEnd}
                                        />
                                        {cutoffDateEnd && <div class='input-group-append'>
                                            <button class='btn btn-sm btn-danger rounded-pill-right' type='button' onClick={this.clearCutoffDateEnd}>
                                                <i class='fa-solid fa-circle-xmark'></i>
                                            </button>
                                        </div>}
                                    </div>

                                    {/* <button
                                        type='button'
                                        class='btn btn-sm btn-outline-primary rounded-pill ml-2'
                                        onClick={this.onClickMedicineUsage}
                                    >
                                        <nobr><i class='fa-duotone fa-print' /> <Text id='dyrejournal.print-medicines'>Print medicine usage</Text></nobr>
                                    </button> */}
                                </div>


                                <div class='table-responsive mt-3'>
                                    <table class='table table-sm table-hover table-striped'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>#</th>
                                                <th class='text-center' scope='col'>Name</th>
                                                <th class='text-center' scope='col'>Unit</th>
                                                <th class='text-center' scope='col'>no</th>
                                                <th class='text-center' scope='col'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medicineUsage.map((medicine, index) => {
                                                const totalAmount = medicine.entries.reduce((acc, entry) => acc + parseInt(entry.amountPerAnimal || 0, 10), 0);
                                                const { name, fullname, unit, no, amountPerAnimal } = medicine;
                                                return <tr>
                                                    <th scope='row'>{index + 1}</th>
                                                    <td>{fullname}</td>
                                                    <td class='text-center'>{unit}</td>
                                                    <td class='text-center'>{no}</td>
                                                    <td class='text-right'>{totalAmount}</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </div>

{/* <xmp>{JSON.stringify(medicineUsage, null, 4)}</xmp> */}

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default AllMedicine;
