import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

import RaceList from '../../components/race/list';
import RaceDetail from '../../components/race/detail';
import RaceClassTracking from '../../components/race/trackingMap';

import RaceResults from '../../components/race/results';


@observer
class RaceResultsDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    render() {
        const { raceId, raceClassId, drawerLevel } = this.props;
        const { userStore, raceStore, raceClassStore, raceClassContestantStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');
        const { race, isRaceAdmin } = raceStore;
        const { raceClass } = raceClassStore;
        const { raceClassContestants } = raceClassContestantStore;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100' style='padding-bottom: 100px;'>

                    {raceClassId && raceClass?.id ? <>

                        <RaceResults
                            stores={this.props.stores}
                            id={raceId}
                            raceClassId={raceClassId}
                            drawerLevel={drawerLevel}
                        />

                    </> : <>
                        <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                            <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                                <div class='w-100 d-flex flex-row justify-content-center'>
                                    <div class='box-line-add'>
                                        <i class='fa-solid fa-circle-question' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>);
    }
}

export default RaceResultsDrawer;
