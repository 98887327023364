import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../../lib/util';
import fields from '../../../lib/fields';

import FormFields from '../../../components/form/fields';
import Input from '../../../components/form/input';
import ImageScroller from '../../../components/imagescroller';
import InspectionStatus from '../../../components/inspection/status';
import GuiList from '../../../components/gui/list';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

@withText(props => ({
    titleText: <Text id='race-class-contestants.title'>Race Contestants</Text>,
}))
@observer
class RaceClassContestantList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    loadRaceClassContestants = async (props = this.props) => {
		const { raceStore, raceClassStore, raceClassContestantStore } = props.stores;
        const { id, raceClassId } = props;
		await raceClassStore.load({
            query: {
                id: raceClassId,
                race: id,
            },
        });
        const { race } = raceStore;
        await raceClassContestantStore.load({ query: {
            race: race.id,
            raceClass: raceClassId,
        }});
    }

    loadAll = async (props = this.props) => {
        await this.loadRaceClassContestants(props);
    }

    viewRaceClassContestant = e => {
        const { view } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMediumLarge } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('adminViewRaceClassContestant', {
            id,
            title: 'Admin View Contestant',
            height: drawerHeightMediumLarge,
            editFunction: this.editMediaViaView,
            callback: () => {
                this.loadAll();
            },
        }, drawerLevel + 1);
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            raceClassContestants = [],
            classNames = '',
            hideEmpty,
            title = titleText,
            editElement,
            showCevron,

            rightBoxField = 'distance',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = 'km',
            rightBoxFieldPostfixField = 'startDate',
            rightBoxFieldPostfixValue = e => util.formatDate(e, { year: 'numeric', locale: 'nb-NO' }, true),
            help,
            searchCallback = () => {},
        } = this.props;

        return (<>
            <div class={`my-4`}>
                <GuiList
                    stores={this.props.stores}
                    holdingStore={raceStore}
                    classNames={classNames}
                    view={this.viewRaceClassContestant}
                    title={`${title} (${raceClassContestants.length})`}
                    list={raceClassContestants}
                    qty={e => e.bib > 0 ? e.bib : undefined}
                    elTitle={e => <>{e.firstname} {e.lastname} <span class='text-muted font-weight-lighter'>- {e.sportsClub}</span></>}
                    elInfo1={e => <>
                        <span class='mr-2'>{e.approved ? <i class='fa-duotone fa-circle-check text-success' /> : <i class='fa-duotone fa-circle-check text-danger' />}</span>
                        {e.signupfeeAmount > 0 && <>
                            <span class='mr-2'>
                                {e.paidSignupfee ? <>
                                    <i class='fa-duotone fa-money-bill text-success' /> {e.vippsSignupfeeStatus}
                                </> : <>
                                    <i class='fa-duotone fa-money-bill text-secondary' />
                                </>}
                            </span>
                        </>}

                        {e.signuppaymentAmount > 0 && <>
                            <span class='mr-2'>
                                {e.paidSignup ? <>
                                    <i class='fa-duotone fa-money-bill text-success' /> {e.vippsSignupfeeStatus}
                                </> : <>
                                    <i class='fa-duotone fa-money-bill text-secondary' />
                                </>}
                            </span>
                        </>}

                        {e.restPaymentAmount > 0 && <>
                            <span class='mr-2'>
                                {e.paidRest ? <>
                                    <i class='fa-duotone fa-money-bill text-success' /> {e.vippsSignupfeeStatus}
                                </> : <>
                                    <i class='fa-duotone fa-money-bill text-secondary' />
                                </>}
                            </span>
                        </>}

                        {e.seedingPaymentAmount > 0 && <>
                            <span class='mr-2'>
                                {e.paidSeeding ? <>
                                    <i class='fa-duotone fa-money-bill text-success' /> {e.vippsSignupfeeStatus}
                                </> : <>
                                    <i class='fa-duotone fa-money-bill text-secondary' />
                                </>}
                            </span>
                        </>}

                        {e.hasVaccines ? <i class='fa-duotone ml-1 fa-syringe text-success' /> : <i class='fa-duotone fa-syringe text-secondary ml-1' />}
                        {e.hasSignedDeclaration ? <i class='fa-duotone ml-1 fa-file-signature text-success' /> : <i class='fa-duotone fa-file-signature text-secondary ml-1' />}
                        {e.hasDeliveredChipList ? <i class='fa-duotone ml-1 fa-microchip text-success' /> : <i class='fa-duotone fa-microchip text-secondary ml-1' />}
                        {e.hasReceivedBib ? <i class='fa-duotone ml-1 fa-hashtag text-success' /> : <i class='fa-duotone fa-hashtag text-secondary ml-1' />}
                        {e.hasReceivedTracker ? <i class='fa-duotone ml-1 fa-location-dot text-success' /> : <i class='fa-duotone fa-location-dot text-secondary ml-1' />}
                        {e.hasPreRaceCheck ? <i class='fa-duotone ml-1 fa-check-double text-success' /> : <i class='fa-duotone fa-check-double text-secondary ml-1' />}
                        {e.hasLicense ? <i class='fa-duotone ml-1 fa-id-card text-success' /> : <i class='fa-duotone fa-id-card text-secondary ml-1' />}
                        {e.rnb ? <i class='fa-duotone ml-1 fa-ribbon text-success' /> : <i class='fa-duotone fa-ribbon text-secondary ml-1' />}
                        {e.veteranRookie ? 'Veteran' : 'Rookie'}
                    </>}
                    elInfo2={e => <>
                        <span class='font-weight-lighter ml-2'><Text id='race-class-contestants.createdDate'>Created Date</Text></span>: {util.formatDate(e.createdDate, { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', locale: 'nb-NO' })}
                        <span class='font-weight-lighter ml-2'><Text id='race-class-contestants.licenseNumber'>License</Text></span>: {e.licenseNumber}
                    </>}
                    elInfo3={e => <>
                        {e.signupfeeAmount > 0 && <>
                            <span class='mr-2'>
                                <Text id='race-class-contestants.signupfee-title'>Signup fee Payment</Text> {e.signupfeeAmount} kr
                            </span>
                        </>}
                        {e.signuppaymentAmount > 0 && <>
                            <span class='mr-2'>
                                <Text id='race-class-contestants.signup-title'>Signup Payment</Text> {e.signuppaymentAmount} kr
                            </span>
                        </>}
                        {e.restPaymentAmount > 0 && <>
                            <span class='mr-2'>
                                <Text id='race-class-contestants.rest-title'>Rest Payment</Text> {e.restPaymentAmount} kr
                            </span>
                        </>}
                    </>}
                    hideEmpty={hideEmpty}
                    editElement={editElement}
                    showEmptyImage={false}
                    showCevron={showCevron}
                    rightBoxField={rightBoxField}
                    rightBoxFieldValue={rightBoxFieldValue}
                    rightBoxFieldPostfix={rightBoxFieldPostfix}
                    imgHeight='60px'
                    help={help}
                    searchCallback={searchCallback}
                    wrapperPropsFunc={e => ({
                        'data-bib': e.bib,
                    })}
                />
            </div>
        </>);
    }
}

export default RaceClassContestantList;
