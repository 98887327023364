import { observable, configure, action, computed } from 'mobx';
import LocalModel from './localModel';

import util from 'preact-util';
import { route } from 'preact-router';
import PubSub, { topics } from '../lib/pubsub';

configure({ enforceActions: 'always' });

class MediaStore extends LocalModel {
    constructor() {
        super('media', {
            namePlural: 'medias',
            namePluralReal: 'medias',
            sort: '-createdDate',
            limit: 100,
            api: {
                search: {
                    url: '/api/medias/',
                    params: {
                        limit: 15,
                        sort: '-createdDate',
                    },
                },
                load: {
                    url: '/api/medias/',
                    params: {},
                },
                save: {
                    url: '/api/medias/',
                    params: {},
                },
                delete: {
                    url: '/api/medias/',
                    params: {},
                },
            },
        });
    }

    @observable newMedia = {};

    @observable media = {};

    @observable medias = [];

    @observable races = [];

    @observable searchText = util.get('media-searchText') || null;;
    @observable race = util.get('media-race') || null;
    @observable cutoffDate = util.get('media-cutoffDate') || null;
    @observable cutoffDateEnd = util.get('media-cutoffDateEnd') || null;
    @observable publishedToNews = util.get('media-publishedToNews');
    @observable publishedToWeb = util.get('media-publishedToWeb');
    @observable publishedToFacebook = util.get('media-publishedToFacebook');
    @observable publishedToInstagram = util.get('media-publishedToInstagram');
    @observable publishedToOther = util.get('media-publishedToOther');

}

const store = new MediaStore();
export default store;
