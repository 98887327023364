import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import ImageScroller from '../../components/imagescroller';

import localUtil from '../../lib/util';

@observer
class NewsMenu extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
        };
        this.mainContainer = null;
    }

    // loadAll = async (props = this.props) => {
    //     const { id } = props;
    //     const { newsStore } = this.props.stores;
    //     if (id) {
    //         newsStore.updateKeyValue('news', {});
    //         await newsStore.load(id, false, { addData: ['tags'] });
    //     }
    // }

    editNews = () => {
		const { news, drawerLevel } = this.props;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('newsEdit', {
			height: drawerHeightMedium,
			id: news.id,
		}, drawerLevel + 1);
	}

    viewInstallation = e => {
        const { id } = e.currentTarget.dataset;
        const { appState } = this.props.stores;
        appState.toggleDrawer();
        route(`/installation/${id}`);
    };

    shareToFacebook = () => {
        const { news } = this.props;
        const { appState } = this.props.stores;
        const { userStore } = this.props.stores;
        const { user } = userStore;
        const { title, text, image } = news;
        const url = `${window.location.origin}/news/${news.id}`;
        const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${title}`;
        window.open(fbUrl, '_blank');
    }

    // componentDidMount() {
    //     this.loadAll();
    // }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const {
            page,
            artid,
            bib,
            showAnimations = true,
            news = {},
        } = this.props;
        const { sessionid, showMenu } = this.state;
        const { appState, userStore, newsStore, raceStore } = this.props.stores;
        const { mainView, subView, isDevelopment, path } = appState;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const { isRaceAdmin } = raceStore;

        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class={`d-flex flex-column mx-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} px-4 py-3`}>
                                <div class='d-flex flex-row justify-content-between'>
                                    {(isAdmin || isRaceAdmin) && <>
                                        <button
                                            class='btn btn-sm btn-primary rounded-pill mr-2'
                                            onClick={this.editNews}
                                        >
                                            <i class='fa-solid fa-edit mr-2' /> <Text id='news.edit' />
                                        </button>
                                    </>}
                                    <button
                                        class='btn btn-sm btn-outline-secondary rounded-pill mr-2'
                                        onClick={this.shareToFacebook}
                                    >
                                        <i class='fa-brands fa-square-facebook mr-2' /> <Text id='news.share-to-facebook'>Share to Facebook</Text>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default NewsMenu;
