import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import ImageScroller from '../../components/imagescroller';
import HelpText from '../../components/gui/helpText';
import Input from '../../components/form/input';

import localUtil from '../../lib/util';

function participantExists(participants, key, value) {
    return participants.some(participant => participant[key] === value);
}

@observer
class ConventionSignup extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    updateField = async (e) => {
        const { id, md5, stage } = this.props;
        const { value, name, type } = e.target;
        const { field } = e.target.dataset;
        // console.log('updateClass', value);
        const { conventionStore } = this.props.stores;
        await conventionStore.editArrayObject({
            field,
            key: name,
            value,
            id,
            md5,
        });
    }

    render() {
        const {
            signedUp,
        } = this.state;
        const { id, md5, stage } = this.props;
        const { appState, userStore, conventionStore } = this.props.stores;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { convention } = conventionStore;
        const currentStage = convention.stages.find(s => s.md5 === md5);
        const stageFieldName = `stages`;

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`font-weight-lighter px-3 mt-3 box-header w-100 position-relative`}>
                        {convention.title}
                    </div>
                    {/* id: {id}<br /> */}
                    {/* md5: {md5}<br /> */}
                    {/* stage: {stage}<br /> */}
                    {/* participantFieldName: {participantFieldName}<br /> */}
                    {/* <xmp>{JSON.stringify(currentStage, null, 2)}</xmp> */}
                    {/* <xmp>{JSON.stringify(currentParticipant, null, 2)}</xmp> */}

                    <div class='w-100 d-flex flex-column'>
                        <div class='px-0 box-header-info d-flex flex-column'>
                            <div class={`d-flex flex-column mt-3 mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <div class='d-flex flex-column'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.stage'>Stage</Text>
                                    </span>
                                    <span>
                                        {currentStage.name}
                                    </span>
                                </div>
                                <div class='d-flex flex-column mt-3'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.choose-class'>Choose stage type</Text>
                                    </span>
                                    <select
                                        class='form-control rounded-pill'
                                        onInput={this.updateField}
                                        name={'type'}
                                        value={currentStage.type}
                                        data-field={stageFieldName}
                                    >
                                        <option value=''>-- Choose type --</option>
                                        <option value='nais'>NAIS</option>
                                        <option value='hurtig'>Hurtig</option>
                                        <option value='hurtig-half'>Hurtig halv serie</option>
                                        <option value='standard'>Standard</option>
                                        <option value='felt'>Felt</option>
                                        <option value='norgesfelt'>Norgesfelt</option>
                                        <option value='t-96'>T-96</option>
                                    </select>
                                </div>
                                <div class='d-flex flex-column mt-3'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.stands'>Number of stands</Text>
                                    </span>
                                    <input
                                        class='form-control rounded-pill'
                                        onInput={this.updateField}
                                        name={'stages'}
                                        value={currentStage.stages}
                                        type='number'
                                        data-field={stageFieldName}
                                    />
                                </div>
                                <div class='d-flex flex-column mt-3'>
                                    <span class='text-muted font-weight-light'>
                                        <Text id='convention.stage-time'>Stage date and time</Text>
                                    </span>
                                    <input
                                        class='form-control rounded-pill'
                                        onInput={this.updateField}
                                        name={'date'}
                                        value={util.isoDate(currentStage.date)}
                                        type='datetime-local'
                                        data-field={stageFieldName}
                                    />
                                </div>
                                <div class='d-flex justify-content-between mt-3'>
                                    <div class='flex-grow-1 pl-2 pr-2'>
                                        <span class='text-muted font-weight-light'>
                                            <Text id='convention.stage-closed'>Stage closed</Text>
                                        </span>
                                        {/* <xmp>{JSON.stringify(currentStage.stageClosed, null, 2)}</xmp> */}
                                    </div>
                                    <div class='custom-control custom-switch custom-switch-lg d-flex justify-content-end mr-3'>
                                        <input
                                            type='checkbox'
                                            id={`stageClosedInputToggle`}
                                            class='inputForm custom-control-input'
                                            name={'stageClosed'}
                                            value={currentStage.stageClosed == 1 ? 0 : 1}
                                            data-field={stageFieldName}
                                            onInput={this.updateField}
                                            checked={currentStage.stageClosed == 1 ? 'checked' : ''}
                                            autocomplete={'off'}
                                        />
                                        <label class='custom-control-label pl-2' for={`stageClosedInputToggle`} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <SimpleBlog stores={this.props.stores} {...this.props} /> */}
                </div>
            </div>
        </>);
    }
}

export default ConventionSignup;
