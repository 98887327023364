import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { toJS } from 'mobx';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import localUtil from '../../lib/util';

const HEIGHT = 80; // 60 minutes per hour * 1.2px per minute
const HEIGHT_SCALE = HEIGHT / 60;

const AVAILABILITY_COLOR = '#ffffff';
const NO_AVAILABILITY_COLOR = '#e0e0e0';
const BORDER_COLOR = '#d0d0d0';

@observer
class CalendarHourGrid extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            isDragging: false,
        };
    }

    loadAll = () => {
        const { calendarStore, calendarEventStore } = this.props.stores;
        calendarStore.getHoursInDay();
    }

    editcalendarEvent = e => {
        e.stopPropagation();
        e.preventDefault();
        const { id } = e.target.closest('button').dataset;
		const { drawerLevel = 1, callback = () => {} } = this.props;
		const { appState, calendarEventStore } = this.props.stores;
        const { calendarEvent, visitors, animals } = calendarEventStore;
		const { drawerHeightLarge } = appState;
		appState.openDrawer('editCalendarEvent', {
			height: drawerHeightLarge,
            calendarType: calendarEvent.calendarType,
            calendarId: calendarEvent.calendarId,
            startDate: calendarEvent.start,
			id,
            callback: event => {
                console.log('editcalendarEvent.callback');
                // callback(event);
            },
		}, drawerLevel + 3);
	}

    viewEvent = e => {
        e.stopPropagation();
        e.preventDefault();

        const { showViewEvent = true } = this.props;
        if (!showViewEvent) {
            return;
        }

        const { id } = e.target.closest('.calendar-event').dataset;

        const { appState, calendarStore } = this.props.stores;
        const { eventId } = calendarStore
        if (eventId) {
            return;
        }

        const { drawerLevel = 1, callback = () => {} } = this.props;
        const { drawerHeightMediumLarge } = appState;

        appState.openDrawer('viewCalendarEvent', {
            height: drawerHeightMediumLarge,
            id: parseInt(id, 10),
            editFunction: this.editcalendarEvent,
            callback: event => {
                callback(event);
                appState.toggleDrawer(false, drawerLevel + 2);
                calendarStore.resetDragging();
            },
        }, drawerLevel + 2);
    }

    selectTime = e => {
        const { isDragging } = this.state;
        if (isDragging) {
            return;
        }
        const { enableSelection } = this.props;
        if (!enableSelection) {
            return;
        }
        e.stopPropagation();
        e.preventDefault();
        const { hour, date } = e.target.closest('.hour-line').dataset;
    // console.log('selectTime', { hour, date })
        const rect = e.currentTarget.getBoundingClientRect();
        const y = e.clientY - rect.top;  // y position within the element


        // Round to the nearest 15 minutes
        const roundedMinutes = Math.round((y / HEIGHT_SCALE) / 15) * 15;
        // Zero pad minutes if needed
        let paddedMinute = roundedMinutes < 10 ? `0${roundedMinutes}` : roundedMinutes;
        let hourInt = parseInt(hour, 10);
        // console.log('y', y, 'roundedMinutes', roundedMinutes, 'hourInt', hourInt)
        if (roundedMinutes === 60) {
            hourInt += 1;
            // console.log('y', y, 'yRelative', yRelative, 'minScale', minScale, 'roundedMinutes', roundedMinutes, 'hourInt', hourInt)
            paddedMinute = '00';
        }
        const paddedHour = hourInt < 10 ? `0${hourInt}` : hourInt;

        const { appState, saleStore, calendarStore } = this.props.stores;
        // const { calendarDate } = calendarStore;
        const { calendarDate } = this.props;
        // calendarStore.resetDragging();

        const startDate = new Date(`${util.isoDate(calendarDate, false, false, true)}T${paddedHour}:${paddedMinute}:00`);
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 15);
        // console.log(`paddedHour: ${paddedHour}, paddedMinute: ${paddedMinute}`)
        // console.log(`startDate: ${startDate}, endDate: ${endDate}`)
        const { calendarSelectDateTime, calendarId, callback = () => {} } = this.props;

        if (calendarSelectDateTime) {
            calendarSelectDateTime({
                start: startDate,
                end: endDate,
                calendarId,
            });
            return;
        }

        const { drawerLevel = 1, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        const { newSale } = saleStore;
        const { animals } = newSale;
        appState.openDrawer('newCalendarEvent', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            calendarId,
            calendarType: 'booking',
            animals,
            startDate: startDate,
            endDate: endDate,
            skipAnimal,
            callback: async booking => {
                // console.log('newCalendarEvent callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                callback(booking);
                calendarStore.resetDragging();
            },
		}, drawerLevel + 1);

    }

    handleMouseDown = e => {
        // console.log('handleMouseDown');
        const  { calendarId, showPullDownHandle, showViewEvent = true, enableSelection = true } = this.props;
        const { calendarStore } = this.props.stores;
        const { selectionStartDate, selectionEndDate, selectionCalendarId } = calendarStore;
        const selectionDiv = e.target.closest('.selection-calendar-event')
        const pullDownHandle = e.target.closest('.pull-down-handle');
        const eventDiv = e.target.closest('.calendar-event');

// console.log('handleMouseDown', { showViewEvent, enableSelection, selectionStartDate, selectionEndDate, selectionCalendarId, selectionDiv, pullDownHandle, eventDiv })

        if (showPullDownHandle && pullDownHandle) {
            return this.handleMouseDownPullDownHandle(e);
        }
        if (selectionDiv) {
            return this.clickSelection(e);
        }
        if (eventDiv) {
            if (showViewEvent) {
                return this.viewEvent(e);
            }
            return this.handleMouseDownInsideEvent(e);
        }

        if (!enableSelection) {
            return;
        }
// console.log('handleMouseDown: isDragging');
        this.setState({
            isDragging: false,
        });

        const { hour, date } = e.target.closest('.hour-line').dataset;
// console.log('handleMouseDown', { hour, date })
        const rect = e.target.closest('.hour-line').getBoundingClientRect();
        const y = e.clientY - rect.top;  // y position within the element
        // Round to the nearest 15 minutes
        let roundedMinutes = Math.round((y / HEIGHT_SCALE) / 15) * 15;
        // Zero pad minutes if needed
        let paddedMinute = roundedMinutes < 10 ? `0${roundedMinutes}` : roundedMinutes;
        let hourInt = parseInt(hour, 10);
        if (paddedMinute === 60) {
            hourInt += 1;
            paddedMinute = '00';
            roundedMinutes = 0;
        }

        // const { calendarDate } = calendarStore;
        const { calendarDate } = this.props;

        const paddedHour = hourInt < 10 ? `0${hourInt}` : hourInt;

        // console.log('handleMouseDown', { hour, paddedHour, paddedMinute })
        const startDate = new Date(`${util.isoDate(calendarDate, false, false, true)}T${paddedHour}:${paddedMinute}:00`);
        const endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 15);

// console.log('handleMouseDown', { startDate, endDate, calendarId })

        calendarStore.setSelectionStartDate(startDate);
        calendarStore.setSelectionEndDate(endDate);
        calendarStore.setSelectionCalendarId(calendarId);
        calendarStore.setDragging(true);
        calendarStore.setStartY(roundedMinutes * HEIGHT_SCALE);
        calendarStore.setStartYAbs(e.clientY * HEIGHT_SCALE);
        calendarStore.setEndY(e.clientY * HEIGHT_SCALE);
    }

    handleMouseDownInsideEvent = e => {
        e.stopPropagation();
        e.preventDefault();
        // console.log('handleMouseDownInsideEvent', e);
    }

    handleMouseMove = (e) => {
        this.setState({
            isDragging: true,
        });
        const { calendarStore, calendarEventStore } = this.props.stores;
        const {
            calendarDate,
            colors,
            calendarHours,
            startY,
            startYAbs,
            endY,
            selectionStartDate,
            selectionEndDate,
            dragging,
            eventId,
            eventEnd,
        } = calendarStore;

        if (dragging) {
            calendarStore.setEndY(e.clientY * HEIGHT_SCALE);
            const selectHeight = endY - startYAbs < 0 ? -Math.abs(endY - startYAbs) : Math.abs(endY - startYAbs);

            // let roundedMinutes = Math.round((y / HEIGHT_SCALE) / 15) * 15;
            // console.log('handleMouseMove', { dragging, startY, startYAbs, endY, eventId, eventEnd, selectHeight })

            const roundedSelectHeight = Math.round((selectHeight / HEIGHT_SCALE) / 15) * 15;

            const endDate = new Date(selectionStartDate);
            endDate.setMinutes(endDate.getMinutes() + roundedSelectHeight);
            calendarStore.setSelectionEndDate(endDate);

            if (eventId) {
                // const rect = e.target.closest('.hour-line').getBoundingClientRect();
                // const y = e.clientY - rect.top;  // y position within the element
                // Round to the nearest 15 minutes
                // let roundedMinutes = Math.round((y / HEIGHT_SCALE) / 15) * 15;

                const roundedMinutesDiff = Math.round((selectHeight / HEIGHT_SCALE));

                // console.log('handleMouseMove', { dragging, startY, startYAbs, endY, eventId, eventEnd, selectHeight, roundedSelectHeight, roundedMinutesDiff })

                const event = calendarEventStore.calendarEvents.find(e => e.id === eventId);
                const end = new Date(eventEnd);
                end.setMinutes(end.getMinutes() + roundedMinutesDiff);
                // end.setMinutes(roundedMinutes);
                calendarEventStore.updateField(eventId, 'end', end);
            }
        }
    }

    handleMouseUp = e => {
        const { isDragging } = this.state;
        if (isDragging) {
            setTimeout(() => {
                this.setState({
                    isDragging: false,
                });
            }, 350);
        }
        const { calendarStore, calendarEventStore } = this.props.stores;
        calendarStore.setDragging(false);

        const {
            selectionStartDate,
            selectionEndDate,
            selectionCalendarId,
            startYAbs,
            endY,
            eventId,
            eventEnd,
        } = calendarStore;
        const { calendarSelectDateTime, calendarId, enableSelection } = this.props;
        // console.log('handleMouseUp', { enableSelection, calendarSelectDateTime, selectionStartDate, selectionEndDate, selectionCalendarId, startYAbs, endY, eventId, eventEnd })
        if (enableSelection && calendarSelectDateTime) {
            calendarSelectDateTime({
                start: selectionStartDate,
                end: selectionEndDate,
                calendarId: selectionCalendarId,
            });
            // calendarStore.resetDragging();
            // calendarStore.setSelectionStartDate(null)
            // calendarStore.setSelectionEndDate(null)
            return;
        }
        if (eventId) {
            e.preventDefault();
            e.stopPropagation();
            const selectHeight = endY - startYAbs < 0 ? -Math.abs(endY - startYAbs) : Math.abs(endY - startYAbs);
            const roundedSelectHeight = Math.round((selectHeight / HEIGHT_SCALE) / 15) * 15;

            const event = calendarEventStore.calendarEvents.find(e => e.id === eventId);
            const end = new Date(eventEnd);
            end.setMinutes(end.getMinutes() + roundedSelectHeight);
            calendarEventStore.save({
                end,
            }, eventId);
            calendarEventStore.updateField(eventId, 'end', end);
            setTimeout(() => {
                calendarStore.setEventId(null);
                calendarStore.setEndOfEvent(null);
            }, 500);
        }
    }

    handleMouseDownPullDownHandle = e => {
        e.stopPropagation();
        e.preventDefault();
        const { calendarStore, calendarEventStore } = this.props.stores;
        // console.log('handleMouseDownPullDownHandle');
        calendarStore.setDragging(true);
        // calendarStore.setStartYAbs(e.clientY);
        // calendarStore.setEndY(e.clientY);

        calendarStore.setStartYAbs(e.clientY * HEIGHT_SCALE);
        calendarStore.setEndY(e.clientY * HEIGHT_SCALE);



        const pullDownHandle = e.target.closest('.pull-down-handle');
        const { id } = pullDownHandle.dataset;
        const eventId = parseInt(id, 10);
        calendarStore.setEventId(eventId);
        const event = calendarEventStore.calendarEvents.find(e => e.id === eventId);
        calendarStore.setEndOfEvent(event.end);

        return;
        calendarStore.setEndY(e.clientY + 15 * HEIGHT_SCALE);

        // Round to the nearest 15 minutes
        // const roundedMinutes = Math.round((y / HEIGHT_SCALE) / 15) * 15;
        // // Zero pad minutes if needed
        // let paddedMinute = roundedMinutes < 10 ? `0${roundedMinutes}` : roundedMinutes;
        // let hourInt = parseInt(hour, 10);
        // // console.log('y', y, 'roundedMinutes', roundedMinutes, 'hourInt', hourInt)
        // if (roundedMinutes === 60) {
        //     hourInt += 1;
        //     // console.log('y', y, 'yRelative', yRelative, 'minScale', minScale, 'roundedMinutes', roundedMinutes, 'hourInt', hourInt)
        //     paddedMinute = '00';
        // }
        // const paddedHour = hourInt < 10 ? `0${hourInt}` : hourInt;

        // calendarStore.setStartY(roundedMinutes * HEIGHT_SCALE);
        // calendarStore.setStartYAbs(e.clientY * HEIGHT_SCALE);
        // calendarStore.setEndY(e.clientY * HEIGHT_SCALE);

    }

    clickSelection = e => {
        e.stopPropagation();
        e.preventDefault();
        // console.log('clickSelection');
        const { calendarStore, appState, saleStore } = this.props.stores;
        const { selectionStartDate, selectionEndDate, selectionCalendarId } = calendarStore;
        const { calendarSelectDateTime, calendarId } = this.props;
        if (calendarSelectDateTime) {
            // console.log('calendarSelectDateTime', { selectionStartDate, selectionEndDate, selectionCalendarId })
            calendarSelectDateTime({
                start: selectionStartDate,
                end: selectionEndDate,
                calendarId: selectionCalendarId,
            });
            return;
        }
        const { drawerLevel = 1, callback = () => {}, skipAnimal } = this.props;
        const { drawerHeightLarge } = appState;
        const { newSale } = saleStore;
        const { animals } = newSale;
        appState.openDrawer('newCalendarEvent', {
			height: drawerHeightLarge,
            isNew: true,
            hideAdmin: true,
            calendarType: 'booking',
            calendarId: selectionCalendarId,
            animals,
            startDate: selectionStartDate,
            endDate: selectionEndDate,
            skipAnimal,
            callback: async booking => {
                // console.log('newCalendarEvent callback', booking);
                appState.toggleDrawer(false, drawerLevel + 1);
                callback(booking);
                calendarStore.resetDragging();
            },
		}, drawerLevel + 1);
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.calendarDate !== this.props.calendarDate) || (nextProps.calendarId !== this.props.calendarId)) {
            this.loadAll();
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const  {
            idx,
            calendarId,
            availabilityHoursStart = 0,
            availabilityMinutesStart = 0,
            availabilityHoursEnd = 0,
            availabilityMinutesEnd = 0,
            useCalendarAvailability,
            showPullDownHandle,
            pulldownHandleId,
            color: colorProp,
            backgroundColor: backgroundColorProp,
            calendarDate,
            minWidth = 215,
            displayCustomerInfo,
        } = this.props;
        const { calendarStore, calendarEventStore, userStore } = this.props.stores;
        const {
            colors,
            calendarHours,
            startY,
            startYAbs,
            endY,
            selectionStartDate,
            selectionEndDate,
            isToday,
            workhourStart,
            workhourEnd,
        } = calendarStore;
        const workhourStartInt = useCalendarAvailability ? parseInt(availabilityHoursStart, 10) : parseInt(availabilityHoursStart || workhourStart, 10);
        const workhourEndInt = useCalendarAvailability ? parseInt(availabilityHoursEnd || workhourEnd, 10) : parseInt(availabilityHoursEnd || workhourEnd, 10);

        const today = new Date();
        const todayHour = today.getHours();
        const todayMinutes = today.getMinutes();
        const todayHourStart = new Date(today);
        todayHourStart.setHours(todayHour, 0, 0, 0);

        const selectTop = Math.min(startY, endY);
        const selectHeight = endY - startYAbs < 0 ? 0 : Math.abs(endY - startYAbs);
        // const roundedSelectHeight = Math.round(selectHeight / 15) * 15 * HEIGHT_SCALE;
        const roundedSelectHeight = Math.round((selectHeight) / 15) * 15 * HEIGHT_SCALE;

        const selectColor = colors[colors.length - 1];

        const { calendarEvents, recurringList, animals, visitors } = calendarEventStore;

        // const { user = {}, isAdmin, isTester, isVeterinary } = userStore;
        // const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const color = colors[idx].color;
        let backgroundColor = colors[idx].backgroundColor;
        // const dragBackgroundColor = colors[idx].dragBackgroundColor;
        // const borderColor = colors[idx].borderColor;
        // console.log(calendarEvents.map(e => `${e.start} - ${e.end}: ${e.title}`));

        return (<>
            <div
                class='no-select'
                onMouseDown={this.handleMouseDown}
                onMouseMove={this.handleMouseMove}
                onMouseUp={this.handleMouseUp}
            >

{/* startY: {startY}<br />
startYAbs: {startYAbs}<br />
endY: {endY}<br />
selectHeight: {selectHeight}<br />
roundedSelectHeight: {roundedSelectHeight}<br /> */}
{/* <xmp>{JSON.stringify({ visitors, animals }, null, 2)}</xmp> */}

                {calendarHours.map((todayHour, idx) => {
                    const hourInt = todayHour.getHours();
                    const hour = new Date(calendarDate);
                    hour.setHours(hourInt, 0, 0, 0);
                    const events = calendarEventStore.getEvents(hour, calendarId);
                    const selectionEvent = calendarStore.getSelectionEvent(hour, calendarId);
                    const isInPast = hour < todayHourStart;
                    const isWorkHour = (hourInt >= workhourStartInt) && (hourInt < workhourEndInt);

                    return (<>
                        <div class={`d-flex flex-row`} style={`min-width: ${minWidth}px;`}>
                            <div class='d-flex flex-row justify-content-end' style='width: 30px;'>
                                <small class='text-muted font-weight-light font-style-narrow'>
                                    {util.isoTime(hour)}
                                </small>
                            </div>
                            <div
                                class='flex-fill ml-1 border-top position-relative hour-line'
                                style={`
                                    height: ${HEIGHT}px;
                                    // opacity: ${isInPast ? 0.8 : 1};
                                    background-color: ${isWorkHour ? AVAILABILITY_COLOR : NO_AVAILABILITY_COLOR};
                                    border-color: ${BORDER_COLOR} !important;
                                `}
                                onClick={this.selectTime}
                                data-hour={hour.getHours()}
                                data-date={util.isoDate(calendarDate, false, false, true)}
                            >
                                {availabilityMinutesStart > 0 && hourInt === workhourStartInt && <>
                                    <div
                                        class='position-absolute'
                                        style={`
                                            top: 0px;
                                            left: 0;
                                            height: ${availabilityMinutesStart * HEIGHT_SCALE}px;
                                            min-height: ${availabilityMinutesStart * HEIGHT_SCALE}px;
                                            background-color: ${NO_AVAILABILITY_COLOR};
                                            width: 100%;
                                            z-index: 1;
                                        `}
                                    ></div>
                                </>}
                                {availabilityMinutesEnd > 0 && hourInt === workhourEndInt && <>
                                    <div
                                        class='position-absolute'
                                        style={`
                                            top: 0;
                                            left: 0;
                                            height: ${HEIGHT - (availabilityMinutesEnd * HEIGHT_SCALE)}px;
                                            min-height: ${HEIGHT - (availabilityMinutesEnd * HEIGHT_SCALE)}px;
                                            background-color: ${AVAILABILITY_COLOR};
                                            width: 100%;
                                            z-index: 1;
                                        `}
                                    ></div>
                                </>}
                                {isToday && hourInt === todayHour && <>
                                    <div
                                        class='position-absolute'
                                        style={`
                                            top: ${todayMinutes}px;
                                            left: 0;
                                            height: 2px;
                                            min-height: 2px;
                                            background-color: red;
                                            width: 100%;
                                            z-index: 1;
                                        `}
                                    ></div>
                                </>}

                                {events.map((event, eidx) => {
                                    const top = (new Date(event.start).getMinutes() / 60) * 60 * HEIGHT_SCALE;
                                    const diff = new Date(event.end) - new Date(event.start);
                                    const height = (diff / 1000 / 60 / 60) * 60 * HEIGHT_SCALE;
                                    let bgColor = backgroundColor;
                                    if (event.backgroundColor) {
                                        bgColor = event.backgroundColor;
                                    }

                                    let currentAnimal = {};
                                    let currentVisitor = {};
                                    if (event.animals && event.animals.length > 0) {
                                        const animalId = event.animals[0].id;
                                        const foundAnimal = animals.find(a => a.id === animalId);
                                        currentAnimal = { ...foundAnimal };
console.log('animalId', animalId)
console.log('animals', animals)
console.log('currentAnimal', currentAnimal)
                                        if (currentAnimal) {
                                            const foundVisitor = visitors.find(v => v.id === currentAnimal.owner);
                                            currentVisitor = { ...foundVisitor };
                                        }
                                    }

                                    // if (event.overlapping) {
                                    //     console.log('overlapping', `eidx: ${event.overlappingIdx}: ${event.id}: ${util.isoTime(event.start)}-${util.isoTime(event.end)}: ${event.title}`, `${event.overlappingEvents.map(e => `${e.id}: ${util.isoTime(e.start)} - ${util.isoTime(e.end)}: ${e.title}`)}`)
                                    // }
                                    // console.log(event);

                                    return (<>
                                        <div
                                            class='position-absolute overflow-hidden pl-1 calendar-event d-flex flex-column'
                                            style={`
                                                top: ${top}px;
                                                ${event.overlapping ? ((event.overlappingIdx % 2 === 0) ? 'left: 0' : 'right: 0') : 'left: 0'};
                                                height: ${height}px;
                                                min-height: ${HEIGHT / 4}px;
                                                background-color: ${event.hasArrived || event.arrivedTime ? localUtil.shiftColor(bgColor, 0, -70, 20) : bgColor};
                                                color: ${color};
                                                opacity: 0.8;
                                                line-height: 0.8em;
                                                width: ${event.overlapping ? '50%' : '100%'};
                                                border-left: 3px solid ${color};
                                                border-top: 1px solid ${color};
                                                z-index: 10000;
                                            `}
                                            data-id={event.id}
                                            onClick={this.viewEvent}
                                        >
                                            <small class='font-weight-normal font-style-narrow'>
                                                {event.arrivedTime ? <>
                                                    {event.receivedTime ? <>
                                                        {event.doneTime ? <>
                                                            <i class='fa-duotone fa-check-circle text-success mr-1' />
                                                        </> : <>
                                                            <i class='fa-duotone fa-user-doctor text-primary mr-1' />
                                                        </>}
                                                    </> : <>
                                                        <i class='fa-solid fa-hourglass-clock fa-beat-fade text-warning mr-1' />
                                                    </>}
                                                </> : <>
                                                    {event.hasArrived && <i class='fa-duotone fa-check-circle text-success mr-1' />}
                                                </>}



                                                {event.skipReminders && <i class='fa-duotone fa-bell-slash text-danger mr-1' />}
                                                {event.isRecurring && <i class='fa-duotone fa-repeat mr-1' />}
                                                <span class='font-style-narrow'>{util.isoTime(event.start)}: {event.title}</span>{event.body && <>: <span class='font-weight-light'>{event.body}</span></>}
                                                {displayCustomerInfo && <>
                                                    {currentVisitor && <>
                                                        <br />
                                                        <span class='font-weight-light'>
                                                            {currentVisitor.firstname} {currentVisitor.lastname}<br />
                                                            {currentVisitor.address}, {currentVisitor.postalcode} {currentVisitor.place}<br />
                                                            {currentVisitor.cellphone}
                                                        </span>
                                                    </>}
                                                </>}
                                                {/* {event.overlappingEvents && event.overlappingEvents.map(e => `${e.start} - ${e.end}: ${e.title}`)} */}
                                            </small>
                                            {showPullDownHandle && pulldownHandleId === event.id ? <>
                                                <div
                                                    class='pull-down-handle position-absolute text-center'
                                                    style={`
                                                        content: "";
                                                        display: block;
                                                        margin: auto;
                                                        width: 20px;
                                                        height: 10px;
                                                        font-size: 10px;
                                                        line-height: 10px;
                                                        bottom: 0px;
                                                        left: 50%;
                                                        transform: translateX(-50%);
                                                        cursor: ns-resize;
                                                    `}
                                                    data-id={event.id}
                                                >
                                                    <i class="fas fa-grip-lines"></i>
                                                </div>
                                            </> : null}
                                        </div>
                                    </>);
                                    lastOneIsOverlapping = event.overlapping;
                                })}
                                {selectionEvent && <>
                                    <div
                                        class='position-absolute overflow-hidden pl-2 selection-calendar-event'
                                        data-selection={true}
                                        style={`
                                            top: ${selectTop}px;
                                            left: 0;
                                            height: ${roundedSelectHeight}px;
                                            min-height: ${HEIGHT / 4}px;
                                            background-color: ${selectColor.backgroundColor};
                                            color: ${selectColor.color};
                                            opacity: 0.5;
                                            line-height: 0.8em;
                                            width: 100%;
                                            border-left: 3px solid ${selectColor.color}};
                                            border-top: 1px solid ${selectColor.color};
                                            z-index: 10000;
                                        `}
                                        // onMouseDown={localUtil.captureEvents}
                                        // onClick={localUtil.captureEvents}
                                    >
                                        <small>
                                            {util.isoTime(selectionEvent.start)}-{util.isoTime(selectionEvent.end)}: {selectionEvent.title}
                                        </small>

                                        {/* <button type='button' class='btn btn-sm btn-success rounded-pill' onClick={this.clickSelection}>
                                            choose
                                        </button> */}

                                    </div>

                                </>}
                            </div>
                        </div>
                    </>);
                })}
            </div>
        </>);
    }
}

export default CalendarHourGrid;
