import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import { stringify, parse } from 'flatted';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

function hasJournalWithDiagnoses(visit) {
    // Check if the visit has journals and it's an array
    if (!visit.journals || !Array.isArray(visit.journals)) {
       return false;
    }
    // Use the some() method to check if any journal has diagnoses
    return visit.journals.some(
        (journal) =>
            Array.isArray(journal.diagnoses) && journal.diagnoses.length > 0
    );
}

function hasJournalWithMedicines(visit) {
    // Check if the visit has journals and it's an array
    if (!visit.journals || !Array.isArray(visit.journals)) {
       return false;
    }
    // Use the some() method to check if any journal has medicines
    return visit.journals.some(
        (journal) =>
            Array.isArray(journal.medicine) && journal.medicine.length > 0
    );
}

function getInitials(name) {
    if (!name) return '';
    return name
        .split(' ')  // Split the name into an array of words
        .filter(word => word.length > 0)  // Filter out any empty strings
        .map(word => word[0].toUpperCase())  // Map each word to its first character and convert to uppercase
        .join('');  // Join the initials into a single string
}

function visitorHasEmail(visitors) {
    if (!Array.isArray(visitors)) {
        return false;
    }
    return visitors.some(visitor => visitor.email);
}

function visitorHasCellphone(visitors) {
    if (!Array.isArray(visitors)) {
        return false;
    }
    return visitors.some(visitor => visitor.cellphone);
}

function visitorHasEmailOrCellphone(visitors) {
    if (!Array.isArray(visitors)) {
        return false;
    }
    return visitors.some(visitor => visitor.email || visitor.cellphone);
}

function visitorHasValidEmail(visitors) {
    if (!Array.isArray(visitors)) {
        return false;
    }
    return visitors.some(visitor => util.validateEmail(visitor.email));
}

function validateCellphone(cellphone) {
    if (!cellphone) {
        return false;
    }
    // Check for valid Norwegian cellphone number
    return /^(\+\d{2})?[489]\d{7}$/.test(cellphone);
}

function visitorHasValidCellphone(visitors) {
    if (!Array.isArray(visitors)) {
        return false;
    }
    return visitors.some(visitor => validateCellphone(visitor.cellphone));
}

@withText(props => ({
    saleTitle: <Text id='dyrejournal.sale-title'>Search for sale</Text>,
    searchTitle: <Text id='dyrejournal.search-title'>Search for sale</Text>,
    deleteAreYouSure: <Text id='dyrejournal.sale-delete-are-you-sure'>Are you sure you want to delete this sale?</Text>,
}))
@observer
class AllVisitsTable extends Component {
	constructor(props) {
        super(props);
        this.state = {
            displayAnimaliaError: {},
            displayFikenError: {},
            animaliaLoading: {},
            fikenLoading: {},
            fikenSent: {},
            tripletexLoading: {},
            tripletexError: {},
            tripletexSent: {},
            saleUserUpdating: {},
            saleUserIsUpdated: {},
        };
    }

    loadAll = async () => {
        const { userStore } = this.props.stores;
        // userStore.getInfo({ forceLoad: true });
        userStore.getInfo();
        this.checkForInvoicesReadyForFiken();
        this.checkForInvoicesReadyForTripletex();
    }

    loadJournals = async (animalIds, sale, journalIds) => {
        const { journalStore, saleStore } = this.props.stores;
        if (!util.isArray(journalIds) || journalIds.length === 0) {
            return false;
        }
        const response = await journalStore.load({
            query: {
                id: journalIds,
                // $or: [
                //     {
                //         animal: animalIds,
                //         sale,
                //     },
                //     {
                //         id: { $in: journalIds },
                //     },
                // ],
            },
            skipUpdate: true,
            addData: ['totalSales'],
        });
        saleStore.updateKeyValue('animalJournals', response);
    }

    loadAnimals = async visitorIds => {
        const { animalStore, saleStore } = this.props.stores;
        const response = await animalStore.load({
            query: {
                owner: visitorIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAnimals', response);

        await animalStore.load({
            query: {
                owner: visitorIds,
            },
        });
    }

    loadSales = async visitorIds => {
        if (!util.isArray(visitorIds) || visitorIds.length === 0) {
            return false;
        }
        const { saleStore } = this.props.stores;
        const response = await saleStore.load({
            query: {
                visitor: visitorIds,
                today: 1,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentVisitorSales', response);
        // this.loadAllVisitorSalePayments();
    }

    loadSalePayments = async saleId => {
        if (!util.isNumber(saleId)) {
            return false;
        }
        const { salePaymentStore, saleStore, paymentStore } = this.props.stores;
        const response = await salePaymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('currentPayments', response);

        // Load Vipps and PayPal payments
        const externalPayments = await paymentStore.load({
            query: {
                sale: saleId,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('currentExternalPayments', externalPayments);

        // Load all Vipps and PayPal payments for this user
        const { newSale } = saleStore;
        if (newSale?.user) {
            const externalUserPayments = await paymentStore.load({
                query: {
                    user: newSale.user,
                },
                skipUpdate: true,
            });
            saleStore.updateKeyValue('externalUserPayments', externalUserPayments);
        }
    }

    loadAllSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleIds = sales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
            limit: 5000,
        });
        saleStore.updateKeyValue('allPayments', response);
    }

    loadAllVisitorSalePayments = async () => {
        const { salePaymentStore, saleStore } = this.props.stores;
        saleStore.updateKeyValue('visitorAllPayments', []);

        const { currentVisitorSales = [] } = saleStore;
        const saleIds = currentVisitorSales.map(e => e.id);
        if (saleIds.length === 0) {
            return false;
        }
        const response = await salePaymentStore.load({
            query: {
                sale: saleIds,
            },
            skipUpdate: true,
        });
        saleStore.updateKeyValue('visitorAllPayments', response);
    }

    onClickDeleteSale = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { deleteAreYouSure, callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        if (confirm(deleteAreYouSure)) {
            const { saleStore } = this.props.stores;
            const response = await saleStore.deleteSale(parseInt(id, 10));
            callback();
        }
    }

    onClickUndeleteSale = async e => {
        e.stopPropagation();
        e.preventDefault();
        const { callback = () => {} } = this.props;
        const { id } = e.target.closest('button').dataset;
        const { saleStore } = this.props.stores;
        const response = await saleStore.save({
            id: parseInt(id, 10),
            offline: 0,
        });
        callback();
    }

    onClickViewVisit = async e => {
        const { id } = e.target.closest('tr').dataset;
        this.viewVisit(parseInt(id, 10));
    }

    viewVisit = async id => {
        const { drawerLevel = 1 } = this.props;
		const { appState, saleStore } = this.props.stores;
        saleStore.createNewSale({});

        const newSale = await saleStore.load(id, false, { skipUpdate: true });
        saleStore.updateKeyValue('newSale', newSale[0]);
        const { visitors, isProductionAnimal } = newSale[0];

        appState.appContainerScrollTop();
        appState.toggleDrawerRight(false, drawerLevel);

        if (id) {
            await this.loadSalePayments(id);
        }
        if (newSale[0].visitors) {
            const visitorIds = newSale[0].visitors.map(e => e.id);
            // console.log('onClickViewVisit', visitorIds)
            await this.loadAnimals(visitorIds);
            await this.loadSales(visitorIds);
        }

        if (newSale[0].animals) {
            const journalIds = newSale[0].journals.map(e => e.id);
            const animalIds = newSale[0].animals.map(e => e.id);
            await this.loadJournals(animalIds, newSale[0].id, journalIds);
        }

        const isProductionAnimals = util.isDefined(isProductionAnimal) ? isProductionAnimal : visitors.some(v => v?.producerNumber);
        saleStore.updateKeyValue('isProductionAnimals', isProductionAnimals);
        console.log('viewVisit: done');

    }

    createFikenCompanyInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { fikenLoading = {} } = this.state;
        fikenLoading[idInt] = true;
        this.setState({ fikenLoading });

        const { callback = () => {} } = this.props;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanyInvoice(id);
        // console.log('createFikenCompanyInvoice', response);
        callback();
        fikenLoading[idInt] = false;
        this.setState({ fikenLoading });
    }

    createFikenCompanySale = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { fikenLoading = {} } = this.state;
        fikenLoading[idInt] = true;
        this.setState({ fikenLoading });
        const { callback = () => {} } = this.props;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanySale(id);
        // console.log('createFikenCompanyInvoice', response);
        callback();
        fikenLoading[idInt] = false;
        this.setState({ fikenLoading });
    }

    sendFikenInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { fikenLoading = {} } = this.state;
        fikenLoading[idInt] = true;
        this.setState({ fikenLoading });
        const { callback = () => {} } = this.props;
        const { fikenStore } = this.props.stores;
        const response = await fikenStore.sendCompanyInvoice(id);
        // console.log('sendFikenInvoice', response);
        callback();
        fikenLoading[idInt] = false;
        this.setState({ fikenLoading });
    }

    sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    sendFikenInvoiceByID = async id => {
        const idInt = parseInt(id, 10);
        const { fikenLoading = {}, fikenSent = {} } = this.state;
        fikenLoading[idInt] = true;
        this.setState({ fikenLoading });

        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanyInvoice(id);
        await this.sleep(200);

        fikenLoading[idInt] = false;
        fikenSent[idInt] = true;
        this.setState({ fikenLoading, fikenSent });
    }

    sendFikenSaleByID = async id => {
        const idInt = parseInt(id, 10);
        const { fikenLoading = {}, fikenSent = {} } = this.state;
        fikenLoading[idInt] = true;
        this.setState({ fikenLoading });

        const { fikenStore } = this.props.stores;
        const response = await fikenStore.createCompanySale(id);
        await this.sleep(200);

        fikenLoading[idInt] = false;
        fikenSent[idInt] = true;
        this.setState({ fikenLoading, fikenSent });
    }

    sendAllInvoicesToFiken = async e => {
        this.setState({ sendAllInvoicesToFiken: true });
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;

        const invoiceSalesForFikenTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isInvoice = e.paymentMethod === 'invoice';
            const isTransferred = !!(e.fikenLocation && e.fikenLocation.length > 0)
            return isInvoice && !isTransferred;
        });
        // this.setState({ invoiceSalesForFikenTransfer });
        // console.log('sendAllInvoicesToFiken', invoiceSalesForFikenTransfer);

        for (let i = 0, l = invoiceSalesForFikenTransfer.length; i < l; i += 1) {
            const sale = invoiceSalesForFikenTransfer[i];
            await this.sendFikenInvoiceByID(sale.id);
        }
        this.setState({ sendAllInvoicesToFiken: false, allInvoicesSentToFiken: true });
    }

    sendAllSalesToFiken = async e => {
        this.setState({ sendAllSalesToFiken: true });
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;

        const saleSalesForFikenTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isPaid = e.status === 1;
            const isTransferred = !!(e.fikenLocation && e.fikenLocation.length > 0)
            return isPaid && !isTransferred;
        });
        // this.setState({ invoiceSalesForFikenTransfer });
        // console.log('sendAllInvoicesToFiken', invoiceSalesForFikenTransfer);

        for (let i = 0, l = saleSalesForFikenTransfer.length; i < l; i += 1) {
            const sale = saleSalesForFikenTransfer[i];
            await this.sendFikenSaleByID(sale.id);
        }
        this.setState({ sendAllSalesToFiken: false, allSalesSentToFiken: true });
    }

    checkForInvoicesReadyForFiken = async e => {
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const invoiceSalesForFikenTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isInvoice = e.paymentMethod === 'invoice';
            const isTransferred = !!(e.fikenLocation && e.fikenLocation.length > 0)
            return isInvoice && !isTransferred;
        });

        this.setState({ invoiceSalesForFikenTransfer });
        // console.log('checkForInvoicesReadyForFiken', invoiceSalesForFikenTransfer);
    }

    checkForSalesReadyForFiken = async e => {
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleSalesForFikenTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isPaid = e.status === 1;
            const isTransferred = !!(e.fikenLocation && e.fikenLocation.length > 0)
            return isPaid && !isTransferred;
        });

        this.setState({ saleSalesForFikenTransfer });
        // console.log('checkForInvoicesReadyForFiken', invoiceSalesForFikenTransfer);
    }


    // invoiceSalesForTripletexTransfer
    checkForInvoicesReadyForTripletex = async e => {
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const invoiceSalesForTripletexTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isInvoice = e.paymentMethod === 'invoice';
            const isTransferred = e.tripletexResponse && e.tripletexResponse?.statusCode === 201;
            return isInvoice && !isTransferred;
        });

        this.setState({ invoiceSalesForTripletexTransfer });
        // console.log('checkForInvoicesReadyForTripletex', invoiceSalesForTripletexTransfer);
    }

    checkForSalesReadyForTripletex = async e => {
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleSalesForTripletexTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isPaid = e.status === 1;
            const isTransferred = e.tripletexResponse && e.tripletexResponse?.statusCode === 201;
            return isPaid && !isTransferred;
        });

        this.setState({ saleSalesForTripletexTransfer });
        // console.log('checkForInvoicesReadyForTripletex', invoiceSalesForTripletexTransfer);
    }

    sendAllInvoicesToTripletex = async e => {
        this.setState({ sendAllInvoicesToTripletex: true });
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;

        const invoiceSalesForTripletexTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isInvoice = e.paymentMethod === 'invoice';
            const isTransferred = e.tripletexResponse && e.tripletexResponse?.statusCode === 201;
            return isInvoice && !isTransferred;
        });
        // this.setState({ invoiceSalesForTripletexTransfer });
        // console.log('sendAllInvoicesToTripletex', invoiceSalesForTripletexTransfer);

        for (let i = 0, l = invoiceSalesForTripletexTransfer.length; i < l; i += 1) {
            const sale = invoiceSalesForTripletexTransfer[i];
            await this.sendTripletexInvoiceById(sale.id);
        }
        this.setState({ sendAllInvoicesToTripletex: false, allInvoicesSentToTripletex: true });
    }

    sendAllSalesToTripletex = async e => {
        this.setState({ sendAllSalesToTripletex: true });
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;

        const saleSalesForTripletexTransfer = sales.filter(e => {
            // paymentMethod	"invoice"
            const isPaid = e.status === 1;
            const isTransferred = e.tripletexResponse && e.tripletexResponse?.statusCode === 201;
            return isPaid && !isTransferred;
        });
        // this.setState({ invoiceSalesForTripletexTransfer });
        // console.log('sendAllInvoicesToTripletex', invoiceSalesForTripletexTransfer);

        for (let i = 0, l = saleSalesForTripletexTransfer.length; i < l; i += 1) {
            const sale = saleSalesForTripletexTransfer[i];
            await this.sendTripletexInvoiceById(sale.id);
        }
        this.setState({ sendAllSalesToTripletex: false, allSalesSentToTripletex: true });
    }

    sendTripletexInvoiceById = async id => {
        const idInt = parseInt(id, 10);
        const { tripletexLoading = {}, tripletexSent = {} } = this.state;
        tripletexLoading[idInt] = true;
        this.setState({ tripletexLoading });
        const { tripletexStore } = this.props.stores;

        const response = await tripletexStore.createInvoice(id);
        // console.log('sendTripletexInvoiceById', response);
        await this.sleep(200);

        tripletexLoading[idInt] = false;
        tripletexSent[idInt] = true;
        this.setState({ tripletexLoading, tripletexSent });
    }

    createTripletexInvoice = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { tripletexLoading = {} } = this.state;
        tripletexLoading[idInt] = true;
        this.setState({ tripletexLoading });

        const { callback = () => {} } = this.props;
        const { tripletexStore } = this.props.stores;
        const response = await tripletexStore.createInvoice(id);
        // console.log('createTripletexInvoice', response);
        callback();
        tripletexLoading[idInt] = false;
        this.setState({ tripletexLoading });
    }



    checkForSalesReadyForCollectiveInvoice = async e => {
        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleForCollectiveInvoice = sales.filter(e => {
            // paymentMethod	"invoice"
            const isCollectiveInvoice = e.status === 1003;
            return isCollectiveInvoice;
        });

        this.setState({ saleForCollectiveInvoice });
        // console.log('checkForInvoicesReadyForTripletex', invoiceSalesForTripletexTransfer);
    }

    onClickCreateCollectiveInvoice = async e => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ createAllCollectiveInvoices: true });
        const { visitors = [] } = this.props;
        const visitorIds = visitors.map(v => v.id);
        // console.log('onClickCreateCollectiveInvoice', visitorIds);
        if (!confirm('Are you sure you want to create a collective invoice for these customers?')) {
            return false;
        }

        const { saleStore } = this.props.stores;
        const { sales } = saleStore;
        const saleForCollectiveInvoice = sales.filter(e => {
            // paymentMethod	"invoice"
            const isCollectiveInvoice = e.status === 1003;
            return isCollectiveInvoice;
        });

        console.log('saleForCollectiveInvoice', saleForCollectiveInvoice);


//         const { visitorStore, saleStore, animalStore } = this.props.stores;
//         const { collectiveInvoiceCutoffDate, collectiveInvoiceCutoffDateEnd } = saleStore;
//         // const { currentVisitors } = visitorStore;
//         // const finalVisitors = (visitors && visitors.length > 0) ? (currentVisitors || visitors) : [];
//         saleStore.createNewSale({ visitors });
// // We should load the animals and sales for the visitor here.
//         if (visitorIds.length > 0) {
//             await this.loadAnimals(visitorIds);
//             await this.loadSales(visitorIds, collectiveInvoiceCutoffDate, collectiveInvoiceCutoffDateEnd);
//         }
//         animalStore.updateObjectKeyValue('newAnimal', 'owner', visitorIds[0]);

//         // Add Products from collective invoice sales to this sale.
//         const { visitorSales = [] } = saleStore;
//         const collectiveInvoiceSales = visitorSales.filter(e => e.status === 1003);
//         const collectiveInvoiceProducts = [];
//         collectiveInvoiceSales.forEach(sale => {
//             sale.products.forEach(product => {
//                 collectiveInvoiceProducts.push({
//                     ...product,
//                     saleDate: sale.saleDate,
//                     saleId: sale.id,
//                     saleNumber: sale.saleNumber,
//                     saleUser: sale.user,
//                 });
//             });
//         });
//         saleStore.updateObjectKeyValue('newSale', 'products', collectiveInvoiceProducts);
//         saleStore.updateObjectKeyValue('newSale', 'subInvoices', collectiveInvoiceSales.map(e => e.id));

//         await saleStore.saveSale();

        this.setState({ createAllCollectiveInvoices: false });
        // TODO: Add references to the collective invoice sales.
        // TODO: Mark the collective invoice sales as a subinvoice. New status.
        // TODO: Add the collective invoice sales to the new sale.

    }




    onClickGotoAnimaliaAuth = e => {
        e.preventDefault();
        const { userStore, appState } = this.props.stores;
        const { isDevelopment } = appState;
        const { user } = userStore;

        // Open popup window to Animalia Auth
        const apiServer = util.getApiServer();

        const animalia_redirect_uri = `${apiServer}/api/integrations/animalia/token-exchange`;
        const animalia_client_id = 'dyrejournal';
        const animalia_state = user.id;
        // const animalia_scope = 'openid profile offline_access'.split(' ').join('+');
        const animalia_scope = 'offline_access'.split(' ').join('+');

        let animalia_auth_url = `https://sso.animalia.no/authorize?redirect_uri=${animalia_redirect_uri}&client_id=${animalia_client_id}&state=${animalia_state}&auto_login=true&response_type=code&scope=${animalia_scope}`;
        if (isDevelopment) {
            animalia_auth_url = `https://staging-sso.animalia.no/authorize?redirect_uri=${animalia_redirect_uri}&client_id=${animalia_client_id}&state=${animalia_state}&auto_login=true&response_type=code&scope=${animalia_scope}`;
        }
        // const animalia_auth_url = `https://staging-sso.animalia.no/authorize?redirect_uri=${animalia_redirect_uri}&client_id=${animalia_client_id}&state=${animalia_state}&auto_login=true&response_type=code&scope=${animalia_scope}`;
        window.open(animalia_auth_url, 'Animalia Auth', 'width=600,height=600');

        // set timeout to update userInfo
        setTimeout(() => {
            userStore.getInfo({ forceLoad: true });
        }, 3000);
    }

    onClickPostAnimaliaVisit = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const idInt = parseInt(id, 10);
        const { animaliaLoading = {} } = this.state;
        animaliaLoading[idInt] = true;
        this.setState({ animaliaLoading });

        const { callback = () => {} } = this.props;
        const { appState, userStore } = this.props.stores;
        const response = await appState.animaliaUploadVisit(id);

        console.log('onClickPostAnimaliaVisit.response', response);
        if (response.error) {
            alert(`DHP Error: ${response?.error?.error}`);
            await userStore.getInfo({ forceLoad: true });
        }
        callback();
        animaliaLoading[idInt] = false;
        this.setState({ animaliaLoading });
    }

    onClickRefreshUserInfo = async e => {
        console.log('onClickRefreshUserInfo');
        e.preventDefault();
        e.stopPropagation();
        this.setState({ userLoading: true });
        const { userStore } = this.props.stores;
        await userStore.getInfo({ forceLoad: true });
        this.setState({ userLoading: false });
    }

    handleGotoSale = e => {
        e.preventDefault();
        e.stopPropagation();
        console.log('handleGotoSale', e.key);
        const { saleStore } = this.props.stores;
        const { sales, searchResults } = saleStore;
        const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;
        const idx = parseInt(e.key, 10) - 1;
        const currentSale = finalSales[idx];
        if (currentSale?.id) {
            this.viewVisit(currentSale.id);
        }
    }

    toggleAnimaliaError = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const { displayAnimaliaError } = this.state;
        displayAnimaliaError[id] = !displayAnimaliaError[id];
        this.setState({ displayAnimaliaError });
    }

    toggleTripleTexError = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const { tripletexError } = this.state;
        tripletexError[id] = !tripletexError[id];
        this.setState({ tripletexError });
    }

    toggleFikenError = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
        const { displayFikenError } = this.state;
        displayFikenError[id] = !displayFikenError[id];
        this.setState({ displayFikenError });
    }

    onClickShipping = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 2 } = this.props;
        const { appState, saleStore, animalStore, calendarEventStore } = this.props.stores;
        const { drawerHeightMediumLarge } = appState;

        const { id } = e.target.closest('button').dataset;
        saleStore.createNewSale({});
        const newSale = await saleStore.load(id, false, { skipUpdate: true });
        saleStore.updateKeyValue('newSale', newSale[0]);

        if (id) {
            await this.loadSalePayments(id);
        }

        appState.openDrawer('saleShipping', {
            height: drawerHeightMediumLarge,
            callback: () => {
                saleStore.createNewSale({});
                // saleStore.load({});
            },
        }, drawerLevel + 1);
    }

    onClickMakeInsider = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('button').dataset;
// console.log('onClickMakeInsider', id);
        const { userStore } = this.props.stores;

        const { saleUserUpdating = {}, saleUserIsUpdated = {} } = this.state;
        saleUserUpdating[id] = true;
        this.setState({ saleUserUpdating });

        const response = await userStore.makeInsider(parseInt(id, 10));

        saleUserUpdating[id] = false;
        saleUserIsUpdated[id] = true;
        this.setState({ saleUserUpdating, saleUserIsUpdated });

        // const { saleStore } = this.props.stores;
        // const response = await saleStore.save({
        //     id: parseInt(id, 10),
        //     insider: 1,
        // });
    }

    checkForExternalTransfer = async e => {
        this.checkForInvoicesReadyForFiken();
        this.checkForSalesReadyForFiken();
        this.checkForInvoicesReadyForTripletex();
        this.checkForSalesReadyForTripletex();
    }

    toggleAdminToolbar = e => {
        e.preventDefault();
        e.stopPropagation();
        const { showAdminToolbar } = this.state;
        this.setState({ showAdminToolbar: !showAdminToolbar });
        this.checkForExternalTransfer();

    }

    componentDidMount() {
        this.loadAll();
        Mousetrap.bind(['1', '2', '3', '4', '5', '6', '7', '8', '9'], this.handleGotoSale);
    }

    componentWillUnmount() {
        // Unbind shortcuts
        Mousetrap.unbind(['1', '2', '3', '4', '5', '6', '7', '8', '9']);
    }

    componentWillReceiveProps(nextProps) {
        // console.log('AllVisitsTable.componentWillReceiveProps', nextProps);
        const keysToCheck = [
            'limit',
            'searchText',
            'activeStatus',
            'activeUser',
            'activePaymentMethod',
            'cutoffDate',
            'cutoffDateEnd'
        ];

        if (keysToCheck.some(key => nextProps[key] !== this.props[key])) {
            // console.log('AllVisitsTable.componentWillReceiveProps: checkForExternalTransfer');
            this.checkForExternalTransfer();
        }
    }

    render() {
        const {
            search,
            currentPage = 0,
            cutoffDate,
            animaliaLoading,
            fikenLoading,
            fikenSent = {},
            tripletexLoading,
            tripletexSent,
            userLoading,
            displayAnimaliaError = {},
            tripletexError = {},
            displayFikenError = {},
            saleUserUpdating = {},
            saleUserIsUpdated = {},

            showAdminToolbar,

            invoiceSalesForFikenTransfer = [],
            saleSalesForFikenTransfer = [],

            invoiceSalesForTripletexTransfer = [],
            saleSalesForTripletexTransfer = [],

            saleForCollectiveInvoice = [],

            sendAllInvoicesToFiken,
            allInvoicesSentToFiken,
            sendAllSalesToFiken,
            allSalesSentToFiken,

            sendAllInvoicesToTripletex,
            allInvoicesSentToTripletex,
            sendAllSalesToTripletex,
            allSalesSentToTripletex,

            createAllCollectiveInvoices,
        } = this.state;
        const {
            saleTitle,
            searchTitle,
            formatting = true,
            showDelete = true,
            activeStatus,
        } = this.props;
        const { userStore, saleStore, appState, fikenStore } = this.props.stores;
        const { isDevelopment } = appState;
        const { user, isAdmin, customer, isCustomerAdmin } = userStore;
        const { customerIds, language } = user;
        const hasMoreThanOneCustomer = customerIds && customerIds.length > 1;

        const darkmode = util.getNestedValue(user, 'settings.darkmode');

        const { sales, allPayments, searchResults, totalSales, saleUsers = [] } = saleStore;
        const finalSales = searchResults && searchResults.length > 0 ? searchResults : sales;
        // const finalSales = sales;

        const isDebitorList = activeStatus === 99;
        let sumRestPayment = 0;

        const { featureFlags = {} } = customer;
        const { animalia = false, fiken = false, tripletex } = featureFlags;
        const showFiken = fiken;
        const showTripletex = tripletex;
        const showAnimalia = animalia;

        const threeAndHalfHours = 3.5 * 60 * 60 * 1000;
        const animaliaRefreshTokenExpire = user?.animalia?.expires_at + threeAndHalfHours;
        const nowUtime = new Date().getTime();
        let animaliaRefreshTokenExpired = true;
        if (user?.animalia?.expires_at) {
            animaliaRefreshTokenExpired = nowUtime > animaliaRefreshTokenExpire || user?.animalia?.error;
        }

        return (<>
            <div class='d-flex flex-row justify-content-between bg-light rounded-lg py-1'>
                <div class='d-flex flex-row'>
                    {showAnimalia && animaliaRefreshTokenExpired  ? <>
                        <button
                            type='button'
                            class='btn btn-sm btn-primary rounded-pill ml-2'
                            onClick={this.onClickGotoAnimaliaAuth}
                        >
                            <nobr><i class='fa-duotone fa-right-to-bracket' /> <Text id='dyrejournal.animalia-auth-login'>Login to DHP and grant access</Text></nobr>
                        </button>
                        <button
                            type='button'
                            class='btn btn-sm btn-link ml-2 p-0'
                            onClick={this.onClickRefreshUserInfo}
                        >
                            <i class='fa-duotone fa-sync' />
                            {userLoading ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                        </button>
                    </> : <>
                        <button
                            type='button'
                            class='btn btn-link ml-2'
                            onClick={this.onClickGotoAnimaliaAuth}
                        >
                            <nobr><i class='fa-duotone fa-right-to-bracket' /> <Text id='dyrejournal.animalia-auth-relogin'>Re-login to DHP and grant access</Text></nobr>
                        </button>
                        <button
                            type='button'
                            class='btn btn-link ml-2 p-0'
                            onClick={this.onClickRefreshUserInfo}
                        >
                            <i class='fa-duotone fa-sync' /> <Text id='dyrejournal.refresh-user-info'>Refresh user info</Text>
                            {userLoading ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                        </button>
                    </>}
                </div>

                <div class='mx-2 d-flex flex-column justify-content-center align-items-center'>
                    <button
                        class={`btn btn-sm btn-${showAdminToolbar ? 'primary' : 'outline-primary'} rounded-pill`}
                        onClick={this.toggleAdminToolbar}
                    >
                        <i class={`fa-duotone fa-cog`} /> <Text id='dyrejournal.admin-toolbar'>Admin toolbar</Text>
                    </button>
                </div>
            </div>

            {isAdmin && <>
                <div class='d-flex flex-row justify-content-center bg-light rounded-lg'>
                    <small>
                        <span class='mx-1'><span class='font-weight-lighter'>animaliaRefreshTokenExpire:</span> {animaliaRefreshTokenExpire ? util.isoDate(animaliaRefreshTokenExpire) : 'n/a'}</span>
                        <span class='mx-1'><span class='font-weight-lighter'>user?.animalia?.expires_at:</span> {user?.animalia?.expires_at ? util.isoDate(user?.animalia?.expires_at) : 'n/a'}</span>
                        <span class='mx-1'><span class='font-weight-lighter'>animaliaRefreshTokenExpired:</span> {animaliaRefreshTokenExpired ? 'true' : 'false'}</span>
                        <span class='mx-1'><span class='font-weight-lighter'>user?.animalia?.error:</span> {stringify(user?.animalia?.error, null, 4)}</span>
                    </small>
                </div>
            </>}


            {(isCustomerAdmin && showAdminToolbar) && <>
                <div class='d-flex flex-row justify-content-end mt-2 mb-2 bg-warning rounded-lg p-2'>

                    {isAdmin && <div class='d-flex flex-column px-4 border-right border-dark'>
                        {saleForCollectiveInvoice.length > 0 ? <>
                            <button
                                disabled={createAllCollectiveInvoices}
                                class='btn btn-danger rounded-pill'
                                onClick={this.onClickCreateCollectiveInvoice}
                            >
                                <nobr>{createAllCollectiveInvoices ? <i class='fa-duotone fa-spinner fa-spin' /> : <i class='fa-duotone fa-paper-plane' />} <Text id='dyrejournal.create-all-collective-invoices'>Create all collective invoices</Text></nobr>
                            </button>
                            <div class='ml-2'>
                                <small>
                                    <span class='font-weight-lighter'><Text id='dyrejournal.invoices-ready-for-collective-invoice'>Collective Invoices ready</Text>:</span> {saleForCollectiveInvoice.length}
                                </small>
                            </div>
                        </> : <>
                            <Text id='dyrejournal.no-collective-invoices'>No collective invoices</Text>
                            <button
                                class='btn btn-link p-0'
                                onClick={this.checkForSalesReadyForCollectiveInvoice}
                            >
                                <nobr><i class='fa-duotone fa-sync' /> <Text id='dyrejournal.check-for-collective-invoices'>Check for collective invoices</Text></nobr>
                            </button>
                        </>}

                        {createAllCollectiveInvoices && <div class='mt-2 badge badge-alert'>
                            <i class='fa-duotone fa-spinner fa-spin' /> <Text id='dyrejournal.sending-all-invoices-to-fiken'>Creating all collective invoices. DO NOT CLOSE THIS WINDOW!</Text>
                        </div>}
                    </div>}



                    <div class='d-flex flex-column px-4 border-right border-dark'>
                        {allInvoicesSentToFiken ? <>
                            <Text id='dyrejournal.all-invoices-sent-to-fiken'>All invoices sent to Fiken</Text>
                        </> : <>
                            {invoiceSalesForFikenTransfer.length > 0 ? <>
                                <button
                                    disabled={sendAllInvoicesToFiken}
                                    class='btn btn-danger rounded-pill'
                                    onClick={this.sendAllInvoicesToFiken}
                                >
                                    <nobr>{sendAllInvoicesToFiken ? <i class='fa-duotone fa-spinner fa-spin' /> : <i class='fa-duotone fa-paper-plane' />} <Text id='dyrejournal.send-all-invoices-to-fiken'>Send all invoices to Fiken</Text></nobr>
                                </button>
                                <div class='ml-2'>
                                    <small>
                                        <span class='font-weight-lighter'><Text id='dyrejournal.invoices-to-send-to-fiken'>Invoices ready to be sent to Fiken</Text>:</span> {invoiceSalesForFikenTransfer.length}
                                    </small>
                                </div>
                            </> : <>
                                <Text id='dyrejournal.no-invoices-to-send-to-fiken'>No invoices ready to be sent to Fiken</Text>
                                <button
                                    class='btn btn-link p-0'
                                    onClick={this.checkForInvoicesReadyForFiken}
                                >
                                    <nobr><i class='fa-duotone fa-sync' /> <Text id='dyrejournal.check-for-invoices-ready-for-fiken'>Check for invoices ready for Fiken</Text></nobr>
                                </button>
                            </>}

                            {sendAllInvoicesToFiken && <div class='mt-2 badge badge-alert'>
                                <i class='fa-duotone fa-spinner fa-spin' /> <Text id='dyrejournal.sending-all-invoices-to-fiken'>Sending all invoices to Fiken. DO NOT CLOSE THIS WINDOW!</Text>
                            </div>}
                        </>}
                    </div>
                    <div class='d-flex flex-column px-4 border-right border-dark'>
                        {allSalesSentToFiken ? <>
                            <Text id='dyrejournal.all-sales-sent-to-fiken'>All sales sent to Fiken</Text>
                        </> : <>
                            {saleSalesForFikenTransfer.length > 0 ? <>
                                <button
                                    disabled={sendAllSalesToFiken}
                                    class='btn btn-danger rounded-pill'
                                    onClick={this.sendAllSalesToFiken}
                                >
                                    <nobr>{sendAllSalesToFiken ? <i class='fa-duotone fa-spinner fa-spin' /> : <i class='fa-duotone fa-paper-plane' />} <Text id='dyrejournal.send-all-sales-to-fiken'>Send all sales to Fiken</Text></nobr>
                                </button>
                                <div class='ml-2'>
                                    <small>
                                        <span class='font-weight-lighter'><Text id='dyrejournal.sales-to-send-to-fiken'>Sales ready to be sent to Fiken</Text>:</span> {saleSalesForFikenTransfer.length}
                                    </small>
                                </div>
                            </> : <>
                                <Text id='dyrejournal.no-sales-to-send-to-fiken'>No sales ready to be sent to Fiken</Text>
                                <button
                                    class='btn btn-link p-0'
                                    onClick={this.checkForSalesReadyForFiken}
                                >
                                    <nobr><i class='fa-duotone fa-sync' /> <Text id='dyrejournal.check-for-sales-ready-for-fiken'>Check for sales ready for Fiken</Text></nobr>
                                </button>
                            </>}

                            {sendAllSalesToFiken && <div class='mt-2 badge badge-alert'>
                                <i class='fa-duotone fa-spinner fa-spin' /> <Text id='dyrejournal.sending-all-sales-to-fiken'>Sending all sales to Fiken. DO NOT CLOSE THIS WINDOW!</Text>
                            </div>}
                        </>}
                    </div>


                    <div class='d-flex flex-column px-4 border-right border-dark'>
                        {allInvoicesSentToTripletex ? <>
                            <Text id='dyrejournal.all-invoices-sent-to-tripletex'>All invoices sent to Tripletex</Text>
                        </> : <>
                            {invoiceSalesForTripletexTransfer.length > 0 ? <>
                                <button
                                    disabled={sendAllInvoicesToTripletex}
                                    class='btn btn-danger rounded-pill'
                                    onClick={this.sendAllInvoicesToTripletex}
                                >
                                    <nobr>{sendAllInvoicesToTripletex ? <i class='fa-duotone fa-spinner fa-spin' /> : <i class='fa-duotone fa-paper-plane' />} <Text id='dyrejournal.send-all-invoices-to-tripletex'>Send all invoices to Tripletex</Text></nobr>
                                </button>
                                <div class='ml-2'>
                                    <small>
                                        <span class='font-weight-lighter'><Text id='dyrejournal.invoices-to-send-to-tripletex'>Invoices ready to be sent to Tripletex</Text>:</span> {invoiceSalesForTripletexTransfer.length}
                                    </small>
                                </div>
                            </> : <>
                                <Text id='dyrejournal.no-invoices-to-send-to-tripletex'>No invoices ready to be sent to Tripletex</Text>
                                <button
                                    class='btn btn-link p-0'
                                    onClick={this.checkForInvoicesReadyForTripletex}
                                >
                                    <nobr><i class='fa-duotone fa-sync' /> <Text id='dyrejournal.check-for-invoices-ready-for-tripletex'>Check for invoices ready for Tripletex</Text></nobr>
                                </button>
                            </>}
                            {sendAllInvoicesToTripletex && <div class='mt-2 badge badge-danger'>
                                <i class='fa-duotone fa-spinner fa-spin' /> <Text id='dyrejournal.sending-all-invoices-to-tripletex'>Sending all invoices to Tripletex. DO NOT CLOSE THIS WINDOW!</Text>
                            </div>}
                        </>}
                    </div>
                    <div class='d-flex flex-column px-4 '>
                        {allSalesSentToTripletex ? <>
                            <Text id='dyrejournal.all-sales-sent-to-tripletex'>All sales sent to Tripletex</Text>
                        </> : <>
                            {saleSalesForTripletexTransfer.length > 0 ? <>
                                <button
                                    disabled={sendAllSalesToTripletex}
                                    class='btn btn-danger rounded-pill'
                                    onClick={this.sendAllSalesToTripletex}
                                >
                                    <nobr>{sendAllSalesToTripletex ? <i class='fa-duotone fa-spinner fa-spin' /> : <i class='fa-duotone fa-paper-plane' />} <Text id='dyrejournal.send-all-sales-to-tripletex'>Send all sales to Tripletex</Text></nobr>
                                </button>
                                <div class='ml-2'>
                                    <small>
                                        <span class='font-weight-lighter'><Text id='dyrejournal.sales-to-send-to-tripletex'>Sales ready to be sent to Tripletex</Text>:</span> {saleSalesForTripletexTransfer.length}
                                    </small>
                                </div>
                            </> : <>
                                <Text id='dyrejournal.no-sales-to-send-to-tripletex'>No sales ready to be sent to Tripletex</Text>
                                <button
                                    class='btn btn-link p-0'
                                    onClick={this.checkForSalesReadyForTripletex}
                                >
                                    <nobr><i class='fa-duotone fa-sync' /> <Text id='dyrejournal.check-for-sales-ready-for-tripletex'>Check for sales ready for Tripletex</Text></nobr>
                                </button>
                            </>}
                            {sendAllSalesToTripletex && <div class='mt-2 badge badge-danger'>
                                <i class='fa-duotone fa-spinner fa-spin' /> <Text id='dyrejournal.sending-all-sales-to-tripletex'>Sending all sales to Tripletex. DO NOT CLOSE THIS WINDOW!</Text>
                            </div>}
                        </>}
                    </div>



                </div>
            </>}


            <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mt-3`}>
                <table class='table table-sm table-striped mb-0' style='font-size: 0.9em;'>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th class='d-none d-sm-table-cell'><Text id='dyrejournal.id'>Id</Text></th>
                            {hasMoreThanOneCustomer && <th class='d-none d-sm-table-cell text-center'><Text id='dyrejournal.customer'>Customer</Text></th>}
                            <th><Text id='dyrejournal.createdDate'>CreatedDate</Text></th>
                            <th><Text id='dyrejournal.saleDate'>SaleDate</Text></th>
                            <th><Text id='dyrejournal.sale.seller'>Seller</Text></th>
                            <th><Text id='dyrejournal.title'>Title</Text></th>
                            <th class='text-center'><Text id='dyrejournal.animals'>Animals</Text></th>
                            <th class='text-center'><Text id='dyrejournal.journals'>Journals</Text></th>
                            <th class='text-center'><Text id='dyrejournal.status'>Status</Text></th>
                            <th class='text-center'><Text id='dyrejournal.payment-method'>Payment method</Text></th>
                            <th class='text-center'><Text id='dyrejournal.total'>Total</Text></th>
                            <th class='text-center'><Text id='dyrejournal.rest'>Rest</Text></th>
                            {!isDebitorList && <th class='text-center'><Text id='dyrejournal.payments'>Payments</Text></th>}
                            {showFiken && <th class='text-center'>Fiken</th>}
                            {showTripletex && <th class='text-center'>Tripletex</th>}
                            {showAnimalia && <th class='text-center'>DHP</th>}
                            {showDelete && <th class='text-center'>&nbsp;</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {(finalSales && finalSales.length > 0) && finalSales.map((e, idx) => {
                            const saleStatus = saleStore.getSaleStatusById(e.status);
                            const saleStatusText = saleStore.getSaleStatusById(e.status, language);
                            const saleStatusIcon = saleStore.getSaleStatusIcon(saleStatus);
                            const saleStatusClass = saleStore.getSaleStatusClass(saleStatus);
                            const salePayments = allPayments.filter(p => p.sale === e.id);

                            let paymentRestAmount = 0;
                            const hasPayments = salePayments && salePayments.length > 0;
                            if (hasPayments) {
                                paymentRestAmount = saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), false, false);
                            }

                            if (isDebitorList) {
                                sumRestPayment += e.restPayment;
                            }

                            let saleIsPaid = (!!e.paidDate && !!e.paymentMethod);
                            if (paymentRestAmount > 0) {
                                saleIsPaid = false;
                            }

                            if (e.paymentMethod === 'creditNote'
                                || e.paymentMethod === 'invoiceCredited'
                                || e.paymentMethod === 'saleCredited') {
                                saleIsPaid = true;
                            }
                            const isAllowedToDelete = !e.saleNumber;
                            const hasJournals = e.journals && e.journals.length > 0;

                            const hasDiagnoses = hasJournalWithDiagnoses(e);
                            const hasMedicines = hasJournalWithMedicines(e);
                            const employee = saleStore.getEmployee(e.user) || {};

                            const hasInsideProducts = e.products && e.products.length > 0 && e.products.some(p => p.isGlobalSubscription);
                            const saleUser = saleUsers.find(u => u.id === e.user) || {};

                            return (<>
                                <tr onClick={this.onClickViewVisit} data-id={e.id} class={`${e.offline ? 'deleted text-muted' : ''}`}>
                                    <td class='font-weight-lighter text-muted'>{idx + 1}</td>
                                    <td class='d-none d-sm-table-cell text-center'>
                                        <nobr>
                                            {e.offline ? <>
                                                <button
                                                    class='btn btn-sm btn-link text-danger'
                                                    onClick={this.onClickUndeleteSale}
                                                    data-id={e.id}
                                                >
                                                    <i class='fa-duotone fa-trash-slash' />
                                                </button>
                                            </> : ''}
                                            {e.saleNumber ? <>{e.saleNumber}</> : <>n/a</>}
                                        </nobr>
                                    </td>
                                    {hasMoreThanOneCustomer && <td class='d-none d-sm-table-cell text-center'>
                                        {e.customer}
                                    </td>}
                                    <td>
                                        <nobr>{formatting ? util.formatDate(e.createdDate || e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : util.isoDate(e.createdDate, false)}</nobr>
                                    </td>
                                    <td>
                                        <nobr class='text-muted'>{formatting ? util.formatDate(e.saleDate, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : util.isoDate(e.saleDate, false)}</nobr>
                                    </td>
                                    <td>
                                        <nobr>
                                            {e.user && employee?.firstname ? getInitials(`${employee.firstname} ${employee.lastname}`) : <span class='text-muted'><Text id='dyrejournal.no-data'>n/a</Text></span>}
                                        </nobr>
                                    </td>
                                    <td>
                                        {e.isProductionAnimal ? <i class='fa-duotone fa-cow text-muted mr-2' /> : ''}

                                        {e.title || 'no title'}

                                        {(e.isWeborder && saleIsPaid) && <>
                                            {e.noPackages ? <>
                                                <i class='fa-duotone fa-check ml-2 text-success' />
                                            </> : <>
                                                {e.packedDate && <>
                                                    <i class='fa-duotone fa-box-check ml-2 text-success' />
                                                </>}
                                                {e.shippingDate && <>
                                                    <i class='fa-duotone fa-truck ml-2 mr-1 text-success' />
                                                </>}
                                                {e.shippingMethod && <>
                                                    {e.shippingMethod}
                                                </>}
                                                {e.trackingNumber && <>
                                                    <i class='fa-duotone fa-barcode ml-2 text-success' /> {e.trackingNumber}
                                                </>}
                                                {/* <i class='fa-duotone fa-globe ml-2 text-success' /> */}
                                                <button
                                                    class={`btn btn-outline-success btn-sm py-0 ml-2 rounded-pill`}
                                                    data-id={e.id}
                                                    onClick={this.onClickShipping}
                                                >
                                                    <i class='fa-solid fa-paper-plane' /> <Text id='dyrejournal.sale.shipping'>Shipping</Text>
                                                </button>
                                            </>}

                                            {hasInsideProducts && <>
                                                {saleUser?.hasValidSubscription || saleUserIsUpdated[saleUser.id] ? <>
                                                    <span class='badge badge-danger ml-2'>Insider</span>
                                                </> : <>
                                                    <button
                                                        type='button'
                                                        class='btn btn-sm btn-warning rounded-pill ml-2'
                                                        onClick={this.onClickMakeInsider}
                                                        data-id={saleUser.id}
                                                    >
                                                        No Insider {saleUserUpdating[saleUser.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                    </button>
                                                </>}
                                            </>}
                                        </>}

                                        <br />
                                        {e.comments && e.comments.length > 0 && <>
                                            <small style='line-height: 1.0em;'>
                                                <div class='d-flex flex-column'>
                                                    {e.comments && e.comments.length > 0 && e.comments.map((comment, idx) => {
                                                        return (<>
                                                            <div>
                                                                <nobr class='font-weight-lighter'>{util.formatDate(comment.date, { hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}</nobr>: {comment.comment}
                                                            </div>
                                                        </>);
                                                    })}
                                                </div>
                                            </small>
                                        </>}

                                    </td>
                                    <td class='text-center'>{e.animals?.length > 0 ? e.animals.length : ''}</td>
                                    <td class='text-center'>{e.journals?.length > 0 ? e.journals.length : ''}</td>
                                    <td class='text-center'>
                                        <nobr>
                                            {/* e.status: {e.status}:: */}
                                            {saleIsPaid ? <>
                                                {e.status === 8 ? <>
                                                    <span class={`text-success`}><i class={saleStore.getSaleStatusIcon(e.paymentMethod)} /> {saleStore.getSaleStatusById(saleStore.getSaleStatus(e.paymentMethod), language)}</span>
                                                </> : <>
                                                    <span class={`text-${saleStore.getSaleStatusClass('paid')}`}><i class={saleStore.getSaleStatusIcon('paid')} /> {saleStore.getSaleStatusById(1, language)}</span>
                                                </>}
                                                {/* paymentRestAmount: {paymentRestAmount}:: */}
                                            </> : <>
                                                <span class={`text-${saleStatusClass}`}><i class={saleStatusIcon} /> {saleStatusText}</span>
                                            </>}
                                            {/* saleIsPaid: {saleIsPaid ? 'true' : 'false'}
                                            e.paidDate: {e.paidDate ? util.formatDate(e.paidDate, { month: '2-digit', year: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : 'n/a'}
                                            e.paymentMethod: {e.paymentMethod}
                                            paymentRestAmount: {paymentRestAmount} */}
                                            {/* paymentRestAmount: {paymentRestAmount} */}
                                            {/* e.status: {e.status} */}
                                            {/* e.paidDate: {e.paidDate ? util.formatDate(e.paidDate, { month: '2-digit', year: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' }) : 'n/a'} */}
                                            {/* e.paymentMethod: {e.paymentMethod} */}

                                        </nobr>
                                    </td>
                                    <td class='text-center'>
                                        <i class={saleStore.getPaymentMethodIcon(e.paymentMethod)} /> {saleStore.getPaymentMethodText(e.paymentMethod, language)}
                                        {['cash', 'card', 'invoice', 'account', 'vipps', 'partial'].includes(e.paymentMethod) ? <>
                                            {salePayments && salePayments.length > 0 ? <>
                                            </> : <>
                                                {e.status > 0 && paymentRestAmount > 0 && <>
                                                    <br /><i class='fa-solid text-danger fa-triangle-exclamation mr-2' /> <Text id='dyrejournal.no-payments'>No payments</Text>
                                                </>}
                                            </>}
                                        </> : <>
                                        </>}
                                    </td>
                                    <td class='text-right'>
                                        <nobr>{saleStore.sumTotal(e.products, formatting)}</nobr>
                                    </td>
                                    <td class={`text-right ${saleIsPaid ? '' : 'text-danger font-weight-bold'}`}>
                                        {e.totalCost ? <>
                                            <nobr>{formatting ? util.format(e.restPayment, 2) : e.restPayment}</nobr>
                                        </> : <>
                                            {salePayments && salePayments.length > 0 && <>
                                                <nobr>{saleStore.sumRestPayments(salePayments, saleStore.sumTotal(e.products, false), formatting, false)}</nobr>
                                            </>}
                                        </>}
                                    </td>
                                    {!isDebitorList && <td classs='text-left'>
                                        <small>
                                            <div class='d-flex flex-column'>
                                                {salePayments && salePayments.length > 0 && salePayments.map(payment => {
                                                    return (<>
                                                        <div class='d-flex flex-row justify-content-between'>
                                                            <nobr class='text-muted mr-2'>
                                                                {util.formatDate(payment.paidDate, { month: '2-digit', year: '2-digit', hour12: false, hour: '2-digit', minute: '2-digit',  locale: 'nb-NO' })}
                                                            </nobr>
                                                            <nobr>
                                                                {formatting ? util.format(payment.amount, 2) : payment.amount} {saleStore.getPaymentMethodText(payment.method, language)}
                                                            </nobr>
                                                        </div>
                                                    </>);
                                                })}
                                            </div>
                                        </small>
                                    </td>}

                                    {showFiken && <td class='text-center'>
                                        {!customer.fikenAccessToken || !customer.fikenSlug ? <>
                                            {!customer.fikenAccessToken ? <>
                                                <small>
                                                    <Text id='dyrejournal.fiken-access-token-missing'>Fiken Access Token missing</Text>
                                                </small>
                                            </> : <></>}
                                            {!customer.fikenSlug ? <>
                                                <small>
                                                    <Text id='dyrejournal.fiken-slug-missing'>Fiken Slug missing</Text>
                                                </small>
                                            </> : <></>}
                                        </> : <>
                                            <small>
                                                {visitorHasEmail(e.visitors) && !visitorHasValidEmail(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-email-not-valid'>Visitor Email not valid</Text></span></nobr><br />
                                                </>}
                                                {visitorHasCellphone(e.visitors) && !visitorHasValidCellphone(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-cellphone-not-valid'>Visitor Cellphone not valid</Text></span></nobr><br />
                                                </>}
                                                {!visitorHasEmailOrCellphone(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-email-or-cellphone-not-valid'>Visitor missing email and cellphone</Text></span></nobr><br />
                                                </>}
                                                {e.paymentMethod === 'invoice' ? <>
                                                    {e.fikenLocation ? <>
                                                        <Text id='dyrejournal.fiken-invoice-created'>Fiken Invoice created</Text>
                                                        {!e.fikenInvoiceSent ? <>
                                                            <button
                                                                disabled={fikenLoading[e.id]}
                                                                type='button'
                                                                class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                                data-id={e.id}
                                                                onClick={this.sendFikenInvoice}
                                                            >
                                                                <i class='fa-duotone fa-paper-plane-top' /> <Text id='dyrejournal.fiken-send-invoice'>Fiken: Send Invoice</Text>
                                                                {fikenLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </>: <>
                                                            <span class='ml-1'>
                                                                <Text id='dyrejournal.fiken-invoice-sent'>and sent</Text>
                                                            </span>
                                                        </>}

                                                        <button
                                                            disabled={fikenLoading[e.id]}
                                                            type='button'
                                                            class='btn btn-sm btn-link py-0'
                                                            data-id={e.id}
                                                            onClick={this.createFikenCompanyInvoice}
                                                        >
                                                            <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-recreate-invoice'>Fiken: Re-create Invoice</Text>
                                                            {fikenLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                        </button>

                                                        {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                    </> : <>
                                                        {saleIsPaid ? <>
                                                            <button
                                                                disabled={fikenLoading[e.id]}
                                                                type='button'
                                                                class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                                data-id={e.id}
                                                                onClick={this.createFikenCompanySale}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-sale'>Fiken: Create Sale</Text>
                                                                {fikenLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </> : <>
                                                            {fikenSent[e.id] ? <>
                                                                <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.fiken-invoice-created'>Fiken Invoice created</Text>
                                                            </> : <>
                                                                <button
                                                                    disabled={fikenLoading[e.id]}
                                                                    type='button'
                                                                    class='btn btn-sm btn-success rounded-pill py-0'
                                                                    data-id={e.id}
                                                                    onClick={this.createFikenCompanyInvoice}
                                                                >
                                                                    <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-invoice'>Fiken: Create Invoice</Text>
                                                                    {fikenLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                                </button>
                                                            </>}
                                                        </>}

                                                        {e.fikenResponse && (e.fikenResponse?.statusCode > 201 || (e.fikenResponse && e.fikenResponse[0] && e.fikenResponse[0].message)) ? <>
                                                            <button class={`btn btn${displayFikenError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleFikenError} data-id={e.id}>
                                                                <nobr>
                                                                    <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-error'>Show Error</Text>
                                                                </nobr>
                                                            </button>
                                                        </> : <></>}
                                                    </>}
                                                </> : <>
                                                    {saleIsPaid && ['cash', 'card', 'vipps'].includes(e.paymentMethod) ? <>
                                                        {e.fikenLocation ? <>
                                                            <Text id='dyrejournal.fiken-sale-created'>Fiken Sale created</Text>
                                                            {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                        </> : <>
                                                            {fikenSent[e.id] ? <>
                                                                <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.fiken-sale-created'>Fiken Sale created</Text>
                                                            </> : <>
                                                                <button
                                                                    disabled={fikenLoading[e.id]}
                                                                    type='button'
                                                                    class='btn btn-sm btn-outline-success rounded-pill py-0'
                                                                    data-id={e.id}
                                                                    onClick={this.createFikenCompanySale}
                                                                >
                                                                    <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.fiken-create-sale'>Fiken: Create Sale</Text>
                                                                    {fikenLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                                </button>
                                                            </>}
                                                        </>}
                                                    </> : <></>}
                                                    {e.fikenResponse && (e.fikenResponse?.statusCode > 201 || (e.fikenResponse && e.fikenResponse[0] && e.fikenResponse[0].message)) ? <>
                                                        <button class={`btn btn${displayFikenError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleFikenError} data-id={e.id}>
                                                            <nobr>
                                                                <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-error'>Show Error</Text>
                                                            </nobr>
                                                        </button>
                                                    </> : <></>}
                                                </>}
                                            </small>
                                            {isAdmin && e.fikenApiResponses && e.fikenApiResponses.length > 0 ? <>
                                                <br />
                                                <button class={`btn btn${displayFikenError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleFikenError} data-id={e.id}>
                                                    <nobr>
                                                        <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-debug'>Show Debug</Text>
                                                    </nobr>
                                                </button>
                                            </> : <></>}
                                        </>}
                                    </td>}

                                    {showTripletex && <td class='text-center'>
                                        {!customer.tripletexEmployeeToken ? <>
                                            <small>
                                                <Text id='dyrejournal.tripletex-employee-token-missing'>Tripletex Employee Token missing</Text>
                                            </small>
                                        </> : <>
                                            <small>
                                                {visitorHasEmail(e.visitors) && !visitorHasValidEmail(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-email-not-valid'>Visitor Email not valid</Text></span></nobr><br />
                                                </>}
                                                {visitorHasCellphone(e.visitors) && !visitorHasValidCellphone(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-cellphone-not-valid'>Visitor Cellphone not valid</Text></span></nobr><br />
                                                </>}
                                                {!visitorHasEmailOrCellphone(e.visitors) && <>
                                                    <nobr><span class='badge badge-danger'><i class='fa-solid fa-warning' /> <Text id='dyrejournal.visitor-email-or-cellphone-not-valid'>Visitor missing email and cellphone</Text></span></nobr><br />
                                                </>}
                                                {e.paymentMethod === 'invoice' ? <>
                                                    {e.tripletexResponse && e.tripletexResponse?.statusCode === 201 ? <>
                                                        <Text id='dyrejournal.tripletex-invoice-created'>Tripletex Invoice created</Text><br />
                                                        <small>
                                                            <button
                                                                disabled={tripletexLoading[e.id]}
                                                                type='button'
                                                                class='btn btn-sm btn-link text-muted py-0'
                                                                data-id={e.id}
                                                                onClick={this.createTripletexInvoice}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-re-create-invoice'>Tripletex: Re-Create Invoice</Text>
                                                                {tripletexLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </small>
                                                    </> : <>
                                                        {tripletexSent[e.id] ? <>
                                                            <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.tripletex-invoice-created'>Tripletex Invoice created</Text>
                                                        </> : <>
                                                            <button
                                                                disabled={tripletexLoading[e.id]}
                                                                type='button'
                                                                class='btn btn-sm btn-info rounded-pill py-0'
                                                                data-id={e.id}
                                                                onClick={this.createTripletexInvoice}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-create-invoice'>Tripletex: Create Invoice</Text>
                                                                {tripletexLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </>}
                                                    </>}
                                                </> : <>
                                                    {saleIsPaid && ['cash', 'card', 'vipps'].includes(e.paymentMethod) ? <>
                                                        {e.tripletexResponse && e.tripletexResponse?.statusCode === 201 ? <>
                                                            <Text id='dyrejournal.tripletex-sale-created'>Tripletex Sale created</Text><br />
                                                            {/* <a href={e.fikenLocation} target='_blank'><i class='fa-duotone fa-external-link' /> Fiken</a> */}
                                                            <small>
                                                                <button
                                                                    disabled={tripletexLoading[e.id]}
                                                                    type='button'
                                                                    class='btn btn-sm btn-link text-muted py-0'
                                                                    data-id={e.id}
                                                                    onClick={this.createTripletexInvoice}
                                                                >
                                                                    <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-re-create-sale'>Tripletex: Re-Create Sale</Text>
                                                                    {tripletexLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                                </button>
                                                            </small>
                                                        </> : <>
                                                            {tripletexSent[e.id] ? <>
                                                                <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.tripletex-sale-created'>Tripletex Sale created</Text>
                                                            </> : <>
                                                                <button
                                                                    disabled={tripletexLoading[e.id]}
                                                                    type='button'
                                                                    class='btn btn-sm btn-outline-info rounded-pill py-0'
                                                                    data-id={e.id}
                                                                    onClick={this.createTripletexInvoice}
                                                                >
                                                                    <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.tripletex-create-sale'>Tripletex: Create Sale</Text>
                                                                    {tripletexLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                                </button>
                                                            </>}
                                                        </>}
                                                    </> : <></>}
                                                </>}
                                            </small>
                                            {e.tripletexResponse && e.tripletexResponse.status > 202 ? <>
                                                <button class={`btn btn${tripletexError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleTripleTexError} data-id={e.id}>
                                                    <nobr>
                                                        <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-error'>Show Error</Text>
                                                    </nobr>
                                                </button>
                                            </> : <></>}
                                            {isAdmin && e.tripletexApiResponses && e.tripletexApiResponses.length > 0 ? <>
                                                <br />
                                                <button class={`btn btn${tripletexError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleTripleTexError} data-id={e.id}>
                                                    <nobr>
                                                        <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-debug'>Show Debug</Text>
                                                    </nobr>
                                                </button>
                                            </> : <></>}
                                        </>}
                                    </td>}

                                    {showAnimalia && <td class='text-center'>
                                        <small>
                                            {e.isProductionAnimal && e.animals?.length > 0 ? <>
                                                {animaliaRefreshTokenExpired ? <>
                                                    {hasDiagnoses && hasMedicines ? <>
                                                        {/* {e.animaliaResponse ? <>
                                                            {JSON.stringify(e.animaliaResponse, null, 4)}
                                                        </> : <></>} */}

                                                        {e.animaliaId ? <>
                                                            <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.animalia-reported'>Reported to DHP</Text>
                                                        </> : <>
                                                            <Text id='dyrejournal.animalia-auth-login-first'>Login to DHP before you can send data.</Text>
                                                        </>}
                                                    </> : <>
                                                    </>}
                                                </> : <>
                                                    {hasDiagnoses && hasMedicines ? <>
                                                        {/* {e.animaliaResponse ? <>
                                                            {JSON.stringify(e.animaliaResponse, null, 4)}
                                                        </> : <></>} */}

                                                        {e.animaliaId ? <>
                                                            <i class='fa-duotone fa-check text-success' /> <Text id='dyrejournal.animalia-reported'>Reported to DHP</Text>
                                                            <button
                                                                disabled={animaliaLoading[e.id]}
                                                                type='button'
                                                                class={`btn btn-sm btn-link rounded-pill p-0 m-0`}
                                                                style='font-size: 1.0em;'
                                                                data-id={e.id}
                                                                onClick={this.onClickPostAnimaliaVisit}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.dhp-re-transfer'>Resend to DHP</Text>
                                                                {animaliaLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </> : <>
                                                            <button
                                                                disabled={animaliaLoading[e.id]}
                                                                type='button'
                                                                class={`btn btn-sm btn-${isDevelopment ? 'warning' : 'primary'} rounded-pill py-0`}
                                                                data-id={e.id}
                                                                onClick={this.onClickPostAnimaliaVisit}
                                                            >
                                                                <i class='fa-duotone fa-cloud-arrow-up' /> <Text id='dyrejournal.dhp-transfer'>DHP</Text>
                                                                {animaliaLoading[e.id] ? <i class='fa-duotone fa-spinner fa-spin ml-2' /> : <></>}
                                                            </button>
                                                        </>}
                                                    </> : <>
                                                        {!hasDiagnoses && <>
                                                            <i class='fa-duotone fa-times text-danger mr-1' /> <Text id='dyrejournal.no-diagnoses'>No diagnoses</Text><br />
                                                        </>}
                                                        {!hasMedicines && <>
                                                            <i class='fa-duotone fa-times text-danger mr-1' /> <Text id='dyrejournal.no-medicine'>No medicine</Text><br />
                                                        </>}
                                                    </>}
                                                </>}
                                            </> : <>
                                                {e.animals?.length > 0 ? <>
                                                    <i class='fa-duotone fa-cow text-muted mr-2' />
                                                    <Text id='dyrejournal.sale-is-not-production-animals'>Production mode for sale not set</Text>
                                                </> : <>
                                                    <i class='fa-duotone fa-times text-muted mr-2' />
                                                    <Text id='dyrejournal.no-animals'>No animals</Text>
                                                </>}

                                            </>}
                                            {e.animaliaError ? <>
                                                <button class={`btn btn${displayAnimaliaError[e.id] ? '' : '-outline'}-danger btn-sm rounded-pill py-0`} onClick={this.toggleAnimaliaError} data-id={e.id}>
                                                    <nobr>
                                                        <i class='fa-duotone fa-exclamation-triangle text-danger' /> <Text id='dyrejournal.animalia-show-error'>Show Error</Text>
                                                    </nobr>
                                                </button>
                                            </> : <></>}
                                        </small>
                                    </td>}
                                    {showDelete && <td>
                                        {isAllowedToDelete  ? <>
                                            {hasJournals ? <>
                                                <i class='fa-duotone fa-hand text-danger mr-2' />
                                                <small class='font-style-narrow text-muted'>
                                                    <Text id='dyrejournal.sale-has-journals'>Sale has journals</Text>
                                                </small>
                                                {isCustomerAdmin ? <>
                                                    {!e.offline && <>
                                                        <button
                                                            type='button'
                                                            class='btn btn-sm btn-link text-danger rounded-pill p-0 ml-2'
                                                            onClick={this.onClickDeleteSale}
                                                            data-id={e.id}
                                                        >
                                                            <i class='fa-solid fa-trash' />
                                                        </button>
                                                    </>}
                                                </> : <></>}
                                            </> : <>
                                                {!e.offline && <>
                                                    <button
                                                        type='button'
                                                        class='btn btn-sm btn-link text-danger rounded-pill p-0'
                                                        onClick={this.onClickDeleteSale}
                                                        data-id={e.id}
                                                    >
                                                        <i class='fa-solid fa-trash' />
                                                    </button>
                                                </>}
                                            </>}
                                        </> : <>
                                            &nbsp;
                                        </>}
                                    </td>}
                                </tr>
                                {!e.animaliaId && displayAnimaliaError[e.id] ? <>
                                    <tr>
                                        <td colspan='17'>
                                            <h5>Error: {e.animaliaError?.error}</h5>
                                            <ul>
                                                {Array.isArray(e.animaliaError?.parameters) ? <>
                                                    {e.animaliaError?.parameters.map((line, idx) => {
                                                        return <>
                                                            {Object.keys(line).map(key => {
                                                                const val = line[key];
                                                                return (<>
                                                                    <li><span class='font-weight-lighter'>{key}:</span> {val}</li>
                                                                </>);
                                                            })}
                                                        </>;
                                                    })}
                                                </> : <>
                                                    {e.animaliaError?.parameters && Object.keys(e.animaliaError?.parameters).map(key => {
                                                        const val = e.animaliaError?.parameters[key];
                                                        return (<>
                                                            <li><span class='font-weight-lighter'>{key}:</span> {val}</li>
                                                        </>);
                                                    })}
                                                </>}
                                            </ul>
                                            {isAdmin && <>
                                                <xmp>Error: {JSON.stringify(e.animaliaError?.parameters, null, 4)}</xmp>
                                                <xmp>Request: {JSON.stringify(e.animaliaRequest, null, 4)}</xmp>
                                            </>}
                                        </td>
                                    </tr>
                                </> : <></>}
                                {tripletexError[e.id] ? <>
                                    <tr>
                                        <td colspan='17'>
                                            <h5>Error: {e.tripletexResponse?.message}</h5>
                                            {/* <xmp>{JSON.stringify(e.tripletexResponse, null, 4)}</xmp> */}
                                            <ul>
                                                {Array.isArray(e.tripletexResponse?.validationMessages) ? <>
                                                    {e.tripletexResponse?.validationMessages.map((line, idx) => {
                                                        return <>
                                                            {Object.keys(line).map(key => {
                                                                const val = line[key];
                                                                if (!val) return <></>;
                                                                return (<>
                                                                    <li><span class='font-weight-lighter'>{key}:</span> {val}</li>
                                                                </>);
                                                            })}
                                                        </>;
                                                    })}
                                                </> : <>
                                                    {e.tripletexResponse?.validationMessages && Object.keys(e.tripletexResponse?.validationMessages).map(key => {
                                                        const val = e.tripletexResponse?.validationMessages[key];
                                                        return (<>
                                                            <li><span class='font-weight-lighter'>{key}:</span> {val}</li>
                                                        </>);
                                                    })}
                                                </>}
                                            </ul>
                                            {isAdmin && <>
                                                <xmp>Error: {JSON.stringify(e.tripletexResponse, null, 4)}</xmp>
                                                <xmp>Request: {JSON.stringify(e.tripletexRequest, null, 4)}</xmp>
                                                <xmp>tripletexApiResponses	: {JSON.stringify(e.tripletexApiResponses, null, 4)}</xmp>
                                            </>}
                                        </td>
                                    </tr>
                                </> : <></>}
                                {displayFikenError[e.id] ? <>
                                    <tr>
                                        <td colspan='17'>
                                            {e.fikenResponse && e.fikenResponse?.statusCode && <>
                                                <h5>Error: {e.fikenResponse?.statusCode}</h5>
                                                <div>
                                                    {e.fikenResponse?.error_description}
                                                </div>
                                            </>}

                                            {e.fikenResponse && e.fikenResponse[0] && e.fikenResponse[0].message && <>
                                                <h5>Error: {e.fikenResponse[0].message}</h5>
                                                <div>
                                                    {e.fikenResponse[0].message}
                                                </div>
                                            </>}

                                            {isAdmin && <>
                                                <xmp>fikenResponse: {JSON.stringify(e.fikenResponse, null, 4)}</xmp>
                                                <xmp>fikenApiResponses: {JSON.stringify(e.fikenApiResponses, null, 4)}</xmp>
                                            </>}

                                        </td>
                                    </tr>
                                </> : <></>}
                            </>);
                        })}
                        {isDebitorList && <>
                            <tr>
                                <td colspan='12' class='text-right font-weight-bold' style='font-size: 1.5em;'><Text id='dyrejournal.total'>Total</Text></td>
                                <td class='text-right font-weight-bold' style='font-size: 1.5em;'>
                                    <nobr>
                                        {formatting ? util.format(sumRestPayment, 2) : sumRestPayment}
                                    </nobr>
                                </td>
                            </tr>
                        </>}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

export default AllVisitsTable;
