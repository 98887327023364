import { Component } from 'preact';
import { route } from 'preact-router';
import util from 'preact-util';

import jsQR from 'jsqr';

class CameraComponent extends Component {
    constructor() {
        super();
        this.state = {
            hasPermission: false,    // Track camera permission
            stream: null,            // Store the media stream
            orientation: 'portrait', // Initialize to portrait mode
            cameras: [],             // Available cameras
            cameraIndex: 0,          // Selected camera index
        };
        this.videoRef = null;  // Reference to the video element
        this.canvasRef = null;

        this.fps = 10;  // Frames per second
        this.isCapturing = false;
        this.mediaRecorder = null;
        this.mediaChunks = []; // Store video and audio data chunks
    }

    // Request permission and enumerate devices
    // Request permission to access the camera
    requestPermissionAndStartCamera = async () => {
        const { deviceIds = [] } = this.state;
        try {
            // Request access to the camera (no constraints)
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });

            // Set the stream to the video element
            if (this.videoRef.current) {
                this.videoRef.current.srcObject = stream;
                this.videoRef.current.muted = true; // Mute the video for feedback-free operation
            }

            // Store the stream
            this.setState({
                hasPermission: true,
                stream: stream,
            });

            // Get the track from the stream and extract the deviceId
            const videoTrack = stream.getVideoTracks()[0];
            const deviceId = videoTrack.getSettings().deviceId;

            // Store the deviceId for future switching
            deviceIds.push(deviceId); // Save the first deviceId (typically the front camera)

            // Enumerate devices after the stream has been started to detect multiple cameras
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            // If there are more video devices, we can toggle between them
            if (videoDevices.length > 1) {
                // Get the second camera deviceId (typically the back camera)
                const secondDeviceId = videoDevices[1]?.deviceId;
                if (secondDeviceId) {
                    deviceIds.push(secondDeviceId); // Save the second camera deviceId
                }
            }

            this.setState({
                cameras: videoDevices,
                deviceIds,
            }, () => {
                // Start scanning (or whatever other function you might have)
                // Get the last device form deviceIds
                const currentDeviceId = deviceIds[deviceIds.length - 1];
                const selectedDeviceId = util.get('selectedDeviceId') || currentDeviceId;
                this.startCamera(selectedDeviceId);
            });


        } catch (err) {
            console.error("Error accessing the camera:", err);
            this.setState({ hasPermission: false });
        }
    }

    // Function to switch to the next available camera
    changeCamera = () => {
        const { cameras, deviceIds, cameraIndex, stream } = this.state;

        // If more than one camera exists, switch to the next one
        if (cameras.length > 1) {
            const nextCameraIndex = (cameraIndex + 1) % cameras.length;

            // Stop the current stream
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }

            // Update the state with the new camera index
            this.setState({
                cameraIndex: nextCameraIndex,
            }, () => {
                const selectedDeviceId = deviceIds[nextCameraIndex];
                util.set('selectedDeviceId', selectedDeviceId);
                this.startCamera(selectedDeviceId); // Start the camera with the new index
            });
        }
    }

    startCamera = async (deviceId) => {
        const { cameras, deviceIds, cameraIndex, stream } = this.state;
        try {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }

            // Request access to the camera
            const newStream = await navigator.mediaDevices.getUserMedia({
                video: {
                    deviceId: { exact: deviceId }, // Use the selected camera deviceId
                    width: { ideal: 2048 },  // Request 2048px width if available
                    height: { ideal: 2048 }   // Request 2048px height if available
                }
            });

            // Set the stream to the video element
            if (this.videoRef) {
                this.videoRef.srcObject = newStream;
                this.videoRef.muted = true;  // Mute video for feedback-free operation
            }

            // Mirror the video if the front camera (selfie camera) is being used
            // console.log(newStream.getVideoTracks()[0].getSettings());
            const facingMode = newStream.getVideoTracks()[0].getSettings().facingMode;
            if (facingMode === 'user') {
                // Apply mirroring (for selfie/front camera)
                this.videoRef.style.transform = 'scaleX(-1)';
            } else {
                // Reset the transform if it's not a front-facing camera
                this.videoRef.style.transform = 'none';
                // this.videoRef.style.transform = 'scaleX(-1)';

            }

            // Update the state with the stream and permission granted
            this.setState({
                hasPermission: true,
                stream: newStream,
            });
            this.startScanning();  // Start scanning for QR codes

        } catch (err) {
            console.error("Error accessing the camera:", err);
            this.setState({ hasPermission: false });
        }
    }

    startScanning = () => {
        const { callback = () => {} } = this.props;
        // Set up an interval to scan every 100ms
        const scanInterval = setInterval(() => {
            if (this.videoRef && this.canvasRef) {
                const canvas = this.canvasRef;
                const context = canvas.getContext('2d');

                // Ensure the canvas size matches the video stream
                canvas.width = this.videoRef.videoWidth;
                canvas.height = this.videoRef.videoHeight;

                if (canvas.width === 0 || canvas.height === 0) {
                    return;  // Skip if the video dimensions are not available
                }
                // Draw the current video frame to the canvas
                context.drawImage(this.videoRef, 0, 0, canvas.width, canvas.height);

                // Get the image data from the canvas
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, canvas.width, canvas.height);

                if (code) {
                    // QR Code found, update state with the QR code data
                    console.log('QR Code Data:', code.data);
                    // Check if the QR code data is a URL
                    const isUrl = code.data.startsWith('http://') || code.data.startsWith('https://');

                    if (isUrl) {
                        const url = new URL(code.data);
                        console.log('URL:', url);
                        // QR Code Data: https://localhost/#/convention/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6IjZkYTcxNzMyLTVlYjEtNGNjMi1iODcyLWY1NjdjZjFkMjFjMSIsImNvbnZlbnRpb24iOiJhOWM0MWE1MC00NjM4LTQxODUtOGNjNS04ZjczZGU2ZmVmY2YiLCJpYXQiOjE3MzQ5NDc0NjksImV4cCI6MTczNTU1MjI2OX0.lrBHMPcX6YKx8qaqztsg9nQzyIj80Sg6-eclJ336swc/signup
                        this.setState({ qrCode: code.data });
                        if (url.hash) {
                            const gotoPage = url.hash.replace('#', '');
                            console.log('gotoPage:', gotoPage);
                            route(gotoPage);
                        }
                    } else {
                        this.setState({
                            qrCode: code.data,
                        });
                        callback(code.data);
                    }
                }
            }
        }, 750);  // Scan every 750ms

        // Stop scanning if component is unmounted
        this._scanInterval = scanInterval;
    }

    stopScanning = () => {
        if (this._scanInterval) {
            clearInterval(this._scanInterval);
        }
    }

    captureImage = () => {
        // Capture the current frame from the video element and convert it to an image
        const canvas = this.canvasRef;
        const context = canvas.getContext('2d');

        // Get the intrinsic video dimensions (video resolution)
        const videoWidth = this.videoRef.videoWidth;
        const videoHeight = this.videoRef.videoHeight;

        // Get the visible size of the video element (client size)
        const videoClientWidth = this.videoRef.clientWidth;
        const videoClientHeight = this.videoRef.clientHeight;

        // Calculate the aspect ratio of the video
        const videoAspectRatio = videoWidth / videoHeight;
        const clientAspectRatio = videoClientWidth / videoClientHeight;

        // Calculate the offsets for cropping based on object-fit: cover
        let scale = 1;
        let offsetX = 0;
        let offsetY = 0;

        if (videoAspectRatio > clientAspectRatio) {
            // If the video is wider (landscape video, portrait container)
            scale = videoClientHeight / videoHeight;
        } else {
            // If the video is taller (portrait video, landscape container)
            scale = videoClientWidth / videoWidth;
        }

        // If the container is taller (portrait video, landscape container)
        offsetX = Math.floor((videoWidth / 2) - (videoClientWidth / 2) / scale); // Center the video horizontally
        offsetY = Math.floor((videoHeight / 2) - (videoClientHeight / 2) / scale); // Center the video vertically

        let cropWidth = Math.floor(videoClientWidth / scale);
        let cropHeight = Math.floor(videoClientHeight / scale);

        // Set canvas dimensions based on the calculated width and height
        canvas.width = cropWidth;
        canvas.height = cropHeight;

        // console.log('videoWidth:', videoWidth);
        // console.log('videoHeight:', videoHeight);
        // console.log('videoClientWidth:', videoClientWidth);
        // console.log('videoClientHeight:', videoClientHeight);
        // console.log('videoAspectRatio:', videoAspectRatio);
        // console.log('clientAspectRatio:', clientAspectRatio);
        // console.log('offsetX:', offsetX);
        // console.log('offsetY:', offsetY);
        // console.log('scale:', scale);
        // console.log('cropWidth:', cropWidth);
        // console.log('cropHeight:', cropHeight);

        // Draw only the visible portion of the video (cropped area)
        context.drawImage(
            this.videoRef,  // Video element
            offsetX,        // Horizontal offset for cropping
            offsetY,        // Vertical offset for cropping
            cropWidth,      // Width to draw on the canvas
            cropHeight,     // Height to draw on the canvas
            0,              // Destination: start from the top-left corner
            0,              // Destination: start from the top-left corner
            cropWidth,      // Destination: cropWidth,
            cropHeight,     // Destination: cropHeight
        );

        // Convert the canvas content to a data URL (Base64 encoded image)
        const imageData = canvas.toDataURL('image/jpeg');

        // Convert the Base64 image data to a Blob object
        const byteString = atob(imageData.split(',')[1]);  // Remove the data URL prefix and decode the Base64 string
        const mimeString = imageData.split(',')[0].split(':')[1].split(';')[0];  // Get the MIME type (image/png)
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uintArray = new Uint8Array(arrayBuffer);

        // Fill the array buffer with the byte values
        for (let i = 0; i < byteString.length; i++) {
            uintArray[i] = byteString.charCodeAt(i);
        }

        // Create a Blob object from the byte array
        const fileBlob = new Blob([uintArray], { type: mimeString });

        // Create a File object to simulate the uploaded image
        const filename = `captured_image-${util.isoDate(new Date, false, false, true)}.jpg`;
        const fileObject = new File([fileBlob], filename, { type: mimeString });

        // Call handleUpload to upload the image
        const { handleUpload } = this.props;
        handleUpload(fileObject);
    }

    handleOrientationChange = () => {
        // Update the orientation state when the orientation changes
        this.updateOrientation();
    }

    updateOrientation = () => {
        // Check orientation using matchMedia or window.orientation (depending on browser support)
        const orientation = window.matchMedia("(orientation: landscape)").matches ? 'landscape' : 'portrait';
        this.setState({ orientation });
    }

    // Video and Audio Capture
    startVideoCapture = async () => {
        const { fps: inputFps } = this.props;
        if (this.isCapturing) return; // Don't start if already capturing

        this.isCapturing = true;

        // Get video and audio stream
        const stream  = await navigator.mediaDevices.getUserMedia({
            video: {
                width: { ideal: 1280 },
                height: { ideal: 720 },
                frameRate: { ideal: this.fps, max: this.fps } // Set frame rate (fps)
            },
            audio: true,
        });

        // Attach video stream to video element
        this.videoRef.srcObject = stream;

        // Create MediaRecorder with the video/audio stream
        this.mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });

        // Collect the video/audio data
        this.mediaRecorder.ondataavailable = (event) => {
            this.mediaChunks.push(event.data); // Push media chunks to array
        };

        // When recording stops, we create the Blob and File
        this.mediaRecorder.onstop = () => {
            this.createVideoFromChunks();
        };

        // Start recording
        this.mediaRecorder.start();
        console.log('Recording started');
    };

    // Stop capturing after some time or when the user chooses to stop
    stopVideoCapture = () => {
        if (!this.isCapturing) return; // If not capturing, do nothing

        this.mediaRecorder.stop(); // Stop recording
        this.isCapturing = false;
        console.log('Recording stopped');
    };

    // Create a video from the recorded chunks (audio + video)
    createVideoFromChunks = () => {
        const videoBlob = new Blob(this.mediaChunks, { type: 'video/webm' });

        // Convert Blob to File object to simulate video upload
        const filename = `captured_video_${new Date().toISOString()}.webm`;
        const fileObject = new File([videoBlob], filename, { type: 'video/webm' });

        // Call handleUpload to upload the video file
        const { handleUpload } = this.props;
        handleUpload(fileObject);

        console.log('Video created and ready for upload');
    };

    // // Start capturing video and audio
    // videoAudioCapture.startCapture();

    // // Stop capturing after 30 seconds (or when the user chooses to stop)
    // setTimeout(() => {
    //     videoAudioCapture.stopCapture();
    // }, 30000); // 30 seconds

    // /Video and Audio Capture





    componentDidMount() {
        this.requestPermissionAndStartCamera();
        // this.startCamera();

        window.addEventListener('orientationchange', this.handleOrientationChange); // Listen for orientation change
        this.updateOrientation(); // Set the initial orientation
    }

    componentWillUnmount() {
        if (this.state.stream) {
            // Stop the camera stream when the component is unmounted
            const tracks = this.state.stream.getTracks();
            tracks.forEach(track => track.stop());
        }
        window.removeEventListener('orientationchange', this.handleOrientationChange); // Cleanup
    }

    render() {
        const { orientation } = this.state;
        const {
            handleUpload = () => {},
            closeCamera = () => {},
            videoEnabled,
            showCapture = true,
        } = this.props;
        return (
            <div class='w-100 h-100 overflow-hidden bg-darkmode position-relative'>
                {/* {orientation === 'portrait' ? (
                    <p>Portrait Mode</p>
                ) : (
                    <p>Landscape Mode</p>
                )} */}
                {/* {videoEnabled ? <>
                    <span class='text-white'>videoEnabled</span>
                </> : <>
                    <span class='text-white'>videoEnabled not enabled</span>
                </>} */}
                <video
                    ref={el => { this.videoRef = el; }}
                    autoPlay
                    playsInline
                    muted
                    class='w-100 h-100 rounded-lg'
                    height="auto"
                    style={`
                        display: ${this.state.hasPermission ? 'block' : 'none'};
                        object-fit: cover;
                        transition: transform 0.5s ease;
                    `}  // Hide until permission is granted
                ></video>

                {showCapture && handleUpload && <>
                    <button
                        class='btn btn-lg btn-danger position-absolute rounded-pill px-4'
                        style={`
                            bottom: 2rem;
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 2.5rem;
                        `}
                        onClick={this.captureImage}
                    >
                        <i class='fa-solid fa-camera' />
                    </button>

                    <button
                        class='btn btn-lg btn-secondary position-absolute rounded-pill'
                        style={`
                            bottom: 2rem;
                            left: 75%;
                        `}
                        onClick={this.changeCamera}
                    >
                        <i class='fa-solid fa-sync' />
                    </button>

                    <button
                        class='btn btn-lg btn-secondary position-absolute rounded-pill'
                        style={{ top: '1rem', left: '1rem' }}
                        onClick={closeCamera}
                    >
                        <i class='fa-solid fa-times' />
                    </button>

                </>}

                {/* Hidden canvas for processing video frames */}
                <canvas ref={el => { this.canvasRef = el; }} style={{ display: 'none' }}></canvas>

                {/* Display the QR code data if available */}
                {/* {this.state.qrCode && (
                    <div>
                        <p>QR Code Data: {this.state.qrCode}</p>
                    </div>
                )} */}

                {!this.state.hasPermission && (
                    <p>Camera access denied or not available.</p>
                )}
            </div>
        );
    }
}

export default CameraComponent;