import { h, Component } from 'preact';
import JsBarcode from 'jsbarcode';

class Barcode extends Component {
    constructor(props) {
        super(props);
        this.barcodeRef = null;
    }

    componentDidMount() {
        this.renderBarcode();
    }

    componentDidUpdate() {
        this.renderBarcode();
    }

    renderBarcode() {
        const { value } = this.props;
        if (this.barcodeRef && value) {
            JsBarcode(this.barcodeRef, value, {
                format: 'CODE128',
                height: 40, // The total height of the barcode (in px)
            });
        }
    }

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return (<>
            <svg ref={el => (this.barcodeRef = el)} height={50} />
        </>);
    }
}

export default Barcode;