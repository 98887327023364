import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import GuiList from '../../components/gui/list';
import { has } from 'mobx';

function displayImg(img, props, size = '150x') {
    if (typeof img !== 'object') {
        return undefined;
    }
    const imgSrc = `${img.s3MediumLink}`;
    return imgSrc;
}

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

// function timeUsed(rcc, sortedCheckpoints) {
//     const startTime = rcc.startTime;
//     let lastTimestamp = startTime;


//     sortedCheckpoints.forEach((cp, idx) => {
//         const rccCp = rcc.checkpoints?.find(e => e.checkpoint === cp.md5) || {};
//         lastTimestamp = rccCp.timestampOut || rccCp.timestampIn;
//     });

//     const tu = util.dateDiff(startTime, lastTimestamp)
//     return tu.seconds;
// }

@withText(props => ({
    titleText: <Text id='race-class-contestants.title'>Race Contestants</Text>,
}))
@observer
class RaceClassContestantList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
        };
    }

    viewRaceClassContestant = e => {
        const {
            view,
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        if (view) {
            return view(e);
        }
        const { id, title } = e.target.closest('.box-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassContestant', {
            id,
            // title,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            showResults: true,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    viewRaceClassCheckpoint = e => {
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;

        const { md5 } = e.target.closest('.cp-line').dataset;
        const { appState } = this.props.stores;
        const { drawerLevel = 1 } = this.props;
        const { drawerHeightMedium } = appState;
        // console.log('viewPart', { id });
        appState.openDrawer('viewRaceClassCheckpoint', {
            md5,
            height: drawerHeightMedium,
            editFunction: this.editMediaViaView,
            race,
            raceClass,
            raceClassContestants,
            callback: () => {
                appState.toggleDrawer();
                // this.loadAll();
            },
        }, drawerLevel + 1);
    }

    calculateTimeUsed = () => {
        const {
            race,
            raceClass,
            raceClassContestants,
        } = this.props;
        const { raceClassContestantStore } = this.props.stores;

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        raceClassContestantStore.calculateTimeUsed(sortedCheckpoints, raceClass.id);
    }

    changeReportDate = async e => {
        const { value: cutoffDate } = e.target;
        const { stopTimer = () => {} } = this.props;
        stopTimer();
        this.setState({ cutoffDate }, () => {
            this.loadRaceResults();
        });
    }

    loadRaceResults = async () => {
        const { cutoffDate } = this.state;
        const {
            race,
            raceClassId,
        } = this.props;
        const { raceClassContestantStore } = this.props.stores;
        await raceClassContestantStore.loadPublic({
            query: {
                race: race.id,
                raceClass: raceClassId,
                addResults: true,
                cutoffDate,
            }
        });
    }

    clearCutoffDate = e => {
        this.setState({ cutoffDate: null }, () => {
            this.loadRaceResults();
        });
    }

    componentDidMount() {
        this.calculateTimeUsed();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.raceClassContestants !== this.props.raceClassContestants) {
            this.calculateTimeUsed();
        }
    }

    render() {
        const { appState, userStore, raceStore } = this.props.stores;
        const { cutoffDate } = this.state;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { titleText } = this.props;
        const {
            race,
            raceClass,
            raceClassContestants,
            classNames,
        } = this.props;

        const tdWidth = '45px';
        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        let prevContestant = null;
        let currentPosistion = 0;
        let hasStarted = false;

        return (<>
            <div class={`d-flex flex-column ${classNames}`}>
                <div class='d-flex flex-row justify-content-between align-items-center bg-warning px-3 rounded-lg'>
                    <div>
                        {race.title}: {raceClass.title}
                    </div>
                </div>
                <div class={`d-flex px-2 ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    <div class='table-responsive' style='font-size: 0.8em;'>
                        <table class='table table-striped table-hover table-sm mb-0'>
                            <tbody>
                                {raceClassContestants.filter(e => e.bib > 0).sort(fieldSorter(['hasScratched', '-lastCheckpoint', '-lastCheckpointLeft', (raceClass.sortOrderResults || 'lastTimestamp'), 'bib', '-name'])).slice(0, 10).map((rcc, posIdx) => {
                                    const { id, bib, firstname, lastname, startTime, country, timeused, raceTimeused } = rcc;
                                    const cpLength = rcc.lastCheckpoint > 0 ? rcc.lastCheckpoint : 1;
                                    let rccHasScratched = false;
                                    // console.log(rcc)
                                    let firstTimeOut = null;
                                    let firstTimeOutRaw = null;
                                    let timeEqualization = null;
                                    let totalActualRestMinutes = 0
                                    let totalRequiredRestMinutes = 0;

                                    const prevRcc = { ...prevContestant };
                                    prevContestant = rcc;
                                    return (<>
                                        <tr
                                            class='box-line'
                                            onClick={this.viewRaceClassContestant}
                                            data-id={id}
                                        >
                                            <td class={`p-0 `}>
                                                <nobr>
                                                    <span class='badge rounded-pill mr-1 ml-0' style='width: 35px'>{posIdx + 1}</span>
                                                    <span class='badge badge-dark rounded-pill mr-1 ml-0' style={`width: 35px; ${raceClass.bibColor ? `background-color: ${raceClass.bibColor} !important;` : ''}`}>{bib}</span>
                                                    {localUtil.getFlagSvg(country, 15, false, 'rounded')} {util.ucfirst(firstname)} {util.ucfirst(lastname)}
                                                    {/* {isAdmin && <>
                                                        <small class='text-muted ml-2'>
                                                            (start: {util.isoDate(rcc.startTime)}
                                                            -end: {util.isoDate(rcc.endTime)})
                                                        </small>
                                                    </>} */}

                                                    {rcc.hasDns ? <>
                                                        <span class='text-danger ml-2'><i class='fa-solid fa-times text-danger' /> DNS</span>
                                                    </> : <></>}
                                                    {rcc.hasWarning ? <>
                                                        <i class='fa-solid fa-cards-blank text-warning ml-2' />
                                                    </> : <></>}
                                                    {rcc.hasTimepenalty ? <>
                                                        <i class='fa-solid fa-timer text-warning ml-2' />
                                                    </> : <></>}
                                                    {rcc.isDisqualified ? <>
                                                        <i class='fa-solid fa-user-xmark text-danger ml-2' />
                                                    </> : <></>}
                                                    {/* lastCheckpoint: {rcc.lastCheckpoint} lastCheckpointLeft: {rcc.lastCheckpointLeft} */}
                                                    {/* rccHasScratched: {rccHasScratched ? 'true' : 'false'}<br /> */}

                                                    {firstTimeOut && <>
                                                        {/* isTimeEq: {isTimeEq ? 'true' : 'false'} */}
                                                        <span class='font-weight-lighter ml-2'><i class='fa-duotone fa-clock' /> <Text id='race.firsttimeout'>Time out</Text>:</span> {firstTimeOut}
                                                        {/* <span class='font-weight-lighter ml-2'><Text id='race.firsttimeout'>Time eq:</Text></span> {timeEqualization} */}
                                                    </>}
                                                </nobr>
                                                <nobr class='float-right'>
                                                    {raceClass.sortOrderResults && <>
                                                        <span class='text-muted ml-2'>
                                                            {raceClass.doNotCountRestTime ? <>
                                                                {util.secToHms(raceTimeused)}
                                                            </> : <>
                                                                <span class='font-weight-lighter'><Text id='race.racetime'>Race time</Text>:</span> {util.secToHms(timeused)}
                                                            </>}
                                                        </span>
                                                    </>}
                                                </nobr>
                                            </td>
                                            {/* <td class='p-0'>
                                                {rcc.lastCheckpoint} /
                                                {rcc.lastCheckpointLeft}
                                            </td> */}
                                        </tr>
                                    </>);
                                })}
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>


            {/* <GuiList
                stores={this.props.stores}
                holdingStore={raceStore}
                classNames={classNames}
                view={this.viewRaceClassContestant}
                title={title}
                list={raceClassContestants}
                elTitle={e => `${e.firstname} ${e.lastname}`}
                elInfo1={e => e.birth ? util.age(e.birth) : ''}
                elInfo2={e => e.sportsClub}
                elInfo3={e => ''}
                hideEmpty={hideEmpty}
                editElement={editElement}
                showEmptyImage={false}
                showCevron={showCevron}
                rightBoxField={rightBoxField}
                rightBoxFieldValue={rightBoxFieldValue}
                rightBoxFieldPostfix={rightBoxFieldPostfix}
                imgHeight='60px'
                help={help}
            /> */}
        </>);
    }
}

export default RaceClassContestantList;
