import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { route } from 'preact-router';

import fields from '../../lib/fields';

import ImageScroller from '../../components/imagescroller';
import ScrollIntoView from '../../components/gui/scrollIntoView';

import RaceClassContestantViewProfile from '../../components/raceClassContestant/viewProfile';

import localUtil from '../../lib/util';

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@observer
class RaceView extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            sessionid: new Date().getTime(),
            selectedCp: null,
        };
        this.mainContainer = null;
    }

    loadAll = async (props = this.props) => {
        const { selectedCp: md5 } = this.state;
        const { raceClassStore } = this.props.stores;
        const { race, raceClass, raceClassContestants } = props;

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];

        const cp = sortedCheckpoints?.find(e => e.md5 === md5);
        const cpIdx = sortedCheckpoints?.findIndex(e => e.md5 === md5);
        const prevCp = sortedCheckpoints?.[cpIdx - 1] || {};
        raceClassStore.updateKeyValue('checkpoint', cp);
        raceClassStore.updateKeyValue('prevCp', prevCp);

        const cpRaceClassContestants = raceClassContestants.map(rcc => {
            const rccCp = rcc.checkpoints?.find(e => e.checkpoint === md5) || {};
            const prevRccCp = prevCp ? rcc.checkpoints?.find(e => e.checkpoint === prevCp.md5) || {} : {};
            return {
                ...rcc,
                positionInToCheckpoint: rccCp.positionInToCheckpoint || 99999,
                prevPositionOutFromCheckpoint: prevRccCp.positionOutFromCheckpoint || 99999,
            };
        });
        const sortedCpRaceClassContestants = cpRaceClassContestants.sort(fieldSorter(['positionInToCheckpoint', 'prevPositionOutFromCheckpoint', 'bib', '-name']));
        raceClassStore.updateKeyValue('raceClassContestants', [...sortedCpRaceClassContestants]);
    }

    componentDidMount() {
        const { md5 } = this.props;
        this.setState({ selectedCp: md5 }, () => {
            this.loadAll();
        });
    }

    selectCp = (e) => {
        const { md5 } = e.target.closest('button').dataset;
        this.setState({ selectedCp: md5 }, () => {
            this.loadAll();
        });
    }


    setSortField = (e) => {
        const { sortField } = this.state;
        const { field } = e.target.closest('th').dataset;
        this.setState({ sortField: sortField === field ? `-${field}` : field });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.page !== this.props.page || nextProps.artid !== this.props.artid) {
            this.loadAll(nextProps);
        }
    }

    componentWillUnmount() {

    }

    render() {
        const { selectedCp: md5, sortField = '-timestamp' } = this.state;
        const { race, raceClass } = this.props;

        const { userStore, raceClassStore } = this.props.stores;
        const { checkpoint = {}, prevCp, raceClassContestants = [] } = raceClassStore;
        const { user = {}, isAdmin, isTester, isVeterinary, language } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];

        // const sortedCheckpoints = raceClass.checkpoints ? raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name'])) : [];
        // raceClass.checkpoints && raceClass.checkpoints.sort(fieldSorter(['sortOrder', '-name']))

        return (<>
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>


                    {/* <xmp>{JSON.stringify(checkpoint, null, 2)}</xmp> */}

                    <div class={`d-flex flex-row mt-0 mx-3 px-3 py-2 position-relative overflow-auto`}>
                        {sortedCheckpoints && sortedCheckpoints.map((cp, idx) => {
                            let roundedClass = 'rounded-none';
                            const total = raceClass.checkpoints.length;
                            if (idx === 0) {
                                roundedClass = 'rounded-pill-left';
                            }
                            if (total === 1) {
                                roundedClass = 'rounded-pill';
                            }
                            if (idx === total - 1) {
                                roundedClass = 'rounded-pill-right';
                            }
                            return (<>
                                <button
                                    type='button'
                                    class={`btn btn-sm btn-${md5 === cp.md5 ? 'primary' : 'outline-secondary'} ${roundedClass}`}
                                    onClick={this.selectCp}
                                    data-md5={cp.md5}
                                >
                                    <nobr>{cp.name}</nobr>
                                    {md5 === cp.md5 && <ScrollIntoView horizontal={true} />}
                                </button>
                            </>);
                        })}
                    </div>

                    <div class={`d-flex flex-column mx-3 px-3 py-3 rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} text-overflow position-relative`}>
                        <div class='table-responsive' style='font-size: 0.8em;'>
                            <table class='table table-striped table-hover table-sm'>
                                <thead>
                                    <tr>
                                        {/* <th data-field='trackerId' class='text-center' onClick={this.setSortField}>trackerId {sortField === 'trackerId' && <i class='fa-duotone fa-up' />}{sortField === '-trackerId' && <i class='fa-duotone fa-down' />}</th> */}
                                        <th class='p-0 px-1' ><Text id='race.result-rank'>Rank</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-bib'>Bib</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-name'>Name</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-starttime'>Start time</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-time-in'>Time in</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-time-out'>Time out</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-time-used'>Time used</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-speed'>Speed</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-rest-in-cp'>Rest in CP</Text></th>
                                        <th class='p-0 px-1'><Text id='race.result-dogs-in'>Dogs in</Text></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {raceClassContestants.map(rcc => {
                                        const { id, bib, firstname, lastname, startTime } = rcc;
                                        const rccCp = rcc.checkpoints?.find(e => e.checkpoint === checkpoint.md5) || {};
                                        const hasLeftCp = rccCp.timestampOut;
                                        const hasScratced = rccCp.timstampScratch;
                                        const isInCp = rccCp.timestampIn && !rccCp.timestampOut;
                                        const prevRccCp = prevCp ? rcc.checkpoints?.find(e => e.checkpoint === prevCp.md5) || {} : {};
                                        const timeInCp = rccCp.timestampIn ? util.dateDiff(rccCp.timestampIn, rccCp.timestampOut) : 0;
                                        // console.log('prevRccCp.timestampOut', prevRccCp.timestampOut);
                                        // console.log('rccCp.timstampScratch', rccCp.timstampScratch);
                                        // console.log('rccCp.timestampIn', rccCp.timestampIn);
                                        const timeFromPrevCp = prevRccCp.timestampOut && rccCp.timestampIn ? util.dateDiff(prevRccCp.timestampOut, rccCp.timestampIn) : 0;
                                        const speedFromPrevCp = timeFromPrevCp.seconds > 0 ? (checkpoint.distanceFromPrev / timeFromPrevCp.seconds) * 3600 : 0;
                                        const isStart = checkpoint.sortOrder === 1;
                                        const isFinishLine = rcc.totalCheckpoints === checkpoint.sortOrder;

                                        return (<>
                                            <tr>
                                                <td class='p-0 px-1 text-center'>{rccCp.positionInToCheckpoint}</td>
                                                <td class='p-0 px-1 text-center'>
                                                    {bib ? <>
                                                        <span class='badge badge-dark rounded-pill mr-1'>{bib}</span>
                                                    </> : ''}
                                                </td>
                                                <td class='p-0 px-1'>
                                                    <nobr>{firstname} {lastname}</nobr>
                                                </td>
                                                <td class='p-0 px-1 text-center'><nobr>{util.isoDateCompact(startTime)}</nobr></td>
                                                <td class='p-0 px-1 text-center'><nobr>{!isStart && rccCp.timestampIn && util.isoDateCompact(rccCp.timestampIn)}</nobr></td>
                                                <td class='p-0 px-1 text-center'><nobr>{rccCp.timestampOut && util.isoDateCompact(rccCp.timestampOut)}</nobr></td>
                                                <td class='p-0 px-1 text-center'>
                                                    <nobr>{timeFromPrevCp ? util.secToHms(timeFromPrevCp.seconds) : ''}</nobr>
                                                    {/* <br />{timeFromPrevCp.seconds} */}
                                                    {/* {JSON.stringify(prevCp)} */}
                                                </td>
                                                <td class='p-0 px-1 text-center'>
                                                    <nobr>{!isStart && speedFromPrevCp ? `${util.format(speedFromPrevCp, 1)} km/t` : ''}</nobr>
                                                </td>
                                                <td class='p-0 px-1 text-center'><nobr>{!isFinishLine && !isStart && timeInCp ? util.secToHms(timeInCp.seconds) : ''}</nobr></td>
                                                <td class='p-0 px-1 text-center'>{prevRccCp?.dogsOut}</td>
                                            </tr>
                                        </>);
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>
            </div>
        </>);
    }
}

export default RaceView;
