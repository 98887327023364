import { h, Component } from 'preact';

function pad(n) {
    return n < 10 ? `0${n}` : n;
}

class Clock extends Component {
    constructor(props) {
        super(props);
    }

    startClock = () => {
        const date = new Date();
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        const currentTime = `${hours}:${minutes}:${seconds}`;
        this.setState({ currentTime });
        this.clockTimer = setTimeout(this.startClock, 1000);
    }

    stopClock = () => {
        clearTimeout(this.clockTimer);
    }

    componentDidMount() {
        this.startClock();
    }

    componentWillUnmount() {
        this.stopClock();
    }

    render() {
        const { currentTime } = this.state;

        return (<>
            <span class='text-monospace' style='font-size: 1.6rem;'>
                {currentTime}
            </span>
        </>);
    }
}

export default Clock;