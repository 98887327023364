import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';

import CustomerList from '../../components/customer/list';
import InstallationList from '../../components/installation/list';
import InspectionList from '../../components/inspection/list';
import NewsList from '../../components/news/list';
import PartList from '../../components/part/list';
import RaceList from '../../components/race/list';
import MediaList from '../../components/media/list';

import localUtil from '../../lib/util';

@observer
class Search extends Component {
	constructor(props) {
        super(props);
        this.state = {
        };
        this.searchTimer = null;
    }

    clearSearch = () => {
        this.setState({
            search: '',
        });
        const { userStore, newsStore, customerStore, installationStore, partStore, inspectionStore, mediaStore, raceStore } = this.props.stores;
        newsStore.resetSearch();
        customerStore.resetSearch();
        installationStore.resetSearch();
        partStore.resetSearch();
        inspectionStore.resetSearch();
        mediaStore.resetSearch();
        raceStore.resetSearch();
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore } = this.props;
        this.setState({ search });

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.doSearch();
        }, 700);
    }

    doSearch = async () => {
        const { search } = this.state;
		const { newsStore, customerStore, installationStore, partStore, inspectionStore, mediaStore, raceStore } = this.props.stores;

        await Promise.all([
            newsStore.search(search),
            customerStore.search(search),
            installationStore.search(search),
            partStore.search(search),
            inspectionStore.search(search),
            mediaStore.search(search),
            raceStore.search(search),
        ]);
    }

    deletePartFromInstallation = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { id: installationId, drawerLevel = 1 } = this.props;
		const { installationStore } = this.props.stores;
        const { id: partId } = e.target.closest('.list-button').dataset;
        // console.log('deletePartFromInstallation', e, { id, partId, qty });

        const { appState } = this.props.stores;
        const { drawerHeightSmall } = appState;
        appState.openDrawer('deletePartFromInstallation', {
            height: drawerHeightSmall,
            installationId,
            partId,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
        }, drawerLevel + 1);
    }

    loanPartToFriend = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
		const { installationStore } = this.props.stores;
        const { id: partId } = e.target.closest('.list-button').dataset;
        // console.log('rentOutPart', e, { id, partId, qty });

        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('loanPartToFriend', {
            height: drawerHeightMedium,
            partId,
            callback: this.loadAll,
        }, drawerLevel + 1);
    }

    addPartToInstallation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
		const { installationStore } = this.props.stores;
        const { id: partId } = e.target.closest('.list-button').dataset;
        // console.log('addPartToInstallation', e, { partId, qty });

        const { appState } = this.props.stores;
        const { drawerHeightMedium } = appState;
        appState.openDrawer('addPartToInstallation', {
            height: drawerHeightMedium,
            partId,
            callback: this.loadAll,
        }, drawerLevel + 1);
    }

    addUsageData = e => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
        const { id, md5 } = e.target.closest('.list-button').dataset;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('partAddUsageData', {
			height: drawerHeightMedium,
			id,
            callback: () => {
                setTimeout(() => {
                    this.loadAll(this.props, false);
                }, 1000);
            },
		}, drawerLevel + 1);
    }

    editPart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
        const { id, md5 } = e.target.closest('.list-button').dataset;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editPart', {
			height: drawerHeightMedium,
			id,
		}, drawerLevel + 1);
	}

    editInstallation = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { drawerLevel = 1 } = this.props;
        const { id, md5 } = e.target.closest('.list-button').dataset;
		const { appState } = this.props.stores;
		const { drawerHeightMedium } = appState;
		appState.openDrawer('editInstallation', {
			height: drawerHeightMedium,
			id,
			lookupArray: 'parts',
		}, drawerLevel + 1);
	}

    closeSearch = () => {
        const { appState } = this.props.stores;
        appState.toggleDrawerRight();
    }

    render() {
        const { search } = this.state;
        const { drawerLevel, classNames = '' } = this.props;
        const {
            appState,
            userStore,
            newsStore,
            customerStore,
            installationStore,
            partStore,
            inspectionStore,
            mediaStore,
            raceStore,
        } = this.props.stores;
        const { isCordova } = appState;
        const { user, isAdmin, saved } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { searchResults: news } = newsStore;
        const { searchResults: customers } = customerStore;
        const { searchResults: installations } = installationStore;
        const { searchResults: parts } = partStore;
        const { searchResults: inspections } = inspectionStore;
        const { searchResults: medias } = mediaStore;
        const { searchResults: races } = raceStore;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='max-width: 960px; padding-top: 20px; padding-bottom: 100px;'>

                    <div class={`w-100 d-flex flex-column ${classNames}`}>
                        {/* <div class='font-weight-lighter px-3 box-header'>
                            <Text id='search.title'>Search</Text>
                        </div> */}
                        <div class={`d-flex flex-column mx-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>

                            <div class='input-group'>
                                <div class='input-group-prepend'>
                                    <span class='input-group-text rounded-pill-left'>
                                        <i class='fa-solid fa-magnifying-glass'></i>
                                    </span>
                                </div>
                                <input
                                    class={`form-control form-control-lg ${search ? '' : 'rounded-pill-right'}`}
                                    type='text'
                                    value={search}
                                    placeholder={'search'}
                                    onInput={this.searchInput}
                                />
                                {search && <div class='input-group-append'>
                                    <button class='btn btn-outline-secondary rounded-pill-right' type='button' onClick={this.clearSearch}>
                                        <i class='fa-solid fa-circle-xmark'></i>
                                    </button>
                                </div>}
                            </div>

                        </div>
                    </div>

                    {/* {customers && customers.length > 0 && <CustomerList
                        stores={this.props.stores}
                        customers={customers}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                    />} */}

                    {news && news.length > 0 && <NewsList
                        stores={this.props.stores}
                        newsList={news}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                    />}

                    {installations && installations.length > 0 && <InstallationList
                        stores={this.props.stores}
                        installations={installations}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                        editElement={this.editInstallation}
                        preCallback={this.closeSearch}
                    />}

                    {inspections && inspections.length > 0 && <InspectionList
                        stores={this.props.stores}
                        inspections={inspections}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                    />}

                    {parts && parts.length > 0 && <PartList
                        stores={this.props.stores}
                        parts={parts}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}

                        // rentOutElement={this.loanPartToFriend}
                        // moveElement={this.addPartToInstallation}
                        // editElement={this.editPart}

                        deleteElement={this.deletePartFromInstallation}
                        rentOutElement={this.loanPartToFriend}
                        moveElement={this.addPartToInstallation}
                        addUsageData={this.addUsageData}
                        editElement={this.editPart}
                    />}

                    {medias && medias.length > 0 && <MediaList
                        stores={this.props.stores}
                        medias={medias}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                    />}

                    {races && races.length > 0 && <RaceList
                        stores={this.props.stores}
                        races={races}
                        classNames={'mt-5'}
                        drawerLevel={drawerLevel + 2}
                        preCallback={this.closeSearch}
                    />}


                </div>
            </div>
        );
    }
}

export default Search;
